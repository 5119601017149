// iconMapper.js
import { FaClock, FaEnvelopeOpen, FaCheck, FaTimes } from 'react-icons/fa';
import { FaInbox } from 'react-icons/fa6';
import { FaRegHourglass } from "react-icons/fa";

const iconMapper = {
  'FaClock': FaClock,
  'FaEnvelopeOpen': FaEnvelopeOpen,
  'FaCheck': FaCheck,
  'FaTimes': FaTimes,
  'FaInbox': FaInbox,
  "FaRegHourglass": FaRegHourglass
};

export default iconMapper;
