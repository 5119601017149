import { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { BalanceContext } from "../../Context/BalanceContext/BalanceContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "../DialogConfirmacao";

const EntradaInsumos = ({ permissionType, show, close, page, status }) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMovimentationType, setSelectedMovimentationType] =
    useState(null);
  const handleSetSelectedUser = user => {
    const userSplit = user.split(" ");
    const userId = userSplit[0];
    const userPermission = userSplit[1];
    setSelectedUser({
      id_user: userId,
      permission_type: userPermission,
    });
  };
  const { listUser } = useContext(UserContext);
  const {
    insertBalanceData,
    getOperationType,
    getOperationCode,
    listMovimentationType,
  } = useContext(BalanceContext);
  const [users, setUsers] = useState([]);
  const [operationType, setOperationType] = useState([]);
  const [operationCode, setOperationCode] = useState([]);
  const [movimentationType, setMovimentationType] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await handleGetOperationType();
      await handleUserList();
    }
    fetchData();
  }, [show]);

  const handleGetOperationType = async () => {
    const response = await getOperationType();
    setOperationType(response.data);
    const responseMovimentationType = await listMovimentationType();
    setMovimentationType(responseMovimentationType.data.result);
  };

  const handleUserList = async () => {
    const payload = {
      pageSearch: page,
      permissionType: permissionType,
      page: 1,
      limit: 9999,
    };
    const usersList = await listUser(payload);
    setUsers(usersList.data.users);
  };

  const [dialogShow, setDialogShow] = useState(false);
  const [dadosEvento, setDadosEvento] = useState([]);

  const closeDialog = function () {
    setDialogShow(false);
  };

  const handleOpenDialog = e => {
    e.preventDefault();
    setDadosEvento(e);
    setDialogShow(true);
  };

  const handleChildData = confirmacao => {
    if (confirmacao === false) {
      toast.dismiss();
      toast.error(
        "Matrícula incorreta! Tente novamente ou redefina a sua matrícula!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
      return;
    } else {
      closeDialog();
      handleSubmit(dadosEvento);
    }
  };

  const handleSubmit = async e => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const formData = new FormData(e.target);

    const payload = {
      operation_code: formData.get("operation_code"),
      id_user: selectedUser.id_user,
      permission_code: selectedUser.permission_type,
      id_orders: [],
      detail: formData.get("detail"),
      operation_type: formData.get("operation_type"),
      movimentation_type: formData.get("movimentation_type"),
    };

    // Convert total_weight to a float number
    const operationWeight = parseFloat(
      formData.get("total_weight")?.replace(",", ".")
    );

    // Function to calculate weight with hand water adjustment
    const calculateHandWaterWeight = () => {
      let adjustedWeight = null;
      if (
        payload.movimentation_type === "16" ||
        payload.movimentation_type === "17"
      ) {
        const factor = payload.permission_code == 14 ? 650 : 680; // Internal (14) = 650, External (15) = 680
        adjustedWeight = (operationWeight * factor) / 750;
      }
      return adjustedWeight;
    };

    if (
      payload.movimentation_type === "16" ||
      payload.movimentation_type === "17"
    ) {
      payload["hand_water_real_weight"] = operationWeight;
      payload["total_weight"] = calculateHandWaterWeight();
    } else {
      payload["total_weight"] = operationWeight;
    }

    try {
      const response = await insertBalanceData(payload);
      toast.dismiss();
      if (response.data.status === "success") {
        toast.success(response.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setSelectedMovimentationType(null);
        close();
      } else {
        toast.error(response.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Ocorreu um erro ao processar a operação.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleGetOperationCode = async id_operation_type => {
    const payload = {
      id_operation_type: id_operation_type,
    };
    const responseOperationCode = await getOperationCode(payload);
    setOperationCode(responseOperationCode.data.sort());
  };

  const [userPermissionInterno, setUserPermissionInterno] = useState(false);
  const [userPermissionExterno, setUserPermissionExterno] = useState(false);
  const verifyUserPermission = () => {
    if (users.some(user => user.PERMISSION_CODE == 14)) {
      setUserPermissionInterno(true);
    }

    if (users.some(user => user.PERMISSION_CODE == 15)) {
      setUserPermissionExterno(true);
    }
  };

  useEffect(() => {
    verifyUserPermission();
  }, [users]);

  return (
    <>
      <ToastContainer />
      <Modal
        size="xl"
        show={show}
        onHide={() => {
          close();
          setSelectedMovimentationType(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Lançamento de Insumos</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleOpenDialog}>
          <Modal.Body className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between gap-3">
              <Card className="flex-grow-1 w-100 card-one">
                <Card.Body>
                  <Card.Title>Ouríves</Card.Title>
                  <Form.Group>
                    <Form.Select
                      onChange={e => handleSetSelectedUser(e.target.value)}
                      required
                      name="user"
                    >
                      <option className="text-start p-2 rounded" value={null}>
                        --Selecione--
                      </option>

                      {userPermissionInterno ? (
                        <optgroup className="p-2" label="Ourives Internos">
                          {users.map(u => {
                            if (u.PERMISSION_CODE === 14) {
                              return (
                                <option
                                  className="text-start p-3"
                                  value={u.ID + " " + u.PERMISSION_CODE}
                                >
                                  {u.NAME}
                                </option>
                              );
                            }
                          })}
                        </optgroup>
                      ) : null}
                      {userPermissionExterno ? (
                        <optgroup label="Ourives Externos">
                          {users.map(u => {
                            if (u.PERMISSION_CODE === 15) {
                              return (
                                <option
                                  className="text-start"
                                  value={u.ID + " " + u.PERMISSION_CODE}
                                >
                                  {u.NAME}
                                </option>
                              );
                            }
                          })}
                        </optgroup>
                      ) : null}
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>

              <Card className="flex-grow-1 w-100 card-one">
                <Card.Body>
                  <Card.Title>Tipo de Operação</Card.Title>
                  <Form.Group>
                    <Form.Select
                      required
                      onChange={e => handleGetOperationCode(e.target.value)}
                      name="operation_type"
                    >
                      <option value={null}>Selecione</option>
                      {operationType.map(o => (
                        <option
                          key={o.id_operation_type}
                          value={o.id_operation_type}
                        >
                          {o.operation_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>

              <Card className="flex-grow-1 w-100 card-one">
                <Card.Body>
                  <Card.Title>Código da Operação</Card.Title>
                  <Form.Group>
                    <Form.Select
                      required
                      disabled={!operationCode.length > 0}
                      name="operation_code"
                    >
                      {operationCode.map(o => {
                        if (
                          o.id_operation_code === 1 ||
                          o.id_operation_code === 8
                        ) {
                          return (
                            <option
                              key={o.id_operation_code}
                              value={o.id_operation_code}
                            >
                              {o.operation_code_name}
                            </option>
                          );
                        }
                      })}
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>

            <Card className="flex-grow-1 w-100 card-one">
              <Card.Body>
                <Card.Title>Tipo da Movimentação</Card.Title>
                <Form.Group>
                  <Form.Select
                    onChange={e => setSelectedMovimentationType(e.target.value)}
                    required
                    name="movimentation_type"
                  >
                    <option value="">--Selecione--</option>
                    {movimentationType &&
                      movimentationType
                        .filter(m =>
                          [
                            7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                          ].includes(m.id_movimentation_type)
                        )
                        .map((m, i) => (
                          <option key={i} value={m.id_movimentation_type}>
                            {m.movimentation_name}
                          </option>
                        ))}
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="flex-grow-1 w-100 card-one">
              <Card.Body>
                <Card.Title>Peso</Card.Title>
                <Form.Group>
                  <Form.Control required name="total_weight" />
                </Form.Group>
              </Card.Body>
            </Card>

            {selectedMovimentationType == "18" ? (
              <Card className="flex-grow-1 w-100 card-one">
                <Card.Body>
                  <Card.Title>Detalhes</Card.Title>
                  <Form.Group>
                    <Form.Control as="textarea" required name="detail" />
                  </Form.Group>
                </Card.Body>
              </Card>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                close();
                setSelectedMovimentationType(null);
              }}
            >
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Dialog
        showDialog={dialogShow}
        closeDialog={closeDialog}
        user={selectedUser.id_user}
        sendDataToParent={handleChildData}
        title={"Insumos"}
        question={"Digite sua matrícula:"}
      />
    </>
  );
};

export default EntradaInsumos;
