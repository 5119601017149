import React, { Component } from 'react';
import { format, isWithinInterval, parse } from 'date-fns';

class DateRangeFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
        };
    }

    handleStartDateChange = (e) => {
        const startDate = parse(e.target.value, 'yyyy-MM-dd', new Date());
        this.setState({ startDate });
    };

    handleEndDateChange = (e) => {
        const endDate = parse(e.target.value, 'yyyy-MM-dd', new Date());
        this.setState({ endDate });
    };

    render() {
        const { startDate, endDate } = this.state;
        const { data, onFilter } = this.props;

        const filteredData = data.filter((item) =>
            isWithinInterval(new Date(item.date), { start: startDate, end: endDate })
        );

        return (
            <div>
                <input
                    type="date"
                    placeholder="Start Date"
                    onChange={this.handleStartDateChange}
                />
                <input
                    type="date"
                    placeholder="End Date"
                    onChange={this.handleEndDateChange}
                />
                <button onClick={() => onFilter(filteredData)}>
                    Apply Date Range Filter
                </button>
            </div>
        );
    }
}

export default DateRangeFilter;
