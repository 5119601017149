import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { userServices } from "../apiServices/userServices";
import Avatar from "../components/Avatar";

import { ToastContainer, toast } from "react-toastify";

import img11 from "../assets/img/img11.jpg";
import PerfectScrollbar from "perfect-scrollbar";
import { productServices } from "../apiServices/productServices";
import { Info, Search, Trash2 } from "lucide-react";
import { cepServices } from "../apiServices/addressServices";
import { cpfMask, cnpjMask } from "../functions/Mask";

const token = localStorage.getItem("token");

const Users = () => {
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [atribuirMetalShow, setAtribuirMetalShow] = useState(false);
  const [material, setMaterial] = useState([]);

  const [modalCadastroShow, setModalCadastroShow] = useState(false);

  const [endereco, setEndereco] = useState({
    cep: "",
    rua: "",
    bairro: "",
    cidade: "",
    estado: "",
  });

  const [definirPercentualModalShow, setDefinirPercentualModalShow] =
    useState(false);

  const [definirPermissoesModalShow, setDefinirPermissoesModalShow] =
    useState(false);

  const [typePermissions, setTypePermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  const [searchKey, setSearchKey] = useState("");

  const closeModalPercentual = () => {
    setDefinirPercentualModalShow(false);
    setAtribuirMetalShow(false);
    handleOpenModal(userData.USERID);
    setDefinirPermissoesModalShow(false);
  };

  const handleClose = () => {
    setModalCadastroShow(false);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleOpenModal = async (data) => {
    const request = {
      token: token,
      user_id: data,
    };

    const response = await userServices.getUserDataToEdit(request);
    setUserData(response.data.result);
    setShow(true);
  };

  const handleOpenModalCadastro = () => {
    setModalCadastroShow(true);
  };

  const handleAtribuicaoMetal = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const data = {
      cliente: userData.USERID,
      material: formData.get("metal"),
      quantidade: formData.get("quantidade"),
      data_hora: formData.get("datetime"),
      descricao: formData.get("detalhamento"),
      token: token,
    };

    const response = await userServices.balanceAdd(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      //   setAtribuirMetalShow(false)
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleSearchNoneValue = (event) => {
    let formData = event.target.value;
    if (formData.length === 0) {
      setSearchKey("");
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let formData = event.target.pesquisar.value;
    if (formData.length > 1) {
      setSearchKey(formData);
    } else if (formData.length === 0) {
      setSearchKey(null);
    }
  };

  const handleDefinirPercentual = (e) => {
    setShow(false);
    setDefinirPercentualModalShow(true);
  };

  const handlePercentual = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const percentual = formData.get("percentual_loss");

    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      token: token,
      user: userData.USERID,
      percentual: percentual,
    };

    const response = await userServices.setPercentual(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg);
      closeModalPercentual();
      setTimeout(() => {
        setDefinirPercentualModalShow(false);
      }, 2000);
    } else {
      toast.dismiss();
      toast.error(response.data.msg);
    }
  };

  const handleInputChange = (e, f) => {
    const value = e.target.value;
    const field = f.toUpperCase();
    setUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEdit = async (e) => {
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER });
    e.preventDefault();
    const data = {
      user: userData,
      token: token,
    };
    const response = await userServices.editUser(data);
    toast.dismiss();
    if (response.data.status === "error") {
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleSavePermission = async (e) => {
    toast.loading("Adicionando permissão...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      permission: formData.get("permission"),
      user: userData.USERID,
      token: token,
    };

    const response = await userServices.setUserPermission(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleGetUserPermissions();
    } else if (response.data.status === "info") {
      toast.dismiss();
      toast.info(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const getUsers = async (token, limit, page, searchKey) => {
    const request = {
      page: page,
      limit: limit,
      token: token,
      pageSearch: "users",
      searchKey: searchKey,
    };
    const response = await userServices.getUsers(request);
    setUsers(response.data.users);
    setTotalPages(response.data.total_pages);
  };

  const handleCep = async (cep) => {
    const response = await cepServices.getAdress(cep);

    if (response.status === 200) {
      // Supondo que os dados do endereço estejam em response.data
      setEndereco({
        ...endereco,
        cep: response.data.cep,
        rua: response.data.logradouro,
        cidade: response.data.localidade,
        bairro: response.data.bairro,
        estado: response.data.uf,
      });
    } else {
      toast.dismiss();
      toast.error("CEP não encontrado", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleCadastro = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER });
    // Acesse os valores do FormData usando o método .get()
    const data = {
      action: "create",
      name: formData.get("name"),
      email: formData.get("email"),
      typeUser: formData.get("type_user"),
      document: formData.get("cpf"),
      matricula: formData.get("matricula"),
      fantasy_name: formData.get("fantasyName"),
      phone: formData.get("telefone"),
      postalCode: endereco.cep,
      address: endereco.rua, // Nome do campo deve ser "rua"
      number: formData.get("numero"),
      neighborhood: endereco.bairro, // Nome do campo deve ser "bairro"
      city: endereco.cidade, // Nome do campo deve ser "cidade"
      uf: endereco.estado, // Nome do campo deve ser "estado"
      token: token,
    };

    const response = await userServices.createUser(data);
    toast.dismiss();
    if (response.data.status === "error") {
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    getUsers(token, 10, pageNumber, "");
  };

  const handleRecebimento = async (e) => {
    setAtribuirMetalShow(true);
    setShow(false);
    handleMaterial();
  };

  const handleMaterial = async (id) => {
    const responseMaterial = await productServices.selectListMaterial(
      id,
      token
    );

    setMaterial(responseMaterial.data);
  };

  const handleGetUserPermissions = async () => {
    const getUserPermissions = await userServices.getUserPermission(
      userData.USERID
    );
    setUserPermissions(getUserPermissions.data);
  };

  const handleDefinirPermissoes = async (e) => {
    setDefinirPermissoesModalShow(true);
    setShow(false);
    const response = await userServices.getUserTypePermissions();
    handleGetUserPermissions();
    setTypePermissions(response.data);
  };

  const handlePermissionRemove = async (id) => {
    toast.loading("Removendo...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id: id,
      token: token,
    };
    const response = await userServices.removeUserPermission(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleGetUserPermissions();
    } else {
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    getUsers(token, 10, 1, searchKey);
  }, [searchKey]);

  return (
    <React.Fragment>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <Card className="card-one">
          <Card.Body className="d-flex flex-row justify-content-between">
            <div className="w-100">
              <h4>Usuários</h4>

              <div className="border form-search w-50 mb-3">
                <Form className="d-flex w-100" onSubmit={handleSearch}>
                  <Form.Control
                    className="w-100"
                    placeholder="Pesquisar"
                    onChange={handleSearchNoneValue}
                    id="pesquisar"
                    name="pesquisar"
                  ></Form.Control>
                  <Button type="submit" variant="outline">
                    <Search />
                  </Button>
                </Form>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-end align-items-center">
              <Button
                className="d-flex h-50"
                onClick={() => handleOpenModalCadastro()}
              >
                <span className="h5 d-flex  justify-content-center align-items-center h-100 gap-2">
                  <i className="ri-user-add-line"></i>Cadastrar Usuário
                </span>
              </Button>
            </div>
          </Card.Body>
        </Card>

        <Card className="card-one mt-3">
          <Card.Body>
            <Table className="table-bordered rounded">
              <thead className="bg-dark text-white">
                <tr>
                  <th className="text-left  p-2">
                    <span className="h6  font-bold">Nome</span>
                  </th>

                  <th className="text-left  p-2">
                    <span className="h6  font-bold">Telefone</span>
                  </th>
                  <th className="text-left  p-2">
                    <span className="h6  font-bold">Email</span>
                  </th>
                  <th className="text-left  p-2">
                    <span className="h6  font-bold">CPF/ CNPJ</span>
                  </th>
                  <th className="text-left  p-2">
                    <span className="h6  font-bold">Tipo</span>
                  </th>
                  <th className="text-left  p-2">
                    <span className="h6  font-bold">Ações</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr className="">
                    <td className="text-left p-2">{user.NAME}</td>
                    <td className="text-left p-2">{user.PHONE}</td>
                    <td className="text-left p-2">{user.EMAIL}</td>
                    <td className="text-center p-2">
                      {user.DOCUMENT.length <= 11
                        ? cpfMask(user.DOCUMENT)
                        : cnpjMask(user.DOCUMENT)}
                    </td>
                    <td className="text-left p-2">{user.USER_TYPE}</td>
                    <td className="text-center p-2">
                      <Button onClick={() => handleOpenModal(user.ID)}>
                        Editar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center">
            <Pagination className="pagination-space pagination-circled mb-0">
              {Array.from({ length: totalPages }, (_, index) => {
                if (
                  index + 1 === currentPage ||
                  index + 1 === 1 ||
                  index + 1 === totalPages ||
                  (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2)
                ) {
                  return (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageClick(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  );
                } else if (
                  (index + 1 === currentPage - 3 && currentPage > 5) ||
                  (index + 1 === currentPage + 3 &&
                    currentPage < totalPages - 4)
                ) {
                  return (
                    <Pagination.Ellipsis
                      key={index + 1}
                      onClick={() => handlePageClick(index + 1)}
                    />
                  );
                }
                return null;
              })}
            </Pagination>
          </Card.Footer>
        </Card>
      </div>

      <Modal size="xl" show={show} onHide={closeModal}>
        <Modal.Header>
          <span className="font-bold">Usuário</span>
        </Modal.Header>

        <div className="p-3">
          <Card className="">
            <Card.Body>
              <div className="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-4">
                <div className="me-4 mb-3 mb-sm-0">
                  <Avatar img={img11} />
                </div>
                <div className="flex-fill">
                  <h4 className="mb-1 text-dark fw-semibold">
                    {userData.NAME} - {userData.FANTASY_NAME}
                  </h4>
                  <div className="">
                    <p>{userData.USER_TYPE_TEXT}</p>
                    {userData.USER_TYPE === 1 ? (
                      <p className="">
                        {" "}
                        Código do Cliente: {userData.CLIENT_CODE}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="g-3 d-flex flex-wrap">
                    <div className="d-flex flex-wrap g-3 mx-1">
                      <div className="d-flex">
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleDefinirPercentual();
                          }}
                          className="px-5"
                        >
                          Definir Percentual
                        </Button>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap g-3 mx-1">
                      <div className="d-flex">
                        <Button
                          variant="primary"
                          onClick={() => handleDefinirPermissoes()}
                          className="px-5"
                        >
                          Definir Permissões
                        </Button>
                      </div>
                    </div>

                    {userData.USERID ? (
                      <div className="d-flex flex-wrap g-3 mx-1">
                        <div className="d-flex">
                          <Button
                            variant="primary"
                            onClick={() => handleRecebimento()}
                            className="px-5"
                          >
                            Novo Recebimento
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <hr />

        <form onSubmit={handleEdit}>
          <Modal.Body>
            <Card className="card-one">
              <Card.Header>
                <Card.Title className="text-center">
                  Dados Cadastrais
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <label for="email">Nome</label>
                    <Form.Control
                      className="w-100"
                      name="name"
                      id="name"
                      type="text"
                      onChange={(e) => handleInputChange(e, "NAME")}
                      value={userData.NAME}
                    />
                  </Col>
                  <Col>
                    <label for="email">Nome Fantasia</label>
                    <Form.Control
                      className="w-100"
                      type="text"
                      name="fantasy_name"
                      id="fantasy_name"
                      onChange={(e) => handleInputChange(e, "fantasy_name")}
                      value={userData.FANTASY_NAME}
                    />
                  </Col>

                  <Col>
                    <label>CPF</label>
                    <Form.Control
                      className="w-100"
                      name="document"
                      type="number"
                      id="document"
                      onChange={(e) => handleInputChange(e, "document")}
                      value={userData.DOCUMENT}
                    ></Form.Control>
                  </Col>

                  <Col>
                    <label for="email">Código do Cliente</label>
                    <Form.Control
                      className="w-100"
                      type="text"
                      name="client_code"
                      id="client_code"
                      onChange={(e) => handleInputChange(e, "CLIENT_CODE")}
                      value={userData.CLIENT_CODE}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <label for="email">Email</label>
                    <Form.Control
                      name="email"
                      id="email"
                      onChange={(e) => handleInputChange(e, "email")}
                      className="w-100"
                      type="text"
                      value={userData.EMAIL}
                    />
                  </Col>
                  <Col>
                    <label for="email">Permissão para autenticar</label>
                    <Form.Select
                      name="authentication"
                      id="authentication"
                      onChange={(e) => handleInputChange(e, "auth")}
                      className="w-100"
                      value={userData.AUTH}
                    >
                      <option value="">Selecione</option>
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <label for="telefone">Telefone</label>
                    <Form.Control
                      name="phone"
                      id="phone"
                      onChange={(e) => handleInputChange(e, "phone")}
                      className="w-100"
                      type="text"
                      value={userData.PHONE}
                    />
                  </Col>

                  {/* <Col>
                    <label for="matricula">Matricula</label>
                    <Form.Control
                      className="w-100"
                      type="text"
                      name="matricula"
                      id="matricula"
                      onChange={(e) => handleInputChange(e, "MATRICULA")}
                      value={userData.MATRICULA}
                    ></Form.Control>
                  </Col> */}
                </Row>

                <Row className="mt-3">
                  <Col>
                    <label for="email">Rua</label>
                    <Form.Control
                      name="address"
                      id="address"
                      onChange={(e) => handleInputChange(e, "street")}
                      className="w-100"
                      type="text"
                      value={userData.STREET}
                    />
                  </Col>
                  <Col>
                    <label for="numero">Numero</label>
                    <Form.Control
                      name="number"
                      id="number"
                      onChange={(e) => handleInputChange(e, "number")}
                      className="w-100"
                      value={userData.NUMBER}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label for="numero">Complemento</label>
                    <Form.Control
                      name="complement"
                      id="complement"
                      onChange={(e) => handleInputChange(e, "complement")}
                      className="w-100"
                      value={userData.COMPLEMENT}
                    ></Form.Control>
                  </Col>
                  <Col>
                    <label for="numero">Bairro</label>
                    <Form.Control
                      name="neighborhood"
                      id="neighborhood"
                      onChange={(e) => handleInputChange(e, "NEIGHBORHOOD")}
                      className="w-100"
                      value={userData.NEIGHBORHOOD}
                    ></Form.Control>
                  </Col>
                  <Col>
                    <label for="numero">Cidade</label>
                    <Form.Control
                      name="city"
                      id="city"
                      onChange={(e) => handleInputChange(e, "city")}
                      className="w-100"
                      value={userData.CITY}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label for="numero">UF</label>
                    <Form.Control
                      name="uf"
                      id="uf"
                      onChange={(e) => handleInputChange(e, "uf")}
                      className="w-100"
                      value={userData.UF}
                    ></Form.Control>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Salvar</Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        size="lg"
        show={definirPercentualModalShow}
        onHide={closeModalPercentual}
      >
        <Modal.Header closeButton>
          <Modal.Title>Percentual de perda de metal</Modal.Title>
        </Modal.Header>
        <form onSubmit={handlePercentual}>
          <Modal.Body>
            <div className="w-100 d-flex justify-content-center">
              <Alert color="info" className="text-center w-50" isOpen={true}>
                Aqui é possível definir de acordo com cada cliente qual é o
                percentual de perda de metal por peça/pedido, essa quantidade
                será somado ao montatne necessário para produção.
              </Alert>
            </div>

            <Card className="p-2 text-center card-one">
              <Row>
                <Col>
                  <Card.Title>Percentual:</Card.Title>
                  <span className="form-control">{userData.PERCENT_LOSS}</span>
                </Col>
                <Col>
                  <Form.Label>Definir Percentual de Perda</Form.Label>
                  <Form.Control
                    name="percentual_loss"
                    id="percentual_loss"
                    className=""
                    placeholder="%"
                  ></Form.Control>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModalPercentual}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal size="lg" show={atribuirMetalShow} onHide={closeModalPercentual}>
        <Modal.Header closeButton>
          <Modal.Title>Lançar recebimento de metal</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleAtribuicaoMetal}>
          <Modal.Body>
            <Card className="p-2 text-center card-one">
              <Row>
                <Col>
                  <Form.Label>Material:</Form.Label>
                  <Form.Select name="metal" id="metal">
                    <option value={""}>---Selecione---</option>
                    {material.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Quantidade</Form.Label>
                  <Form.Control
                    name="quantidade"
                    id="quantidade"
                    placeholder="Digite a quantidade:"
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>Data de Recebimento</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="datetime"
                    id="datetime"
                    className=""
                    placeholder="%"
                  ></Form.Control>
                </Col>
              </Row>
              <div className="w-100 mt-3">
                <Form.Label for="detalhamento">Detalhamento</Form.Label>
                <textarea
                  name="detalhamento"
                  id="detalhamento"
                  className="form-control"
                ></textarea>
              </div>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModalPercentual}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        size="lg"
        show={definirPermissoesModalShow}
        onHide={closeModalPercentual}
      >
        <Modal.Header closeButton>
          <Modal.Title>Definição de permissões</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSavePermission}>
            <Form.Select name="permission" id="permission">
              {/* //"type": "page", "id": 17, */}
              {typePermissions
                .filter(
                  (item) =>
                    !(item.type === "page" && [17, 25, 26].includes(item.id))
                )
                .filter(
                  (item) => !(item.type !== "page" && [19].includes(item.id))
                )
                ?.map((type) => (
                  <option key={type.id} value={type.type + "|" + type.id}>
                    {type.type} - {type.name}
                  </option>
                ))}
            </Form.Select>
            <Button className="py-1 mt-3" type="submit">
              Salvar
            </Button>
          </form>

          <Card className="p-2 mt-3">
            <Row>
              <Col md={12} className="d-flex flex-wrap">
                {userPermissions.map((type) => (
                  <Button
                    value={type.id}
                    className="rounded p-1 m-1 text-xl text-center d-flex align-items-center justify-content-between"
                    onClick={(e) => handlePermissionRemove(e.target.value)}
                  >
                    <Trash2 size={16} />
                    {type.name}
                  </Button>
                ))}
              </Col>
            </Row>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalPercentual}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={modalCadastroShow} onHide={handleClose}>
        <form onSubmit={handleCadastro}>
          <Modal.Header>
            <Modal.Title>Novo Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="g-3">
              <Card className="p-3">
                <Row>
                  <Col md="6">
                    <label for="email">Nome</label>
                    <Form.Control
                      className="w-100"
                      name="name"
                      id="name"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <label for="email">Nome Fantasia</label>
                    <Form.Control
                      className="w-100"
                      name="fantasyName"
                      id="fantasyName"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <label for="email">CPF / CNPJ</label>
                    <Form.Control
                      className="w-100"
                      name="cpf"
                      id="cpf"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <label for="email">Tipo de Usuário</label>
                    <Form.Select name="type_user" id="type_user">
                      <option value="">Selecione</option>
                      <option value="2">Colaborador</option>
                      <option value="7">Modelista</option>
                    </Form.Select>
                  </Col>
                  <Col md="6">
                    <label for="email">Email</label>
                    <Form.Control
                      className="w-100"
                      name="email"
                      id="email"
                      type="email"
                    />
                  </Col>
                  <Col md="6">
                    <label for="telefone">Telefone</label>
                    <Form.Control
                      className="w-100"
                      name="telefone"
                      id="telefone"
                      type="phone"
                    />
                  </Col>
                </Row>
              </Card>
              <Card className="p-3">
                <Row>
                  <Col md="6">
                    <label for="email">CEP</label>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Digite o cep e aperte <strong>TAB</strong>
                        </Tooltip>
                      }
                    >
                      <Info size={18} className="mx-1 mb-1" />
                    </OverlayTrigger>
                    <Form.Control
                      onBlur={(e) => {
                        if (e.target.value.length > 7) {
                          handleCep(e.target.value);
                        }
                      }}
                      className="w-100"
                      name="cep"
                      id="cep"
                      onChange={(e) => {
                        setEndereco({
                          ...endereco,
                          cep: e.target.value,
                        });
                      }}
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <label for="email">Rua</label>
                    <Form.Control
                      className="w-100"
                      name="rua"
                      id="rua"
                      value={endereco.rua}
                      onChange={(e) => {
                        setEndereco({
                          ...endereco,
                          rua: e.target.value,
                        });
                      }}
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <label for="numero">Numero</label>
                    <Form.Control
                      className="w-100"
                      name="numero"
                      id="numero"
                    ></Form.Control>
                  </Col>
                  <Col md="6">
                    <label for="complemento">Complemento</label>
                    <Form.Control
                      className="w-100"
                      name="complemento"
                      id="complemento"
                    ></Form.Control>
                  </Col>
                  <Col md="6">
                    <label for="numero">Bairro</label>
                    <Form.Control
                      className="w-100"
                      name="bairro"
                      id="bairro"
                      value={endereco.bairro}
                      onChange={(e) => {
                        setEndereco({
                          ...endereco,
                          bairro: e.target.value,
                        });
                      }}
                    ></Form.Control>
                  </Col>
                  <Col md="6">
                    <label for="cidade">Cidade</label>
                    <Form.Control
                      name="cidade"
                      id="cidade"
                      value={endereco.cidade}
                      onChange={(e) => {
                        setEndereco({
                          ...endereco,
                          cidade: e.target.value,
                        });
                      }}
                      className="w-100"
                    />
                  </Col>
                  <Col md="6">
                    <label for="numero">UF</label>
                    <Form.Control
                      name="estado"
                      id="estado"
                      value={endereco.estado}
                      onChange={(e) => {
                        setEndereco({
                          ...endereco,
                          estado: e.target.value,
                        });
                      }}
                      className="w-100"
                    ></Form.Control>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button type="submit" variant="primary">
                  Cadastrar
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Users;
