import api from "./api";

export const FixServices = {
	getOrder: (token, limit, page, search) => {
		return api.post("/repairController.php", {
			action: "read",
			page: page,
			limit: limit,
			search: search,
			token: token,
		});
	},

	createRepair: data => {
		return api.post("/repairController.php", {
			action: "create_repair",
			id_product: data.id_product,
			order_type: data.order_type,
			product_metal: data.product_metal,
			product_metal_color: data.product_metal_color,
			amount_product: data.amount_product,
			amount_product_metal: data.amount_product_metal,
			amount_product_metal_total: data.amount_product_metal_total,
			ordder_description: data.ordder_description,
			token: data.token,
			id_client: data.id_client,
			pair: data.pair,
		});
	},

	getOrderHistory: (order, token) => {
		return api.post("/repairController.php", {
			action: "orderHistory",
			id_order: order,
			token: token,
		});
	},

	getOrderView: (order, token) => {
		return api.post("/repairController.php", {
			action: "orderView",
			token: token,
			id_order: order,
		});
	},
	getCountStatus: token => {
		return api.post("/homeController.php", {
			action: "countStatus",
			token: token,
		});
	},

	orderStatusList: (order, token) => {
		return api.post("/orderController.php", {
			action: "selectList",
			type: "order_status_list",
			id_order: order,
			token: token,
		});
	},

	getMetal: (order, token) => {
		return api.post("/orderController.php", {
			action: "list",
			type: "metal",
			id_order: order,
			token: token,
		});
	},

	getModule: (order, token) => {
		return api.post("/orderController.php", {
			action: "list",
			type: "module",
			id_order: order,
			token: token,
		});
	},

	getNailing: (order, token) => {
		return api.post("/orderController.php", {
			action: "list",
			type: "nailing",
			id_order: order,
			token: token,
		});
	},

	getTypeNailing: (order, token) => {
		return api.post("/orderController.php", {
			action: "list",
			type: "type_nailing",
			id_order: order,
			token: token,
		});
	},

	getAttachment: (order, token) => {
		return api.post("/repairController.php", {
			action: "list",
			type: "attachment",
			id_order: order,
			token: token,
		});
	},

	// ########################### CLIENT SERVICES ###########################
	getClients: id => {
		return api.post("/profileController.php", {
			action: "data",
			id_user: id,
		});
	},

	getOrderPdf: data => {
		return api.post("/orderController.php", {
			action: "orderPDF",
			id_order: data.id_order,
			token: data.token,
		});
	},

	updateStatusOrder: data => {
		return api.post("/orderController.php", {
			action: "orderUpdateStatus",
			id_order: data.order,
			id_status: data.status,
			description: data.description,
			token: data.token,
		});
	},
	createRepairOrder: async data => {
		const filesBase64 = await convertFilesToBase64(data.file, data.fileName); // Convertendo os arquivos para Base64

		const jsonData = {
			action: "create_repair",
			description: data.descricao,
			id_client: data.cliente,
			id_category: data.categoria,
			weight: data.peso,
			fix_part_code: data.codicoPeca,
			fix_order_part_amount: data.quantidadePecas,
			id_material: data.material,
			id_material_color: data.cor,
			files: filesBase64,
			token: data.token,
		};

		try {
			const response = await api.post("/repairController.php", jsonData, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	finallyOrder: async data => {
		const filesBase64 = await convertFilesToBase64(data.file); // Convertendo os arquivos para Base64

		const jsonData = {
			action: "update_repair",
			id_order: data.id_order,
			description: data.descricao,
			weight: data.peso,
			files: filesBase64,
			token: data.token,
		};

		try {
			const response = await api.post("/repairController.php", jsonData, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
};

const convertFilesToBase64 = async (files, name) => {
	const promises = files.map(file => {
		if (!file || !file.preview) {
			console.error("Invalid file object or missing preview property:", file);
			return null;
		}

		return new Promise((resolve, reject) => {
			const extension = file.path.slice(-4);
			fetch(file.preview)
				.then(response => response.blob())
				.then(blob => {
					const reader = new FileReader();
					reader.onloadend = () => {
						const base64data = reader.result.split(",")[1];
						resolve({
							fileName: name + extension || "Unknown",
							file: base64data,
						});
					};
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
				.catch(error => {
					console.error("Error fetching file for conversion:", error);
					reject(error);
				});
		});
	});

	try {
		const base64Files = await Promise.all(promises);
		return base64Files.filter(file => file !== null);
	} catch (error) {
		console.error("Error converting files to base64:", error);
		throw error;
	}
};
