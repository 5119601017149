import React, { createContext, useEffect, useState } from "react";
import { userServices } from "../../apiServices/userServices";
import { data } from "jquery";
import BalanceServices from "../../apiServices/BalanceServices/ApiServices";
import AccountServices from "../../apiServices/AccountServices/ApiServices";

const BalanceContext = createContext();

const token = localStorage.getItem("token");

const BalanceProvider = ({ children }) => {
  const [operationList, setOperationList] = useState([]);
  const [totalBreak, setTotalBreak] = useState([]);
  const [operationDetail, setOperationDetail] = useState([]);
  const [operationCode, setOperationCode] = useState([]);
  const [operationType, setOperationType] = useState([]);
  const [movimentationType, setMovimentationType] = useState([]);

  const insertBalanceData = async data => {
    const payload = {
      id_user: data.id_user,
      id_orders: data.id_orders,
      operation_type: data.operation_type,
      operation_code: data.operation_code,
      permission_code: data.permission_code,
      movimentation_type: data.movimentation_type,
      hand_water_real_weight: data.hand_water_real_weight,
      total_weight: data.total_weight,
      descricao: data.detalhamento,
      detail: data.detail,
      token: token,
    };

    return await userServices.operationAdd(payload);
  };

  const accountOperation = async id => {
    const payload = {
      selectedUser: id,
      material: data.material ? data.material : null,
      token: token,
    };
    return await AccountServices.accountOperationList(payload);
  };

  const getOperationType = async () => {
    const payload = {
      token: token,
    };

    const response = await BalanceServices.listOperationType(payload);
    setOperationType(response.data);
    return response;
  };

  const getOperationCode = async data => {
    const payload = {
      token: token,
      id_operation_type: data?.id_operation_type ? data.id_operation_type : "",
    };
    const response = await BalanceServices.listOperationCode(payload);
    setOperationCode(response.data);
    return response;
  };

  const listOperation = async (id, permission_code) => {
    const payload = {
      token: token,
      id_user: id,
      permission_code: permission_code,
    };

    const response = await BalanceServices.listOperation(payload);
    setOperationList(response.data.result);
  };

  const listMovimentationType = async () => {
    const payload = {
      token: token,
    };
    const response = await BalanceServices.listMovimentation(payload);
    setMovimentationType(response.data.result);
    return response;
  };

  const getTotalBreak = async selectedUser => {
    const payload = {
      selectedUser: selectedUser.id_user,
      permission_code: selectedUser.permission_type,
      token: token,
    };

    const response = await BalanceServices.getTotalBreak(payload);
    setTotalBreak(response.data.result);
  };

  const getOperationDetail = async id => {
    const payload = {
      token: token,
      id_operation: id,
    };

    const response = await BalanceServices.getOperationDetail(payload);
  };

  return (
    <BalanceContext.Provider
      value={{
        operationList,
        totalBreak,
        operationCode,
        movimentationType,
        insertBalanceData,
        getTotalBreak,
        accountOperation,
        getOperationType,
        getOperationCode,
        listOperation,
        listMovimentationType,
        getOperationDetail,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export { BalanceContext, BalanceProvider };
