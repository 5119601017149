import React, { useEffect, useState } from "react";
import { Nav, ButtonGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./ProductComponent.module.css";
import { apiServices } from "../../apiServices/ApiServices";

export const Product = () => {
    const token = localStorage.getItem('token');
    const [selectedOption, setSelectedOption] = useState("#btncheck1");
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <Form.Control className={styles.search_form} placeholder="Pesquisar">
                </Form.Control>
                <ButtonGroup aria-label="Basic checkbox toggle button group">
                    <input type="radio" className="btn-check" name="option" id="btncheck1" value={0} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor="btncheck1">Todos os Produtos</label>

                    <input type="radio" className="btn-check" name="option" id="btncheck2" value={1} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor="btncheck2">CriatGold</label>

                    <input type="radio" className="btn-check" name="option" id="btncheck3" value={2} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor="btncheck3">Meus Produtos</label>
                </ButtonGroup>
            </div>
            <h3>
                Este será um componente para novos pedidos. Será exibida uma listagem
                com os produtos cadastrados e a opção de selecionar os produtos, definir
                a quantidade etc.
            </h3>
        </div>
    );
};

export default Product;
