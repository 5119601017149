import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import pageSvg from "../assets/svg/forgot_password.svg";
import { userServices } from "../apiServices/userServices";
import { Eye, EyeOff } from "lucide-react";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/img/logo.webp"; import logoBranca from "../assets/img/logo-branca.png";

export default function ForgotPassword() {

  const skin = localStorage.getItem('skin-mode');

  const [typeInput, setTypeInput] = useState('password');
  const [enviado, setEnviado] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataReset, setDataReset] = useState({
    email: "",
    code: "",
    password1: "",
    password2: ""
  })

  const togglePasswordVisibility = () => {
    setTypeInput((prevType) => (prevType === 'password' ? 'text' : 'password'));
    setShowPassword((prevShow) => !prevShow);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      email: formData.get("email"),
    };
    setDataReset({
      ...dataReset,
      email: formData.get("email"),
    });

    toast.loading("Aguarde...", { position: toast.POSITION.BOTTOM_CENTER })
    const response = await userServices.recoveryCode(data);
    toast.dismiss();
    if (response.status === 200) {
      toast.success("Enviado com Sucesso!", { position: toast.POSITION.BOTTOM_CENTER });
      setTimeout(() => {
        setEnviado(true)
      }, 2000)
    } else {
      toast.error(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER });
    }
  };

  const reset = async (e) => {
    e.preventDefault()
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER })
    const formData = new FormData(e.target);


    const response = await userServices.createPassword(dataReset);
    toast.dismiss()
    if (response.data.status === 'error') {
      toast.error(response.data.msg, {position: toast.POSITION.BOTTOM_CENTER})
    } else if(response.data.status === "success") (
      toast.success(response.data.msg, {position: toast.POSITION.BOTTOM_CENTER})

    )
  }

  document.body.classList.remove("sidebar-show");

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link target="_blank" to="/" className=""><img src={skin && skin === "dark" ? logoBranca: logo} width={100}></img></Link>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>
              <Card.Title>Recuperação de senha de acesso</Card.Title>
              {!enviado ?
                (
                  <Card.Text className="mb-5">Digite o seu e-mail, enviaremos um código para redefinição de senha.</Card.Text>
                ) :
                (
                  <Card.Text className="mb-5">Digite o código de segurança que enviamos em seu e-mail. Depois, digite e confirme sua nova senha.</Card.Text>
                )}
              <form className="g-3 p-0" onSubmit={handleReset}>
                <Row className="g-2 p-0 d-flex align-items-center justify-content-center">
                  <Col sm="12" className="g-3 p-0">
                    <Form.Control
                      className="text-center w-100"
                      required type="email"
                      disabled={enviado ? "disabled" : ""}
                      name="email"
                      id="email"
                      placeholder="Email" />
                  </Col>

                  <Col sm="5"><Button type="submit" className="" hidden={enviado ? "hidden" : ""} >Enviar Código</Button></Col>
                </Row>
              </form>
              {enviado ? (
                <form onSubmit={reset} className="g-3 p-0">
                  <Row className="g-2 p-0 d-flex align-items-center justify-content-center">

                    <Col sm="12" className=" g-3 p-0">
                      <Form.Control
                        className="text-center w-100"
                        required type="text"
                        onChange={(e) => { setDataReset({ ...dataReset, code: e.target.value }) }}
                        name="code"
                        id="code"
                        placeholder="Código recebido" />
                    </Col>

                    <Col sm="12" className="g-3 p-0">
                      <Form.Control
                        className="text-center w-100"
                        required
                        type={typeInput}
                        onChange={(e) => { setDataReset({ ...dataReset, password1: e.target.value }) }}
                        name="password"
                        id="password"
                        placeholder="Nova senha"
                      />
                    </Col>

                    <Col sm="12" className="g-3 p-0">
                      <Form.Control
                        className="text-center"
                        required
                        type={typeInput}
                        onChange={(e) => { setDataReset({ ...dataReset, password2: e.target.value }) }}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirme a nova senha"
                      />
                    </Col>

                    <Col sm="12" className="g-3 p-0">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {!showPassword ? "Visualizar senha" : "Ocultar senha"}
                          </Tooltip>
                        }
                      >
                        <Button
                          className="password-toggle"
                          variant="link"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <EyeOff /> : <Eye />}
                        </Button>
                      </OverlayTrigger>

                    </Col>

                    <Col sm="5">
                      <Button
                        type="submit"
                        hidden={enviado ? "" : "hidden"}
                        variant="primary">
                        Confirmar
                      </Button>

                    </Col>

                  </Row>
                </form>
              ) : (
                <>
                </>
              )}
              <Row className="p-5">
                <Link to="/">Voltar para tela de Login</Link>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <ToastContainer />
    </div>
  );
}