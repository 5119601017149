import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/img1.jpg";
import { apiServices } from "../apiServices/ApiServices";
import { UserContext } from "../Context/UserContext";
import { CarrinhoButton } from "../components/Carrinho/CarrinhoButton";
import { Badge, Button } from "react-bootstrap";

export default function Header({ onSkin, cart, sendDataToParent }) {
  const { userData, login, plNome, plSobrenome } = useContext(UserContext);
  const [skin, setSkin] = useState(localStorage.getItem("skin-mode"));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSkin(localStorage.getItem("skin-mode"));
  }, [localStorage.getItem("skin-mode")]);

  const handleOpenCart = () => {
    sendDataToParent(true);
  };

  const token = localStorage.getItem("token");
  const fullName = localStorage.getItem("name");
  const name = fullName.split(" ")[0];
  const au = localStorage.getItem("au");
  const usd = localStorage.getItem("usd");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    window.location.reload();
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  // function NotificationList() {
  //   const notiList = notification.map((item, key) => {
  //     return (
  //       <li className="list-group-item" key={key}>
  //         <div
  //           className={item.status === "online" ? "avatar online" : "avatar"}
  //         >
  //           {item.avatar}
  //         </div>
  //         <div className="list-group-body">
  //           <p>{item.text}</p>
  //           <span>{item.date}</span>
  //         </div>
  //       </li>
  //     );
  //   });

  //   return <ul className="list-group">{notiList}</ul>;
  // }

  const skinMode = (e) => {
    e.preventDefault();
    const isDarkMode = localStorage.getItem("skin-mode") === "dark";
    const HTMLTag = document.querySelector("html");

    if (isDarkMode) {
      HTMLTag.removeAttribute("data-skin");
      localStorage.setItem("skin-mode", "light");
      setSkin("light"); // Atualiza o estado 'skin' para 'light'
      onSkin(""); // Chama a função 'onSkin' com o argumento vazio
    } else {
      HTMLTag.setAttribute("data-skin", "dark");
      localStorage.setItem("skin-mode", "dark");
      setSkin("dark"); // Atualiza o estado 'skin' para 'dark'
      onSkin("dark"); // Chama a função 'onSkin' com o argumento 'dark'
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="w-100 d-flex justify-content-end flex-wrap">
        {screenWidth < 1000 ? (
          <Dropdown>
            <Dropdown.Toggle>Commodities</Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                <div className="d-flex">
                  <span className="mx-2">USD: {usd}</span>
                  <span className="mx-2">AU: {au}</span>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <>
            <Button
              className="mx-2"
              variant={"primary"}
              style={{ cursor: "default", minWidth: "80px" }}
            >
              USD {usd}
            </Button>
            <Button
              className="mx-2"
              variant={"primary"}
              style={{ cursor: "default", minWidth: "80px" }}
            >
              AU {au}
            </Button>
          </>
        )}
      </div>

      {cart && cart.length > 0 ? (
        <CarrinhoButton onClick={handleOpenCart}>
          <span className="d-flex flex-column p-0 m-0">
            <span style={{ marginBottom: "-10px" }}>{cart.length}</span>
            <i className="mt-0 ri-shopping-cart-line h3"></i>
          </span>
        </CarrinhoButton>
      ) :
        null}

      <Dropdown
        className={`d-flex flex-row dropdown-profile ms-3 ms-xl-4 justify-content-end ${screenWidth < 1537 && screenWidth > 1100 ? 'w-30' : screenWidth < 1100 && screenWidth > 1000 ? 'w-50' : screenWidth < 1000 ? 'w-20' : "w-20"}`}
        align="end"
      >
        <Link onClick={skinMode} className={"nav-link active mt-2"}>
          <h2 className="d-flex align-items-center justify-content-center p-0">
            {skin === "dark" ? (
              <i className="ri-moon-line mx-3"></i>
            ) : (
              <i className="ri-sun-line mx-3"></i>
            )}
          </h2>
          <span className="d-none">Toggle Theme</span>
        </Link>

        <Dropdown.Toggle as={CustomToggle}>

          <div className="d-flex flex-row justify-content-center align-items-center py-2 gap-2 ">
            <div className="avatar online d-flex">
              <div
                className="d-flex flex-row  rounded-circle w-100 align-items-center justify-content-center  font-weight-bold"
                style={{
                  border: "2px solid #506fd9",
                  color: "#506fd9",
                  fontWeight: "bold",
                  fontSize: "100%", // Ajusta o tamanho da fonte para controlar o tamanho do círculo
                  width: "250px", // Define a largura do círculo
                  height: "100%", // Define a altura do círculo
                }}
              >
                {/* Utilize media queries do CSS para ajustar o tamanho do texto */}
                <span className="d-none d-lg-inline">
                  {localStorage.getItem("name").split(" ")[0][0]}
                </span>
                <span className="d-none d-lg-inline">
                  {localStorage.getItem("name").split(" ")[1][0]}
                </span>
                <span className="d-lg-none">
                  {localStorage.getItem("name").split(" ")[0][0]}
                  {localStorage.getItem("name").split(" ")[1][0]}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column userName">
              <span className="text-primary">
                Olá, {localStorage.getItem("name").split(" ").shift()}
              </span>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="my-2 mt-10-f">
          <div className="dropdown-menu-body">
            <nav className="nav">
              <Link to="/perfil">
                <i className="ri-profile-line"></i> Meu perfil
              </Link>
              <Link
                className={`${screenWidth < 1100 ? "" : "d-none"}`}
                onClick={logout}
              ><i className="ri-logout-box-r-line"></i>
                Sair

              </Link>
            </nav>

          </div>

        </Dropdown.Menu>
      </Dropdown>
      <Button
        variant="outline-primary"
        className={`mx-3 border-0 d-flex flex-row justify-content-center align-items-start py-0 gap-2 ${screenWidth < 1100 ? "d-none" : null}`}
        onClick={logout}
      >
        Sair
        <i className="ri-logout-box-r-line"></i>
      </Button>


    </div>
  );
}
