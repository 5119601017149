import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Table, Button, Alert, Card } from "react-bootstrap";
import { apiServices } from "../../apiServices/ApiServices";
import { Resumo } from "../ListaPedidos/Resumo";
import { ToastContainer, toast } from "react-toastify";
import { userServices } from "../../apiServices/userServices";
import { BalanceContext } from "../../Context/BalanceContext/BalanceContext";
import { OrderContext } from "../../Context/OrderContext";
export const RetornoOurives = ({
  idUser,
  permissionType,
  permissionUpdate,
  page,
  status,
  sendDataToParent,
  openModalPeso,
  permissionFetch
}) => {
  const token = localStorage.getItem("token");
  const inputRef = useRef(null);
  const [pedidos, setPedidos] = useState([]);
  const [value, setValue] = useState("");
  const [codigoPedidos, setCodigoPedidos] = useState([]);

  const [usuarios, setUsuarios] = useState([]);
  const [lote, setLote] = useState([]);
  const [userOrder, setUserOrder] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const { getOperationCode } = useContext(BalanceContext);
  const [operationCode, setOperationCode] = useState([]);

  const handleOperationCode = async () => {
    const payload = {
      id_operation_type: 2,
    };
    const response = await getOperationCode(payload);
    setOperationCode(response.data);
  };

  useEffect(() => {
    handleOperationCode();
  }, []);

  const [selectedOperationCode, setSelectedOperationCode] = useState(null);
  const handleSetOperationCode = (e) => {
    setSelectedOperationCode(e);
  };

  const [selectedOperationType, setSelectedOperationType] = useState(null);
  const handleSetOperationType = (e) => {
    setSelectedOperationType(e);
  };

  const [selectedMovimentationType, setMovimentationType] = useState(null);
  const handlesetMovimentationType = (e) => {
    setMovimentationType(e);
  };

  const [pesoTotal, setPesoTotal] = useState(0);
  const handleSetPesoTotal = (e) => {
    setPesoTotal(e);
  };

  const [detail, setDetail] = useState(null);
  const handleSetDetail = (e) => {
    setDetail(e);
  };

  const handleGetUsuarios = async () => {
    const request = {
      token: token,
      pageSearch: page,
      permission_type: permissionType,
      page: 1,
      limit: 9999,
    };
    const response = await userServices.getUsers(request);
    if (response) {
      setUsuarios(response.data.users);
    }
  };

  const handleGetData = (data) => {
    setLote(data);
  };

  const handleModalEntered = () => {
    inputRef.current && inputRef.current.focus();
  };

  const [modalHidden, setModalHidden] = useState("block");
  const [modalPeso, setModalPeso] = useState([]);

  const handleAddPeso = (pedido) => {
    openModalPeso({ data: pedido, open: true });
  };

  

  const handleUpdate = async () => {
    handleUpdateStatus(codigoPedidos, token, status);
  };

  const handleUpdateStatus = async (orders, token, id_status) => {
    const data = {
      order: orders,
      token: token,
      status: 23,
      operation_code: selectedOperationCode,
      operation_type: 1,
      total_weight: pesoTotal,
      selectedUser: selectedUser,
      movimentation_type: selectedMovimentationType,
      detail: detail,
      id_page: page,
      description: `Atualização em massa! lote: ${lote.lote}`,
      lote: {
        batch: lote.lote,
        order_weight: lote.peso,
      },
    };
    toast.dismiss();
    try {
      if (orders.length > 0) {
        const response = await apiServices.updateStatusOrder(data);
        if (response.data.status === "success") {
          toast.success(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          sendToParent(true);
          setTimeout(() => {
            setPedidos([]);
            setLote([]);
            setValue([]);
            setCodigoPedidos([]);
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          sendToParent(false);
        }
      }
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
    }
  };

  let timerId = null;

  const handleFetchOrder = async () => {
    toast.loading("Processando...");
    codigoPedidos.map(async order => await handleGetOrderOurives(token, order));
    toast.dismiss();
    toast.success("Pedidos atualizados com sucesso!");
  }
  const [permissionFetchState, setPermissionFetchState] = useState(permissionFetch)
  useEffect(() => { handleFetchOrder() }, [permissionFetchState]);

  const handleGetOrderOurives = async (token, order) => {
    let response;
  
    toast.loading("Processando...");
    response = await apiServices.getOrder(
      token,
      1,
      1,
      order,
      null,
      null,
      true,
      true,
      idUser
    );
  
    if (response.data.orders.length !== 0) {
      const existingOrderIndex = pedidos.findIndex(
        (item) => item.CODE === response.data.orders[0].CODE
      );
      const isAllowed =
        response.data.orders[0].ORDER_PAGE === 13 &&
        response.data.orders[0].ID_STATUS === 18;
  
      if (existingOrderIndex === -1 && isAllowed) {
        // Pedido não existe e está na etapa autorizada
        setPedidos((prevPedidos) => [...prevPedidos, response.data.orders[0]]);
        setCodigoPedidos((prevPedidos) => [
          ...prevPedidos,
          response.data.orders[0].ID,
        ]);
        toast.dismiss();
        toast.success("Pedido adicionado com sucesso!");
        setValue("");
        handleModalEntered();
      } else if (!isAllowed) {
        // Pedido não está na etapa autorizada
        toast.dismiss();
        toast.error("Este pedido não está na etapa autorizada.");
        setValue("");
        handleModalEntered();
      } else {
        // Pedido já existe, atualize o registro
        const updatedPedidos = [...pedidos];
        updatedPedidos[existingOrderIndex] = response.data.orders[0];
        setPedidos(updatedPedidos);
        
        toast.dismiss();
        toast.success("Pedido atualizado com sucesso!");
        setValue("");
        handleModalEntered();
      }
      setPermissionFetchState(false);
    }
  };
  

  const handleGetOrder = async (token, order) => {
    let response;

    if (order.length === 10 || order.length === 5 || order.length === 4) {
      toast.loading("Processando...");
      response = await apiServices.getOrder(
        token,
        1,
        1,
        order,
        null,
        null,
        true,
        true,
        idUser
      );
    }

    if (response.data.orders.length !== 0) {
      const existe = pedidos.find(
        (item) => item.CODE === response.data.orders[0].CODE
      );
      const permite =
        response.data.orders[0].ORDER_PAGE === 13 &&
        response.data.orders[0].ID_STATUS === 18;

      if (!existe && permite) {
        setPedidos((prevPedidos) => [...prevPedidos, response.data.orders[0]]);
        setCodigoPedidos((prevPedidos) => [
          ...prevPedidos,
          response.data.orders[0].ID,
        ]);
        toast.dismiss();
        toast.success("Pedido adicionado com sucesso!");
        setValue("");
        handleModalEntered();
      } else if (!permite) {
        toast.dismiss();
        toast.error("Este pedido não está na etapa autorizada.");
        setValue("");
        handleModalEntered();
      } else {
        toast.dismiss();
        toast.error("Este pedido já foi adicionado.");
        setValue("");
        handleModalEntered();
      }
    }
  };

  const removeOrder = (orderCode) => {
    const index = pedidos.findIndex((pedido) => pedido.CODE === orderCode);
    if (index > -1) {
      const updatedPedidos = [...pedidos];
      updatedPedidos.splice(index, 1);
      const updatedCodigo = [...updatedPedidos.map((pedido) => pedido.CODE)];
      setPedidos(updatedPedidos);
      setCodigoPedidos(updatedCodigo);
      toast.success("Pedido removido com sucesso!");
    }
  };

  const handleGetCode = (e) => {
    const valor = e.target.value;
    setValue(valor);

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      handleGetOrder(token, valor);
    }, 1000);
  };

  const sendToParent = (value) => {
    sendDataToParent(value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current && inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (permissionUpdate === true) {
      handleUpdate();
    }
  }, [permissionUpdate]);

  useEffect(() => {
    handleGetUsuarios();
  }, []);

  return (
    <>
      <button
        className="w-100 bg-transparent border border-light"
        onClick={() => {
          if (page !== 13) {
            inputRef.current.focus();
          }
        }}
      >
        <div className="d-flex gap-3 flex-column align-items-center justify-content-center">
          <div className="d-flex w-100 flex-row gap-3 align-items-center justify-content-between">
            {operationCode ? (
              <Card className="w-100">
                <Card.Body>
                  <Form.Group className="w-100">
                    <Form.Label className="text-center w-100">
                      Código da operação
                    </Form.Label>
                    <Form.Select
                      style={{ fontSize: "20px" }}
                      onChange={(e) =>
                        handleSetOperationCode(e.target.value, pedidos.ID)
                      }
                      required
                    >
                      <option className="text-start" value={null}>
                        --Selecione--
                      </option>
                      {operationCode &&
                        operationCode.map((o, i) => (
                          <option
                            name="operation_code"
                            id="operation_code"
                            className="text-start m-3"
                            value={o.id_operation_code}
                          >
                            {o.operation_code_name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>
            ) : null}

            <Card className="w-100 h-100">
              <Card.Body className="h-100">
                <Form.Label className="text-center w-100">
                  Aguarde o leitor ou digite o código do pedido
                </Form.Label>
                <Form.Control
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                  type="text"
                  className="bg-white display-3 h-20 z-3 w-100 text-center"
                  ref={inputRef}
                  autoFocus={page !== 13}
                  id="code-form"
                  value={value}
                  onChange={handleGetCode}
                />
              </Card.Body>
            </Card>
          </div>

          {(status !== 5 && page === 11) || page === 13 ? (
            <></>
          ) : (
            <Resumo dados={pedidos} sendDataToParent={handleGetData} />
          )}
        </div>

        <div className="mt-3 w-100" style={{ overflowX: "auto" }}>
          <Table
            className="table-four table-bordered"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th className="text-center w-60">Cod.Pedido</th>
                <th className="text-center w-60">Cliente</th>
                <th className="text-center w-60">Cod.Peça</th>
                <th className="text-center w-60">Data</th>
                <th className="text-center w-50">Status</th>
                <th className="text-center w-50">Peso</th>
                <th className="text-center w-30">Remover</th>
              </tr>
            </thead>
            <tbody>
              {pedidos.length > 0 ? (
                pedidos.map((pedido) => (
                  <tr>
                    <td className="text-center w-100">{pedido.CODE}</td>
                    <td className="text-center w-100">{pedido.NAME_CLIENT}</td>
                    <td className="text-center w-100">{pedido.PART_CODE}</td>
                    <td className="text-center w-100">{pedido.DATE}</td>
                    <td className="text-center w-100">{pedido.STATUS}</td>
                    <td className="text-center w-100">
                      {pedido.ACABAMENTO_FINAL_WEIGHT &&
                      pedido.ACABAMENTO_FINAL_WEIGHT !== null &&
                      pedido.ACABAMENTO_FINAL_WEIGHT > 0 ? (
                        pedido.ACABAMENTO_FINAL_WEIGHT
                      ) : (
                        <Button onClick={(e) => handleAddPeso(pedido)}>
                          Adicionar peso no pedido
                        </Button>
                      )}
                    </td>
                    <td className="text-center w-100">
                      <Button
                        variant="danger"
                        onClick={(e) => removeOrder(pedidos.CODE)}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="w-100 flex text-center">
                  <td colSpan={9}>
                    <Alert>Nenhum pedido adicionado</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {page !== 13 ? null : (
            <Card className="mt-3 d-flex flex-row gap-3 w-100">
              <div className="h-100 w-100">
                <Card.Body className="h-100">
                  <Form.Label className="text-center w-100">
                    Peso total:
                  </Form.Label>
                  <Form.Control
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                    type="text"
                    onChange={(e) => handleSetPesoTotal(e.target.value)}
                    value={pesoTotal}
                    className="bg-white display-3 h-20 z-3 w-100 text-center"
                    name="peso-total"
                    id="peso-total"
                  />
                </Card.Body>
              </div>
              <Card className="h-100 w-100 card-one">
                <Card.Body className="h-100">
                  <Form.Label>Detalhamento</Form.Label>
                  <Form.Control
                    onChange={(e) => handleSetDetail(e.target.value)}
                    name="detail"
                    id="detail"
                    as="textarea"
                  ></Form.Control>
                </Card.Body>
              </Card>
            </Card>
          )}
        </div>
      </button>

      <ToastContainer />
    </>
  );
};
