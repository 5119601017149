import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Lotes from "../components/Lotes";
import Lista from "../components/Lista";
import { toast, ToastContainer } from "react-toastify";
import { apiServices } from "../apiServices/ApiServices";
import { FloatButton } from "../components/FloatButton";
import { ListaPedidos } from "../components/ListaPedidos/ListaPedidos";
import { Dialog } from "../components/DialogConfirmacao";
import ListaPolimento from "../components/ListaPolimento/ListaDePedidosPolimento";
import { EvolucaoPedidos } from "../components/ListaPedidos/EvolucaoPedidos";

const Polimento = () => {
	const [show, setShow] = useState(false);
	const token = localStorage.getItem("token");
	const page = 15;
	const status = 9;
	const [dnone, setDnone] = useState("");
	const [buscar, setBuscar] = useState(false);
	const [nextPage, setNextPage] = useState(false);
	const [modalEvoluir, setModalEvoluir] = useState(false);
	const [responseDialogEvolucao, setResponseDialogEvolucao] = useState(false);
	const [permissionUpdateEvolucao, setPermissionUpdateEvolucao] = useState(false);

	const handleOpenEvoluirPedidos = () => {
		setModalEvoluir(true);
	};

	const [dialogShow, setDialogShow] = useState(false);
	const [responseDialog, setResponseDialog] = useState(false);

	const [dNoneEvolucao, setDnoneEvolucao] = useState("");

	const [dialogEvolucaoShow, setDialogEvolucaoShow] = useState(false);
	const closeDialogEvolucao = () => {
		setDialogEvolucaoShow(false);
		setDnoneEvolucao("");
	};

	const handleCloseEvoluirPedidos = () => {
		setModalEvoluir(false);
	};

	const handleChildDataEvolucao = e => {
		setResponseDialogEvolucao(e);
	};

	useEffect(() => {
		if (responseDialogEvolucao === true) {
			closeDialogEvolucao();
			setDnoneEvolucao("");
			toast.loading("Processando...", {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			setTimeout(() => {
				setPermissionUpdateEvolucao(true);
				setResponseDialogEvolucao(false);
			}, 1000);
		}
	}, [responseDialogEvolucao]);

	const [showEnterMaterial, setShowEnterMaterial] = useState(false);

	const [permissionUpdate, setPermissionUpdate] = useState(false);

	const [materialRecebido, setMaterialRecebido] = useState(0);
	const [inputs, setInputs] = useState([""]);
	const [materialParent, setMaterialParent] = useState([]);

	const handleGetNextPage = async () => {
		const data = {
			page: page + 1,
			token: token,
		};
		const response = await apiServices.getPage(data);
		if (response) {
			setNextPage(response.data.data);
		}
	};

	const handleGetMaterialParent = e => {
		if (e) {
			setMaterialParent(e);
		}
	};

	const handleGetDataParent = value => {
		if (value) {
			closeModal();
			setBuscar(true);
		}
	};

	const showModalMaterial = () => {
		setShowEnterMaterial(true);
	};

	const closeModalMaterial = () => {
		setShowEnterMaterial(false);
	};

	const handleGetMaterial = e => {
		e.preventDefault();
		const formData = new FormData(e.target);

		const value = formData.get("material");
	};

	const hideModal = () => {
		setDnone("d-none");
	};

	const closeModal = () => {
		setShow(false);
	};

	const showModal = () => {
		setShow(true);
	};

	const handleChildData = e => {
		setResponseDialog(e);
	};

	const closeDialog = () => {
		setDialogShow(false);
		setDnone("");
	};

	const addInput = () => {
		const newInputs = [...inputs, ""];
		setInputs(newInputs);
	};

	// Função para atualizar o valor de um input específico
	const handleInputChange = (index, event) => {
		const newInputs = [...inputs];
		newInputs[index] = event.target.value;
		setInputs(newInputs);
	};

	useEffect(() => {
		if (responseDialog === true) {
			closeDialog();
			showModal();
			toast.loading("Processando...", {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			setTimeout(() => {
				setPermissionUpdate(true);
				setResponseDialog(false);
			}, 1000);
		}
	}, [responseDialog]);

	const hideModalEvolucao = () => {
		setDnoneEvolucao("d-none");
	};

	useEffect(() => {
		handleGetNextPage();
	}, []);

	return (
		<React.Fragment>
			<Header />
			<div className="main main-app p-3 p-lg-4">
				<div className="d-md-flex align-items-center justify-content-between mb-4">
					<div>
						<h4 className="main-title mb-0">Polimento/ Ródio</h4>
					</div>
				</div>

				<Row className="g-3">
					<Col xl="12">
						<Card className="card-one">
							<Card.Body className="d-flex justify-content-between">
								<div>
									<Button className="font-lg text-center d-flex align-items-center justify-content-center rounded-md" variant="primary" onClick={() => showModal()}>
										Iniciar Polimento
									</Button>
								</div>
								<div>
									<Button onClick={handleOpenEvoluirPedidos}>Evoluir pedidos em massa</Button>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col xl="12">
						<ListaPolimento nextPage={nextPage} titulo={"Polimento/ Ródio"} page={page} status={status} buscar={buscar} />
					</Col>
				</Row>
			</div>

			<Modal size="xl" className={dnone} backdrop="static" show={show} onHide={closeModal}>
				<Modal.Header closeButton>
					<h4>Recebimento de pedidos</h4>
				</Modal.Header>
				<Modal.Body>
					<ListaPedidos
						permissionUpdate={permissionUpdate}
						materialTotal={materialRecebido}
						page={page}
						status={status}
						sendDataToParent={handleGetDataParent}
						sendMaterialToParent={handleGetMaterialParent}
					/>
				</Modal.Body>

				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							setDialogShow(true);
							hideModal();
						}}
					>
						Finalizar
					</Button>
					<Button variant="danger" onClick={closeModal}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal size="xl" className={dNoneEvolucao} backdrop="static" keyboard={false} show={modalEvoluir} onHide={handleCloseEvoluirPedidos}>
				<Modal.Header closeButton>
					<h4>Evolução de pedidos</h4>
				</Modal.Header>
				<Modal.Body>
					<EvolucaoPedidos
						nextPage={nextPage}
						permissionUpdate={permissionUpdateEvolucao}
						materialTotal={materialRecebido}
						page={page}
						status={status}
						sendDataToParent={handleGetDataParent}
						sendMaterialToParent={handleGetMaterialParent}
					/>
				</Modal.Body>

				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							setDialogEvolucaoShow(true);
							hideModalEvolucao();
						}}
					>
						Finalizar
					</Button>
					<Button variant="danger" onClick={handleCloseEvoluirPedidos}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>

			<Dialog
				showDialog={dialogEvolucaoShow}
				closeDialog={closeDialogEvolucao}
				sendDataToParent={handleChildDataEvolucao}
				title={`Saída em massa de pedidos`}
				question={`Deseja realmente dar saída em massa de pedidos?`}
			/>

			<ToastContainer />
			<Dialog
				showDialog={dialogShow}
				closeDialog={closeDialog}
				sendDataToParent={handleChildData}
				title={"Iniciar fundição"}
				question={"Deseja realmente começar o acabamento dos pedidos?"}
			/>
		</React.Fragment>
	);
};

export default Polimento;
