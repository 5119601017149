import { Card, Form } from "react-bootstrap";
import ListaPedidosOurives from "../../ListaOurives/ListaPedidosOurives";
import { userServices } from "../../../apiServices/userServices";
import { useContext, useEffect, useState } from "react";
import { BalanceContext } from "../../../Context/BalanceContext/BalanceContext";

export const SaidaPedidos = ({
  page,
  setStatusToFetch,
  returnStatusToFech,
}) => {
  const { getOperationType, getOperationCode, listMovimentationType } =
    useContext(BalanceContext);
  const token = localStorage.getItem("token");
  const [operationTypeState, setOperationType] = useState([]);
  const [operationCode, setOperationCode] = useState([]);

  const [movimentationType, setMovimentationType] = useState([]);

  const handleGetOperationType = async () => {
    const response = await getOperationType();
    setOperationType(response.data);

    const payload = {
      id_operation_type: 2,
    };

    const responseOperationCode = await getOperationCode(payload);
    setOperationCode(responseOperationCode.data.sort());

    const responseMovimentationType = await listMovimentationType();
    setMovimentationType(responseMovimentationType.data.result);
  };

  useEffect(() => {
    handleGetOperationType();
  }, []);
  const [usuarios, setUsuarios] = useState([]);
  const handleGetUsuarios = async () => {
    const request = {
      token: token,
      pageSearch: null,
      permission_type: [14, 15],
      page: 1,
      limit: 9999,
    };
    const response = await userServices.getUsers(request);
    if (response) {
      setUsuarios(response.data.users);
    }
  };

  const [selectedUser, setSelectedUser] = useState("");
  
  const handleSetSelectedUser = user => {
    const userSplit = user.split(" ");
    const userId = userSplit[0];
    const userPermission = userSplit[1];
    setSelectedUser({
      id_user: userId,
      permission_type: userPermission,
    });
  };

  useEffect(() => {
    handleGetUsuarios();
  }, []);

  const [userPermissionInterno, setUserPermissionInterno] = useState(false);
  const [userPermissionExterno, setUserPermissionExterno] = useState(false);
  const verifyUserPermission = () => {
    if (usuarios.some(user => user.PERMISSION_CODE == 14)) {
      setUserPermissionInterno(true);
    }

    if (usuarios.some(user => user.PERMISSION_CODE == 15)) {
      setUserPermissionExterno(true);
    }
  };

  useEffect(() => {
    verifyUserPermission();
  }, [usuarios]);

  return (
    <div className="d-flex flex-column gap-3 p-3">
      <Card className="card-one">
        <Card.Body>
          <Card.Title>Selecione o ourives</Card.Title>
          <Form.Select onChange={e => handleSetSelectedUser(e.target.value)}>
            <option value=""></option>
            <option className="text-start p-2 rounded" value={null}>
              --Selecione--
            </option>

            {userPermissionInterno ? (
              <optgroup className="p-2" label="Ourives Internos">
                {usuarios.map(u => {
                  if (u.PERMISSION_CODE === 14) {
                    return (
                      <option
                        className="text-start p-3"
                        value={u.ID + " " + u.PERMISSION_CODE}
                      >
                        {u.NAME}
                      </option>
                    );
                  }
                })}
              </optgroup>
            ) : null}
            {userPermissionExterno ? (
              <optgroup label="Ourives Externos">
                {usuarios.map(u => {
                  if (u.PERMISSION_CODE === 15) {
                    return (
                      <option
                        className="text-start"
                        value={u.ID + " " + u.PERMISSION_CODE}
                      >
                        {u.NAME}
                      </option>
                    );
                  }
                })}
              </optgroup>
            ) : null}
            {!userPermissionInterno &&
              !userPermissionExterno &&
              usuarios &&
              usuarios.map(u => (
                <option className="text-start" value={u.ID}>
                  {u.NAME}
                </option>
              ))}
          </Form.Select>
        </Card.Body>
      </Card>
      {selectedUser && (
        <ListaPedidosOurives
          operationType={operationTypeState}
          movimentationType={movimentationType}
          operationCode={operationCode}
          setStatusToFetch={setStatusToFetch}
          page={page}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
};
