import { Button } from "react-bootstrap";
import "./CarrinhoButton.css";

export const CarrinhoButton = ({ onClick, children }) => {
  return (
    <button
      className="button px-2 py-0"
      onClick={onClick}
      style={{ marginLeft: "25px", width: "60px", minWidth: "60px" }}
    >
      <span className="d-flex flex-column m-0">{children}</span>
    </button>
  );
};
