import { useEffect } from "react";
import { Card } from "react-bootstrap";

export const Resumo = ({ dados, sendDataToParent }) => {
  const Cores = dados.map((dado) => dado.MATERIAL_COLOR);
  const Metais = dados.map((dado) => dado.MATERIAL_CODE);
  const MetalUnico = Array.from(new Set(Metais));
  var Total = 0;

  for (let i = 0; i < dados.length; i++) {
    Total += dados[i].MATERIAL_WEIGHT;
  }
  // Obtendo valores únicos para Metal
  const Metal = MetalUnico.map((item, index) => {
    if (MetalUnico.length > 1 && index !== MetalUnico.length - 1) {
      return item;
    } else {
      return item;
    }
  });

  const handleSendData = () => {
    sendDataToParent({
      lote: lote,
      peso: Total.toFixed(3),
    });
  };

  // Obtendo valores únicos para Cor
  const CoresSplit = Cores.map((material) => material.split(" "));
  const coresUnicas = Array.from(
    new Set(CoresSplit.map((material) => material[0]))
  );

  const Cor = coresUnicas.map((item, index) => {
    if (coresUnicas.length > 1 && index !== coresUnicas.length - 1) {
      return item;
    } else {
      return item;
    }
  });

  const date = new Date();

  const year = date.getFullYear().toString().slice(-2);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const lote = `${dados.length}-${Metal.join("")}-${Cor.join(
    ""
  )}-0${year}-${month}-${day}-${hour}${minute}${second}`;

  useEffect(() => {
    handleSendData(lote);
  }, [lote]);

  return (
    <div className="d-flex flex-row w-100">
      <Card className="w-100 h-100">
        <Card.Body className="w-100 h-100 d-flex flex-row justify-content-between">
          <div className="w-100 h-100 ">
            <strong className="font-weight-bold">Quantidade: </strong>
            <span>{dados.length}</span>
          </div>
          <div className="w-100 h-100 ">
            <strong>Metais: </strong>
            <span>{Metal}</span>
          </div>
          <div className="w-100 h-100 ">
            <strong>Cores: </strong>
            <span>{Cor}</span>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
