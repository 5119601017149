import api from "../api";

export const StatusSevices = {
  list: (data) => {
    return api.post("/orderController.php", {
      action: "statusList",
      token: data.token,
      status_edit: data.status_edit
    });
  }
}