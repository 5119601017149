import { createContext, useState, useEffect } from "react";
import AccountServices from "../../apiServices/AccountServices/ApiServices";

const AccountContext = createContext();

const token = localStorage.getItem("token");

const AccountProvider = ({ children }) => {
	const [account, setAccount] = useState([]);
	const [list, setList] = useState([]);

	const [accountDetail, setAccountDetail] = useState([]);
	const [accountDebitCredit, setAccountDebitCredit] = useState([]);

	const accountList = async permissionType => {
		const payload = {
			token: token,
			permissionType: permissionType,
		};
		const responseAccountList = await AccountServices.accountList(payload);
		setList(responseAccountList.data.list);
	};

	const getAccount = async (id, permission) => {
		const payload = {
			id_user: id,
			permission: permission,
			token: token,
		};
		const response = await AccountServices.account(payload);
		setAccount(response.data.list[0]);
	};

	const getAccountDetail = async id => {
		const payload = {
			id_user: id,
			token: token,
		};
		const detailResponse = await AccountServices.accountDetail(payload);
		setAccountDetail(detailResponse.data.list);
	};

	const getAccountDebitCredit = async (id, permission_code) => {
		const payload = {
			id_user: id,
			token: token,
			permission_code: permission_code,
		};
		const debitCreditResponse = await AccountServices.accountDebitCredit(payload);
		setAccountDebitCredit(debitCreditResponse.data.list);
	};

	const accountBalance = async data => {
		const payload = {
			id_user: data.id_user,
			token: token,
			detail: data.detail,
			permission_code: data.permission_code,
		};
		return await AccountServices.accountBalance(payload);
	};

	const accountBalancePedding = async () => {
		const payload = {
			action: "account_balance_pedding",
			token: token,
		};

		const response = await AccountServices.accountBalancePedding(payload);
		setList(response.data.lista);
	};

	const accountBalanceList = async data => {
		const payload = {
			id_user: data.id_user,
			token: token,
		};
		const response = await AccountServices.accountBalanceList(payload);
	};

	const getExtract = async data => {
		const payload = {
			action: "extract_pdf",
			user: data.id,
			permission_code: data.permission_code,
			token: token,
		};
		return await AccountServices.getExtract(payload);
	};

	const addNailingValue = async data => {
		const payload = {
			action: "add_nailing_value",
			user: data.user,
			token: token,
			permission_id: data.permission_id,
			nailing: data.nailing,
		};

		return await AccountServices.addNailingValue(payload);
	};

	const getNailingExtract = async data => {
		const { user, start_date, end_date, permission_code } = data;
		const payload = {
			action: "extract_nailing_pdf",
			user,
			start_date,
			end_date,
			permission_code,
			token: token,
		};

		return await AccountServices.getNailingExtract(payload);
	};

	const concluiAccountPendding = async data => {
		const payload = {
			action: "conclui_account_pendding",
			balance_id: data.balance_id,
			observacoes: data.observacoes,
			token: token,
		};

		return await AccountServices.concluiAccountPendding(payload);
	};

	return (
		<AccountContext.Provider
			value={{
				list,
				account,
				accountDetail,
				accountDebitCredit,
				accountList,
				getAccount,
				getAccountDetail,
				getAccountDebitCredit,
				accountBalanceList,
				getExtract,
				accountBalance,
				addNailingValue,
				getNailingExtract,
				accountBalancePedding,
				concluiAccountPendding,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};

export { AccountContext, AccountProvider };
