import { createContext, useEffect, useState } from "react";

const ScreenContext = createContext();

const ScreenProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ScreenContext.Provider value={{ screenWidth, handleResize }}>
      {children}
    </ScreenContext.Provider>
  );
};

export { ScreenContext, ScreenProvider };
