import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dropdown, ListGroup, Modal, Nav, Table } from "react-bootstrap";
import ModalSolicitacao from "../../components/Solicitacao/ModalSolicitacao";
import { NailingContext } from "../../Context/NailingContext/Context";
import { ModalSolicitacaoDetalhes } from "../../components/Solicitacao/Detalhes/ModalDetalhes";
import AlteraStatus from "../../components/Solicitacao/AlteraStatusSolicitacao/ModalAlteraStatus";
import DynamicIcon from "../../components/DynamicIcon/DynamicIcon";
import { FaInbox } from "react-icons/fa6";
import { swalErro, swalSucesso } from "../../components/Swal";

export const Pendencias = () => {
	const [statusName, setStatusName] = useState("Aguardando");

	const { listaSolicitacaoCliente, solicitacoes, requestStatus, detalhesSolicitacao, ListRequestStatus, AtualizaSolicitacao } = useContext(NailingContext);
	useEffect(() => {
		listaSolicitacaoCliente({
			status_name: "Aguardando",
		});
		ListRequestStatus();
	}, []);

	const handleListaSolicitacoes = () => {
		const payload = {
			status_name: statusName,
		};
		listaSolicitacaoCliente(payload);
	};

	useEffect(() => {
		handleListaSolicitacoes();
	}, [statusName]);

	const [modalAttachmentShow, setModalAttachmentShow] = useState(false);
	const [modalAttachment, setModalAttachment] = useState({
		link: "",
		name: "",
	});

	const handleOpenModalAttachment = data => {
		setModalAttachment({
			link: data.FILE,
			name: data.part_code,
		});
		setModalAttachmentShow(true);
	};

	const handleInformaEnvio = async id => {
		const payload = {
			id_request: id,
			request_status: 5,
		};
		const response = await AtualizaSolicitacao(payload);
		if (response.data.status === "Success") {
			swalSucesso("O envio foi informado com sucesso!");
			handleListaSolicitacoes();
		} else {
			swalErro("Ocorreu um erro ao informar o envio! Por favor, tente novamente ou entre em contato com o nosso suporte!");
		}
	};

	return (
		<React.Fragment>
			<div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
				<Card className="card-one">
					<Card.Body>
						<div className="d-md-flex align-items-center justify-content-between">
							<div>
								<Card.Title as={"h4"}>Pendências de Insumos de Produção</Card.Title>
							</div>
						</div>
					</Card.Body>
				</Card>
				<div>
					<div className="p-0 d-flex flex-row gap-3">
						<Card className="w-100 card-one">
							<Card.Header>
								<Card.Title>Ultimas solicitações</Card.Title>
							</Card.Header>
							<Card.Body className="d-flex flex-row gap-2 justify-content-between">
								<Card className="w-15">
									<Card.Body>
										<Nav className="nav-sidebar">
											{requestStatus?.map((status, index) => (
												<Nav.Link
													active={statusName === status?.status_name}
													onClick={() => setStatusName(status?.status_name)}
													href=""
													className="d-flex flex-row gap-3"
												>
													<h5 className="font-xl">
														<DynamicIcon icon={status.icon} />
													</h5>{" "}
													<span>{status.status_name}</span>
												</Nav.Link>
											))}
										</Nav>
									</Card.Body>
								</Card>
								<div className="w-100 d-flex flex-column gap-1">
									<div className="px-1">
										<Card className="px-2">
											<Card.Body className="d-flex flex-row justify-content-between align-items-center px-5">
												<div
													className="text-start d-flex align-items-center justify-content-center"
													style={{
														width: "4rem",
													}}
												>
													<strong> #</strong>
												</div>

												<div
													className="text-start"
													style={{
														width: "9rem",
													}}
												>
													Data
												</div>

												<div
													className="text-start border-0 flex-row justify-content-start text-start"
													style={{
														paddingRight: "2.8rem",
													}}
												>
													<span className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-start">Status</span>
												</div>

												<div
													className="text-start border-0 flex-row justify-content-start text-start"
													style={{
														paddingRight: "2.8rem",
													}}
												>
													<span className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-start">Envio</span>
												</div>
											</Card.Body>
										</Card>
									</div>
									<div
										className="w-100 d-flex flex-column gap-2 px-1"
										style={{
											height: "40rem",
											overflowY: "scroll",
										}}
									>
										{solicitacoes?.map((s, i) => (
											<Card key={i}>
												<Card.Body className="d-flex flex-row justify-content-between align-items-center px-5">
													<div
														className="text-center d-flex align-items-center justify-content-center"
														style={{
															width: "4rem",
														}}
													>
														<ModalSolicitacaoDetalhes client={true} id={s.id}>
															<div className="product-thumb">{s.id}</div>
														</ModalSolicitacaoDetalhes>
													</div>

													<div
														className="text-end"
														style={{
															width: "9rem",
														}}
													>
														<span>
															{new Date(s.created_at).toLocaleDateString("pt-BR")} {new Date(s.created_at).toLocaleTimeString("pt-BR")}
														</span>
													</div>

													<div className="text-center border-0 flex-row justify-content-center">
														<span
															className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-center"
															style={{
																padding: "0.5rem",
																borderRadius: "5px",
																minWidth: "10rem",
																color: s.text_color,
																backgroundColor: s.status_color,
															}}
														>
															<DynamicIcon icon={s.status_icon} />
															<span>{s.status_name}</span>
														</span>
													</div>

													<div className="text-center border-0 flex-row justify-content-center">
														<span
															className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-center"
															style={{
																padding: "0.5rem",
																borderRadius: "5px",
																minWidth: "10rem",
															}}
														>
															{s.status_name == "Enviado" ? (
																<Button disabled>Envio informado (Aguardando Confirmação)</Button>
															) : s.status_name == "Recebido" ? (
																<Button
																	style={{
																		border: `none`,
																		backgroundColor: s.status_color,
																		color: s.text_color,
																	}}
																>
																	Recebido
																</Button>
															) : (
																<Button onClick={() => handleInformaEnvio(s.id)}>Informar envio</Button>
															)}
														</span>
													</div>
												</Card.Body>
											</Card>
										))}
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
			<Modal size="lg" show={modalAttachmentShow} onHide={() => setModalAttachmentShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{modalAttachment.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body className="d-flex justify-content-center">
							<img src={modalAttachment.link} height={"100%"}></img>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};
