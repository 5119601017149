import { createContext, useContext, useState } from "react";
import { TabelaMontagemServices } from "../../apiServices/TabelaMontagemServices/TabelaMontagemServices";

const TabelaMontagemContext = createContext();

export const TabelaMontagemProvider = ({ children }) => {
	const [listaTabelaMontagem, setListaTabelaMontageme] = useState([]);
	const token = localStorage.getItem("token");
	const listarTabelaMontagem = async () => {
		const response = await TabelaMontagemServices.Listar({ token: token });
		setListaTabelaMontageme(response.data.lista);
	};
	return (
		<TabelaMontagemContext.Provider
			value={{
				listarTabelaMontagem,
				listaTabelaMontagem,
			}}
		>
			{children}
		</TabelaMontagemContext.Provider>
	);
};
export const useTabelaMontagem = () => {
	return useContext(TabelaMontagemContext);
};
