import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useNota } from "../../../Context/NotaContext/NotaContext";

export const AlteraStatusNota = nota => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { alteraStatusNota } = useNota();

	const handleChangeStatus = async e => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const status = formData.get("status");
		const payload = {
			note_status: status,
			note_id: nota.nota.note_id,
		};

		const response = await alteraStatusNota(payload);
		if (response.data.status === "success") {
			handleClose();
		}
	};

	return (
		<>
			<Button onClick={handleOpen}>Alterar status</Button>
			<Modal backdrop="true" show={open} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Alterar Status da Nota</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleChangeStatus} className="d-flex flex-column gap-3">
						<Form.Label>Status:</Form.Label>
						<Form.Select name="status" required>
							<option value="">Selecione um status</option>
							<option value="enviado">Enviado</option>
							<option value="cancelado">Cancelado</option>
						</Form.Select>
						<Button type="submit">Alterar</Button>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};
