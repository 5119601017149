import { Search } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Dropdown, Form, ListGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { IoRemoveOutline } from "react-icons/io5";

import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { NailingContext } from "../../../../Context/NailingContext/Context";
import { AccountContext } from "../../../../Context/AccountContext/AccountContext";
import { Link } from "react-router-dom";
import { Dialog } from "../../../../components/DialogConfirmacao";
import { ScreenContext } from "../../../../Context/Screen/context";
import { HiOutlineEye } from "react-icons/hi2";
import { HiOutlineEyeSlash } from "react-icons/hi2";
import { apenasNumeros, apenasNumerosInteiros } from "../../../../functions/Mask";
import { useVisualization } from "../../../../Context/VisualizationContext/VisualizationContext";
import { useLoading } from "../../../../Context/Loading/LoadingContext";
import { dialog, swalErro, toastErro, toastInfo, toastSucesso } from "../../../../components/Swal";
import ReactDatePicker from "react-datepicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalCravador({ setter }) {
	const { account, getAccount, addNailingValue, accountList } = useContext(AccountContext);

	const [selectedTab, setSelectedTab] = useState(1);
	const handleChangeTab = tab => setSelectedTab(tab);

	const { getNailingExtract } = useContext(AccountContext);

	const today = new Date();
	const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

	const [startDate, setStartDate] = useState(firstDayOfMonth);
	const [endDate, setEndDate] = useState(new Date());
	useEffect(() => {
		console.log({
			startDate: startDate,
			endDate: endDate,
		});
	}, [startDate, endDate]);
	const { handleEndLoad } = useLoading();

	const { showValues, handleToggle } = useVisualization();
	const todaysYear = new Date().getFullYear();
	const todaysMonth = new Date().getMonth() + 1;
	const id = setter.id_user;
	const { screenWidth } = useContext(ScreenContext);
	const { orderNailing, getOrderNailing, totalValue, getTotalValue, NailingBreak, SaveEditingNailing, handleResetValuesFromNailing, saveConcluedOrCanceled } = useContext(NailingContext);

	const [editableOrderNailing, setEditableOrderNailing] = useState([]);

	useEffect(() => {
		setEditableOrderNailing(orderNailing);
	}, [orderNailing]);

	const handleInputChange = (e, index, field) => {
		var newValue = 0;
		const updatedOrderNailing = [...editableOrderNailing];
		if (field == "additional_value") {
			newValue = apenasNumeros(e.target.value);
		} else {
			newValue = apenasNumerosInteiros(e.target.value);
		}
		updatedOrderNailing[index][field] = newValue;
		setEditableOrderNailing(updatedOrderNailing);
	};

	const calculateTotalValue = nailing => {
		return (
			nailing?.value_grifas * nailing?.amount_grifas +
			nailing?.value_pave * nailing?.amount_pave +
			nailing?.value_central * nailing?.amount_central +
			nailing?.value_inglesa * nailing?.amount_inglesa +
			nailing?.value_diferencial * nailing?.amount_diferencial +
			Number(nailing?.additional_value)
		);
	};
	const [isFetchingEditingOrderNailing, setIsFetchingEditingOrderNailing] = useState({
		id_order: null,
		is_loading: false,
		id_order_nailing: null,
	});
	const [alteraDadosCravacao, setAlteraDadosCravacao] = useState(false);

	const handleSaveEditingOrderNailing = async nailing => {
		setIsFetchingEditingOrderNailing({
			is_loading: true,
			id_order: nailing.id_order,
			id_order_nailing: nailing.id_order_nailing,
		});
		const response = await SaveEditingNailing({ nailing });
		if (response.data.status == "success") {
			handleFetchAllData();
		}
		setAlteraDadosCravacao(false);
		setIsFetchingEditingOrderNailing({
			id_order: null,
			is_loading: false,
			id_order_nailing: null,
		});
	};

	const handleCloseModal = () => {
		handleResetValuesFromNailing();
		setOpenModalCravador(false);
		setStartDate(firstDayOfMonth);
		setEndDate(new Date());
		setAlteraDadosCravacao(false);
		setSelectedTab(1);
	};

	const somatorioValores =
		Number(totalValue[0]?.total_value_grifas || 0) +
		Number(totalValue[0]?.total_value_central || 0) +
		Number(totalValue[0]?.total_value_inglesa || 0) +
		Number(totalValue[0]?.total_value_diferencial || 0) +
		Number(totalValue[0]?.total_value_pave || 0) +
		Number(totalValue[0]?.additional_value || 0);

	const [modalAttachmentShow, setModalAttachmentShow] = useState(false);
	const [modalAttachment, setModalAttachment] = useState([]);
	const handleOpenModalAttachment = order => {
		setModalAttachment(order);
		setModalAttachmentShow(true);
	};

	const [modalPrestacaoShow, setModalPrestacaoShow] = useState(false);
	const [modalPrestacao, setModalPrestacao] = useState([]);
	const handleOpenModalPrestacao = () => {
		setModalPrestacaoShow(true);
	};

	const [dialogShow, setDialogShow] = useState(false);
	const [responseDialog, setResponseDialog] = useState(null);
	const [dadosEvento, setDadosEvento] = useState([]);

	const closeDialog = function () {
		setDialogShow(false);
	};

	const handleOpenDialog = e => {
		setDadosEvento(e);
		setDialogShow(true);
	};

	const handleSubmit = async () => {
		const payload = {
			user: id,
		};
		const response = await NailingBreak(payload);
		if (response.data.status === "success") {
			toast.dismiss();
			toast.success(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			setModalPrestacaoShow(false);
		} else {
			toast.dismiss();
			toast.error(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};

	useEffect(() => {
		if (responseDialog === true) {
			handleChangeValues();
			handleFetchAllData();
			closeDialog();
		}
	}, [responseDialog]);

	const handleChildData = e => {
		setResponseDialog(e);
	};

	const [openModalCravador, setOpenModalCravador] = useState(false);

	const [filterYear, setFilterYear] = useState(todaysYear);
	const [filterMonth, setFilterMonth] = useState(todaysMonth);
	const formatDate = date => {
		return date.toISOString().split("T")[0]; // Retorna apenas a parte da data (YYYY-MM-DD)
	};
	const handleGetExtract = async () => {
		const payload = {
			user: id,
			start_date: formatDate(startDate),
			end_date: formatDate(endDate),
			permission_code: setter.permission_type,
		};

		const response = await getNailingExtract(payload);
		if (response.data.link !== "") {
			var link = response.data.link;
			var anchor = document.createElement("a");
			anchor.style.display = "none";
			document.body.appendChild(anchor);

			// Configure o atributo href e download
			anchor.href = link;
			anchor.setAttribute("target", "_blank");
			toastSucesso("Documento gerado com sucesso!");
			// Dispare um clique simulado no elemento de âncora
			setTimeout(() => {
				anchor.click();
			}, 1000);

			// Remova o elemento de âncora
			document.body.removeChild(anchor);
		}
	};
	const handleFetchAllData = async () => {
		handleResetValuesFromNailing();
		getAccount(id, setter.permission_type);
		getOrderNailing({
			id: id,
			start_date: formatDate(startDate),
			end_date: formatDate(endDate),
			permission_id: setter.permission_type,
		});
		getTotalValue({
			id,
			start_date: formatDate(startDate),
			end_date: formatDate(endDate),
			permission_id: setter.permission_type,
		});

		setTimeout(() => {
			handleEndLoad();
		}, 1000);
	};

	useEffect(() => {
		handleFetchAllData();
	}, [startDate, endDate]);

	const handleOpenModalCravador = () => {
		setOpenModalCravador(true);
		handleFetchAllData();
	};

	const [modalAlterarShow, setModalAlterarShow] = useState(false);
	const [modalAlterarData, setModalAlterarData] = useState([]);
	const [nailingValue, setNailingValue] = useState([]);

	const handleOpenModalAlterar = data => {
		setModalAlterarShow(true);
		setModalAlterarData(data);
		if (account?.nailing_value.length > 0) {
			setNailingValue(account?.nailing_value);
		}
	};

	const changeNailingValue = (nailing, event) => {
		const index = nailingValue.findIndex(n => n.id_nailing_value === nailing);
		if (index !== -1) {
			const newArray = [...nailingValue];
			newArray[index] = {
				...newArray[index],
				nailing_value: event?.replace(",", "."),
			};
			setNailingValue(newArray);
		}
	};
	const [handleDataEventChangeValues, setHandleDataEventChangeValues] = useState(null);

	const handleSubmitAlteraValoresCravadores = async e => {
		e.preventDefault();
		handleOpenDialog();
		setHandleDataEventChangeValues(e);
		toast.loading("Salvando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
	};

	const handleChangeValues = async () => {
		const payload = {
			user: id,
			permission_id: setter.permission_type,
			nailing: nailingValue,
		};

		const response = await addNailingValue(payload);

		if (response.data.status == "success") {
			toast.dismiss();
			toast.success(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
			handleFetchAllData();
			accountList([17, 18]);
			setModalAlterarShow(false);
		} else {
			toast.dismiss();
			toast.error(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
		}
	};

	const monthList = [
		{
			id: 1,
			name: "Janeiro",
		},
		{
			id: 2,
			name: "Fevereiro",
		},
		{
			id: 3,
			name: "Março",
		},
		{
			id: 4,
			name: "Abril",
		},
		{
			id: 5,
			name: "Maio",
		},
		{
			id: 6,
			name: "Junho",
		},
		{
			id: 7,
			name: "Julho",
		},
		{
			id: 8,
			name: "Agosto",
		},
		{
			id: 9,
			name: "Setembro",
		},
		{
			id: 10,
			name: "Outubro",
		},
		{
			id: 11,
			name: "Novembro",
		},
		{
			id: 12,
			name: "Dezembro",
		},
	];

	const handleFilterWithDate = async e => {
		e.preventDefault();

		getOrderNailing({
			id: id,
			month: e.target.month.value,
			year: e.target.year.value,
			permission_id: setter.permission_type,
		});

		getAccount(id, setter.permission_type);

		getTotalValue({
			id: id,
			month: e.target.month.value,
			year: e.target.year.value,
			permission_id: setter.permission_type,
		});
	};

	const MySwal = withReactContent(Swal);

	const handleMarkAsConcluedOrCanceled = async (nailing, id_status_services) => {
		const confirmation = await Swal.fire({
			title: "Confirmação",
			text: `Você deseja marcar o pedido ${nailing.order_code} como concluído?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		});

		if (confirmation.isConfirmed) {
			const payload = {
				id_order_nailing: nailing.id_order_nailing,
				id_order: nailing.id_order,
				conclued_detail: "Conclued",
				id_status_services: id_status_services,
				nailing: nailing,
			};

			const response = await saveConcluedOrCanceled(payload);
			if (response.data.status == "success") {
				handleFetchAllData();
			}
		} else {
			toastErro("Operação cancelada!");
		}
	};

	const [modalCancelNailing, setModalCancelNailing] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const handleCancelNailing = order => {
		setSelectedOrder(order);
		setModalCancelNailing(true);
		setOpenModalCravador(false);
	};

	const [cancelDetail, setCancelDetail] = useState(null);
	const handleSubmitCancelNailing = async e => {
		e.preventDefault();
		const confirmation = await dialog("Você realmente deseja cancelar a cravaçãod este pedido para este cravador?");
		if (confirmation.isConfirmed) {
			const payload = {
				id_order_nailing: selectedOrder.id_order_nailing,
				id_order: selectedOrder.id_order,
				completed_detail: cancelDetail,
				id_status_services: 3,
			};

			const response = await saveConcluedOrCanceled(payload);
			if (response.data.status == "success") {
				handleFetchAllData();
				setModalCancelNailing(false);
			}
		} else {
			toastErro("Operação cancelada!");
		}
	};

	const [selectedNailing, setSelectedNailing] = useState(null);
	const [openModalDetailNailing, setOpenModalDetailNailing] = useState(false);
	const handleOpenModalDetailNailing = nailing => {
		setSelectedNailing(nailing);
		setOpenModalCravador(false);
		setOpenModalDetailNailing(true);
	};

	useEffect(
		function () {
			console.log(selectedNailing);
		},
		[selectedNailing]
	);

	const [sumValuesFromSelectedNailing, setValuesFromSelectedNailing] = useState(0);

	useEffect(() => {
		setValuesFromSelectedNailing(
			Number(totalValue[0]?.total_value_grifas || 0) +
				Number(totalValue[0]?.total_value_central || 0) +
				Number(totalValue[0]?.total_value_inglesa || 0) +
				Number(totalValue[0]?.total_value_diferencial || 0) +
				Number(totalValue[0]?.total_value_pave || 0) +
				Number(totalValue[0]?.additional_value || 0)
		);
	}, [selectedNailing]);

	const handleCloseModalDetailNailing = () => {
		setOpenModalDetailNailing(false);
		setOpenModalCravador(true);
		setSelectedNailing(null);
	};

	const [dialogAlteraCravacoesShow, setDialogShowAlteraCravacoes] = useState(false);
	const handleOpenDialogAlteraValores = () => {
		setDialogShowAlteraCravacoes(true);
		setOpenModalCravador(false);
	};

	const closeDialogAlteraCravacoes = () => {
		setDialogShowAlteraCravacoes(false);
		setOpenModalCravador(true);
	};

	const handleChildDataAlteraCravacoes = e => {
		if (e) {
			closeDialogAlteraCravacoes();
			setAlteraDadosCravacao(true);
		}
	};

	const renderTooltip = props => (
		<Tooltip id="button-tooltip" {...props}>
			{props.text}
		</Tooltip>
	);

	return (
		<React.Fragment>
			<ToastContainer />
			<Link onClick={() => handleOpenModalCravador()}>
				<h5 className="p-0 m-0">{setter.user_name}</h5>
			</Link>
			<Modal fullscreen show={openModalCravador} onHide={handleCloseModal}>
				<Modal.Body>
					<div className="d-flex flex-row gap-3 justify-content-between">
						<Card className="card-one w-100">
							<Card.Body className={`d-flex ${screenWidth > 1200 ? "flex-row" : "flex-column"} justify-content-between`}>
								<div className="d-flex flex-row align-items-center">
									<h4 className="main-title mb-0">{screenWidth < 1100 ? account.user_name?.split(" ")[0] : account.user_name}</h4>
								</div>
								<div className="d-flex flex-row gap-4">
									<div className="d-flex flex-column justify-content-end">
										<div className="p-0 primary" onClick={handleToggle}>
											<h3 className="p-0">{showValues ? <HiOutlineEye /> : <HiOutlineEyeSlash />}</h3>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>

						<Card className="card-one">
							<Card.Body>
								<div className="d-flex flex-column gap-3">
									<Form className="d-flex flex-row gap-3">
										<Form.Group>
											<Form.Label htmlFor="startDatePicker">
												<strong>Data de Início</strong>
											</Form.Label>
											<ReactDatePicker
												id="startDatePicker"
												onChange={date => setStartDate(date)}
												className="form-control"
												selected={startDate}
												dateFormat="dd/MM/yyyy"
											/>
										</Form.Group>

										<Form.Group>
											<Form.Label htmlFor="endDatePicker">
												<strong>Data de Fim</strong>
											</Form.Label>
											<ReactDatePicker id="endDatePicker" onChange={date => setEndDate(date)} className="form-control" selected={endDate} dateFormat="dd/MM/yyyy" />
										</Form.Group>
									</Form>
									<Button
										onClick={() => {
											setStartDate(firstDayOfMonth);
											setEndDate(new Date());
										}}
									>
										Redefinir data
									</Button>
								</div>
							</Card.Body>
						</Card>
					</div>
					<div className="d-flex flex-row gap-3">
						<Card
							className="mt-3 w-15"
							style={{
								maxHeight: "130px",
							}}
						>
							<Card.Body className="d-flex flex-column gap-3">
								<Button
									variant={selectedTab == "1" ? "primary" : "outline-primary"}
									style={{
										minWidth: "150px",
									}}
									onClick={() => handleChangeTab(1)}
								>
									Pendentes
								</Button>
								<Button
									variant={selectedTab == "2" ? "primary" : "outline-primary"}
									style={{
										minWidth: "150px",
									}}
									onClick={() => handleChangeTab(2)}
								>
									Contabilizados
								</Button>
							</Card.Body>
						</Card>
						{selectedTab == 1 ? (
							<Card className="card-one border mt-3 w-100">
								<Card.Header className="w-100">
									<div className="p-2 d-flex w-100 flex-row justify-content-between">
										<div className="border form-search w-20">
											<Form className="d-flex w-100">
												<Form.Control
													className="w-100"
													placeholder="Pesquisar"
													onChange={e => console.log(e.target.value)}
													id="pesquisar"
													name="pesquisar"
												></Form.Control>
												<Button type="submit" variant="outline">
													<Search />
												</Button>
											</Form>
										</div>
									</div>
								</Card.Header>
								<Card.Body className="mb-0 d-flex flex-column gap-3">
									{/* <Card className="mb-0">
                  <Card.Body
                    className={`mb-0 d-flex gap-3 ${
                      screenWidth < 1100 ? "flex-column" : "flex-row"
                    } justify-content-between`}
                  >
                    <div
                      className={`d-flex ${
                        screenWidth < 1100 ? "flex-row" : "flex-column"
                      } gap-2`}
                    >
                      <span className="span-inter">Total Pave:</span>
                      <span className="span-inter2">
                        {totalValue[0]?.total_amount_pave || 0}
                      </span>
                    </div>
                    <div
                      className={`d-flex ${
                        screenWidth < 1100 ? "flex-row" : "flex-column"
                      } gap-2`}
                    >
                      <span className="span-inter">Total Grifas:</span>
                      <span className="span-inter2">
                        {totalValue[0]?.total_amount_grifas || 0}
                      </span>
                    </div>
                    <div
                      className={`d-flex ${
                        screenWidth < 1100 ? "flex-row" : "flex-column"
                      } gap-2`}
                    >
                      <span className="span-inter">Total Diferencial:</span>
                      <span className="span-inter2">
                        {totalValue[0]?.total_amount_diferencial || 0}
                      </span>
                    </div>
                    <div
                      className={`d-flex ${
                        screenWidth < 1100 ? "flex-row" : "flex-column"
                      } gap-2`}
                    >
                      <span className="span-inter">Total Central:</span>
                      <span className="span-inter2">
                        {totalValue[0]?.total_amount_central || 0}
                      </span>
                    </div>
                    <div
                      className={`d-flex ${
                        screenWidth < 1100 ? "flex-row" : "flex-column"
                      } gap-2`}
                    >
                      <span className="span-inter">Total Inglesa:</span>
                      <span className="span-inter2">
                        {totalValue[0]?.total_amount_inglesa || 0}
                      </span>
                    </div>
                    <div>
                      <Button onClick={handleOpenModalPrestacao}>
                        Prestação de Contas
                      </Button>
                    </div>
                  </Card.Body>
                </Card> */}

									<Row className="mt-0">
										<Col xs={12} sm={12} md={12} xxl={12}>
											<Card style={{ maxHeight: "500px" }} className="border-0 overflow-auto">
												<Card.Body className="p-0">
													<Table bordered hover>
														<thead>
															<tr>
																<th className="text-center" scope="col">
																	Imagem
																</th>
																<th className="text-center" scope="col">
																	Pedido
																</th>
																<th className="text-center" scope="col">
																	Pave
																</th>
																<th className="text-center" scope="col">
																	Grifas
																</th>
																<th className="text-center" scope="col">
																	Grifas Maior
																</th>
																<th className="text-center" scope="col">
																	Central
																</th>
																<th className="text-center" scope="col">
																	Inglesa
																</th>
																<th className="text-center" scope="col">
																	Diferencial
																</th>
																<th className="text-center" scope="col">
																	Adicional
																</th>
																{showValues ? (
																	<th className="text-center" scope="col">
																		Valor
																	</th>
																) : null}

																<th>Opções</th>
															</tr>
														</thead>
														<tbody>
															{editableOrderNailing?.map((nailing, i) => {
																if (nailing.id_status_services == "1") {
																	return (
																		<tr key={i} scope="row">
																			<td
																				scope="col"
																				className="text-center"
																				onClick={() => handleOpenModalAttachment(nailing)}
																				style={{ cursor: "pointer" }}
																			>
																				<img src={nailing.FILE} height={30} alt="Imagem do Pedido"></img>
																			</td>
																			<td scope="col" className="text-center" style={{ minWidth: "120px" }}>
																				{nailing.order_code}
																			</td>
																			{[
																				"amount_pave",
																				"amount_grifas",
																				"amount_grifas_maior",
																				"amount_central",
																				"amount_inglesa",
																				"amount_diferencial",
																				"additional_value",
																			].map(field => (
																				<td key={field} scope="col" valign="center" className="text-center">
																					<Form.Control
																						name={field}
																						className="custom-form-sheet text-center h-100 my-auto"
																						style={{
																							maxWidth: "100%",
																							height: "100%",
																						}}
																						value={nailing[field]}
																						onChange={e => handleInputChange(e, i, field)}
																					/>
																				</td>
																			))}
																			{showValues ? (
																				<td scope="col" className="text-end">
																					{new Intl.NumberFormat("pt-BR", {
																						style: "currency",
																						currency: "BRL",
																					}).format(calculateTotalValue(nailing))}
																				</td>
																			) : null}

																			<td scope="col" className="text-center">
																				<div className="d-flex flex-row justify-content-center w-100">
																					{/* <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip">
                                              Salvar alterações
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="mx-1 btn-icon"
                                            disabled={
                                              (isFetchingEditingOrderNailing.id_order =
                                                nailing.id_order &&
                                                isFetchingEditingOrderNailing.is_loading)
                                            }
                                            onClick={() => {
                                              return handleSaveEditingOrderNailing(
                                                nailing
                                              );
                                            }}
                                          >
                                            {
                                              (isFetchingEditingOrderNailing.id_order =
                                                nailing.id_order &&
                                                isFetchingEditingOrderNailing.is_loading ==
                                                  true ? (
                                                  <>
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                  </>
                                                ) : (
                                                  <i class="ri-save-line"></i>
                                                ))
                                            }
                                          </Button>
                                        </OverlayTrigger> */}
																					<OverlayTrigger
																						placement="top"
																						overlay={<Tooltip id="button-tooltip">Concluir Cravação</Tooltip>}
																					>
																						<Button
																							onClick={e => handleMarkAsConcluedOrCanceled(nailing, 2)}
																							className="mx-1 btn-icon"
																						>
																							<i className="ri-check-double-line"></i>
																						</Button>
																					</OverlayTrigger>
																					<OverlayTrigger
																						placement="top"
																						overlay={<Tooltip id="button-tooltip">Cancelar Cravação</Tooltip>}
																					>
																						<Button
																							onClick={e => handleCancelNailing(nailing)}
																							variant="danger"
																							className="mx-1 btn-icon"
																						>
																							<i class="ri-delete-bin-line"></i>
																						</Button>
																					</OverlayTrigger>
																				</div>
																			</td>
																		</tr>
																	);
																}
															})}
														</tbody>
													</Table>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						) : null}

						{selectedTab == 2 ? (
							<Card className="card-one border mt-3 w-100">
								<Card.Header className="w-100">
									<div className="p-2 d-flex w-100 flex-row justify-content-between">
										<div className="border form-search w-20">
											<Form className="d-flex w-100">
												<Form.Control
													className="w-100"
													placeholder="Pesquisar"
													onChange={e => console.log(e.target.value)}
													id="pesquisar"
													name="pesquisar"
												></Form.Control>
												<Button type="submit" variant="outline">
													<Search />
												</Button>
											</Form>
										</div>
										<div className="d-flex flex-row gap-3">
											<Button
												style={{
													minWidth: "180px",
												}}
												className=""
												onClick={handleGetExtract}
											>
												Extrato
											</Button>
											<Button
												style={{
													minWidth: "180px",
												}}
												onClick={handleOpenDialogAlteraValores}
											>
												Alterar Cravações
											</Button>
										</div>
									</div>
								</Card.Header>
								<Card.Body className="mb-0 d-flex flex-column gap-3">
									<Card className="mb-0">
										<Card.Body className={`mb-0 d-flex gap-3 ${screenWidth < 1100 ? "flex-column" : "flex-row"} justify-content-between`}>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Pave:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_pave || 0}</span>
											</div>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Grifas:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_grifas || 0}</span>
											</div>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Grifas_maior:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_grifas_maior || 0}</span>
											</div>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Diferencial:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_diferencial || 0}</span>
											</div>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Central:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_central || 0}</span>
											</div>
											<div className={`d-flex ${screenWidth < 1100 ? "flex-row" : "flex-column"} gap-2`}>
												<span className="span-inter">Total Inglesa:</span>
												<span className="span-inter2">{totalValue[0]?.total_amount_inglesa || 0}</span>
											</div>
											{/* <div>
                    <Button onClick={handleOpenModalPrestacao}>
                      Prestação de Contas
                    </Button>
                  </div> */}
										</Card.Body>
									</Card>

									<Row className="mt-0">
										<Col xs={12} sm={12} md={12} xxl={10}>
											<Card style={{ maxHeight: "500px" }} className="border-0 overflow-auto">
												<Card.Body className="p-0">
													<Table bordered hover>
														<thead>
															<tr>
																<th className="text-center" scope="col">
																	Imagem
																</th>
																<th className="text-center" scope="col">
																	Pedido
																</th>
																<th className="text-center" scope="col">
																	Pave
																</th>
																<th className="text-center" scope="col">
																	Grifas
																</th>
																<th className="text-center" scope="col">
																	Grifas Maior
																</th>
																<th className="text-center" scope="col">
																	Central
																</th>
																<th className="text-center" scope="col">
																	Inglesa
																</th>
																<th className="text-center" scope="col">
																	Diferencial
																</th>
																<th className="text-center" scope="col">
																	Adicional
																</th>
																{showValues ? (
																	<th className="text-center" scope="col">
																		Valor
																	</th>
																) : null}

																{alteraDadosCravacao ? <th>Salvar</th> : null}
															</tr>
														</thead>
														<tbody>
															{editableOrderNailing?.map((nailing, i) => {
																if (nailing.id_status_services == "2") {
																	return (
																		<tr key={i} scope="row">
																			<td
																				scope="col"
																				className="text-center"
																				onClick={() => handleOpenModalAttachment(nailing)}
																				style={{ cursor: "pointer" }}
																			>
																				<img src={nailing.FILE} height={30} alt="Imagem do Pedido"></img>
																			</td>
																			<td scope="col" className="text-center" style={{ minWidth: "120px" }}>
																				<Link onClick={() => handleOpenModalDetailNailing(nailing)} as={Button}>
																					{nailing.order_code}
																				</Link>
																			</td>
																			{[
																				"amount_pave",
																				"amount_grifas",
																				"amount_grifas_maior",
																				"amount_central",
																				"amount_inglesa",
																				"amount_diferencial",
																				"additional_value",
																			].map(field => (
																				<td key={field} scope="col" valign="center" className="text-center">
																					<Form.Control
																						name={field}
																						className="custom-form-sheet text-center h-100 my-auto"
																						disabled={!alteraDadosCravacao}
																						style={{
																							maxWidth: "100%",
																							height: "100%",
																						}}
																						value={nailing[field]}
																						onChange={e => handleInputChange(e, i, field)}
																					/>
																				</td>
																			))}
																			{showValues ? (
																				<td scope="col" className="text-end">
																					{new Intl.NumberFormat("pt-BR", {
																						style: "currency",
																						currency: "BRL",
																					}).format(calculateTotalValue(nailing))}
																				</td>
																			) : null}
																			{alteraDadosCravacao ? (
																				<td scope="col" className="text-center">
																					<Button
																						disabled={
																							(isFetchingEditingOrderNailing.id_order =
																								nailing.id_order && isFetchingEditingOrderNailing.is_loading)
																						}
																						onClick={() => {
																							return handleSaveEditingOrderNailing(nailing);
																						}}
																					>
																						{
																							(isFetchingEditingOrderNailing.id_order =
																								nailing.id_order && isFetchingEditingOrderNailing.is_loading == true ? (
																									<>
																										<span className="spinner-border spinner-border-sm"></span>
																									</>
																								) : (
																									"Salvar"
																								))
																						}
																					</Button>
																				</td>
																			) : null}
																		</tr>
																	);
																}
															})}
														</tbody>
													</Table>
												</Card.Body>
											</Card>
										</Col>

										<Col xs={12} sm={12} md={12} xxl={2} className="d-flex flex-column gap-3">
											<Card className="card-one">
												<Card.Header>
													<Card.Title>Relatório</Card.Title>
												</Card.Header>
												<Card.Body className="p-1">
													<ListGroup className="border-0">
														<ListGroup.Item as={"li"} className="d-flex flex-row justify-content-between w-100">
															<span>Total de Pedidos Cravados: </span>
															<strong>{orderNailing?.length}</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100 align-items-center">
															<span>
																<strong>Central</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.total_value_central) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100">
															<span>
																<strong>Diferencial</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.total_value_diferencial) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100">
															<span>
																<strong>Grifas</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.total_value_grifas) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100">
															<span>
																<strong>Inglesas</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.total_value_inglesa) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100">
															<span>
																<strong>Pave</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.total_value_pave) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>

														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100">
															<span>
																<strong>Valores adicionais</strong>:{" "}
															</span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(totalValue[0]?.additional_value) || 0)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</ListGroup.Item>
														<ListGroup.Item as={"li"} className="border-top  d-flex flex-row justify-content-between w-100"></ListGroup.Item>
													</ListGroup>
													<div>
														<Card.Body className="d-flex flex-row justify-content-between w-100">
															<span>Valor total cravado: </span>
															<strong>
																{showValues ? (
																	new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(somatorioValores)
																) : (
																	<IoRemoveOutline />
																)}
															</strong>
														</Card.Body>
													</div>
												</Card.Body>
											</Card>

											<Card className="">
												<Card.Header>
													<Card.Title>Valores</Card.Title>
												</Card.Header>
												<Card.Body className="p-1">
													<ListGroup className="border-0">
														{account?.nailing_value?.map((v, i) => (
															<ListGroup.Item key={i} as={"li"} className="d-flex flex-row justify-content-between w-100">
																<strong>{v?.nailing_type?.charAt(0).toUpperCase() + v?.nailing_type?.toLowerCase().slice(1)}</strong>

																{new Intl.NumberFormat("pt-BR", {
																	style: "currency",
																	currency: "BRL",
																}).format(Number(v.nailing_value))}
															</ListGroup.Item>
														))}

														<Button onClick={handleOpenModalAlterar} className="rounded mt-3">
															Alterar Valores
														</Button>
													</ListGroup>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						) : null}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal size="xl" show={modalAttachmentShow} onHide={() => setModalAttachmentShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Anexo</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex aligm-items-center justify-content-center">
					<img src={modalAttachment.FILE} height={600}></img>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setModalAttachmentShow(false)}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal size="xl" show={modalPrestacaoShow} onHide={() => setModalPrestacaoShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{account.user_name}</Modal.Title>
					<Form.Control></Form.Control>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column gap-3">
					<Card style={{ maxHeight: "500px" }} className="overflow-auto">
						<Card.Body>
							<Table bordered hover className="">
								<thead>
									<tr>
										<td scope="col">Imagem</td>
										<td scope="col">Código do pedido</td>
										<td scope="col">Pave</td>
										<td scope="col">Grifas</td>
										<td scope="col">Central</td>
										<td scope="col">Inglesa</td>
										<td scope="col">Diferencial</td>
										<td scope="col" className="border-left">
											Valor
										</td>
									</tr>
								</thead>
								<tbody>
									{orderNailing?.map((o, i) => (
										<tr key={i} scope="row">
											<td scope="col" onClick={() => handleOpenModalAttachment(o)} style={{ cursor: "pointer" }}>
												<img src={o.FILE} height={30}></img>
											</td>
											<td scope="col">{o.order_code}</td>
											<td scope="col">{o.amount_pave}</td>
											<td scope="col">{o.amount_grifas}</td>
											<td scope="col">{o.amount_central}</td>
											<td scope="col">{o.amount_inglesa}</td>
											<td scope="col">{o.amount_diferencial}</td>
											<td scope="col" className="text-end">
												{new Intl.NumberFormat("pt-BR", {
													style: "currency",
													currency: "BRL",
												}).format(Number(o.grifas) + Number(o.pave) + Number(o.central) + Number(o.inglesa) + Number(o.diferencial))}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body className="d-flex flex-row justify-content-between w-100">
							<h5>Valor total cravado no Mês: </h5>
							<h5 className="">
								{new Intl.NumberFormat("pt-BR", {
									style: "currency",
									currency: "BRL",
								}).format(somatorioValores)}
							</h5>
						</Card.Body>
					</Card>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setModalPrestacaoShow(false)} variant="secondary">
						Cancelar
					</Button>
					<Button onClick={() => handleOpenDialog()} type="submit" disabled={!orderNailing.length > 0} variant="primary">
						Salvar
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal size="xl" show={modalAlterarShow} onHide={() => setModalAlterarShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Alterar Valores de Cravação</Modal.Title>
				</Modal.Header>
				<form onSubmit={handleSubmitAlteraValoresCravadores}>
					<Modal.Body>
						<Card className="card-one">
							<Card.Body>
								<Card.Title> {modalAlterarData?.user_name}</Card.Title>
								<hr></hr>
								<div className="d-flex flex-row gap-3 justify-content-between">
									{nailingValue?.map((v, i) => (
										<Form.Group>
											<Form.Label>
												<strong>{v.nailing_type.charAt(0) + v.nailing_type.toLowerCase().substring(1)}</strong>
											</Form.Label>
											<Form.Control onChange={e => changeNailingValue(v.id_nailing_value, e.target.value)} value={v.nailing_value}></Form.Control>
										</Form.Group>
									))}
								</div>
							</Card.Body>
						</Card>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setModalAlterarShow(false)} variant="secondary">
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Salvar
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
			<Modal
				size="xl"
				show={modalCancelNailing}
				onHide={() => {
					setModalCancelNailing(false);
					setOpenModalCravador(true);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Cancelamento de Cravação</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitCancelNailing}>
					<Modal.Body>
						<Form.Control onChange={e => setCancelDetail(e.target.value)} placeholder="Digite suas observações aqui..." as={"textarea"}></Form.Control>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setModalCancelNailing(false);
								setOpenModalCravador(true);
							}}
							variant="secondary"
						>
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Confirmar
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal size="xl" show={openModalDetailNailing} onHide={handleCloseModalDetailNailing}>
				<Modal.Header closeButton>
					<Modal.Title>{selectedNailing?.order_code}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className="card-one shadow-none rounded-lg">
						<Card.Body className="d-flex flex-row gap-3 justify-content-between">
							<Card className="card-one w-100 shadow-none">
								<Card.Header>
									<Card.Title>Imagem da peça</Card.Title>
								</Card.Header>
								<Card.Body>
									<img src={selectedNailing?.FILE} alt="Imagem de Cravação"></img>
								</Card.Body>
							</Card>

							<Card className="card-one w-100 shadow-none">
								<Card.Header>
									<Card.Title>Quantidade de Cravação</Card.Title>
								</Card.Header>
								<Card.Body>
									<div>
										<strong>Pave: </strong>
										<span>{selectedNailing?.amount_pave}</span>
									</div>
									<div>
										<strong>Grifas: </strong>
										<span>{selectedNailing?.amount_grifas}</span>
									</div>
									<div>
										<strong>Central: </strong>
										<span>{selectedNailing?.amount_central}</span>
									</div>
									<div>
										<strong>Inglesa: </strong>
										<span>{selectedNailing?.amount_inglesa}</span>
									</div>
									<div>
										<strong>Diferencial: </strong>
										<span>{selectedNailing?.amount_diferencial}</span>
									</div>
								</Card.Body>
							</Card>

							<Card className="card-one w-100 shadow-none">
								<Card.Header>
									<Card.Title>Valores de Cravação</Card.Title>
								</Card.Header>
								<Card.Body>
									<div>
										<strong>Pave: </strong>
										<span>{Number(selectedNailing?.value_pave).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</span>
									</div>
									<div>
										<strong>Grifas: </strong>
										<span>{Number(selectedNailing?.value_grifas).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</span>
									</div>
									<div>
										<strong>Central: </strong>
										<span>{Number(selectedNailing?.value_central).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</span>
									</div>
									<div>
										<strong>Inglesa: </strong>
										<span>{Number(selectedNailing?.value_inglesa).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</span>
									</div>
									<div>
										<strong>Diferencial: </strong>
										<span>
											{Number(selectedNailing?.value_diferencial).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})}
										</span>
									</div>
								</Card.Body>
							</Card>
						</Card.Body>
					</Card>
					<Card className="mt-3 card-one shadow-none">
						<Card.Body className="d-flex flex-column justify-content-end">
							<div className="w-100 d-flex flex-row justify-content-end">
								<span>Total</span>
							</div>
							<hr />
							<div className="w-100 d-flex flex-row justify-content-end">
								<span>
									{new Intl.NumberFormat("pt-BR", {
										style: "currency",
										currency: "BRL",
									}).format(calculateTotalValue(selectedNailing))}
								</span>
							</div>
						</Card.Body>
					</Card>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleCloseModalDetailNailing}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
			<Dialog
				showDialog={dialogShow}
				closeDialog={closeDialog}
				title={`${account.user_name}`}
				question={`Digite a sua matrícula para confirmar`}
				sendDataToParent={handleChildData}
				user={account.id_user}
			></Dialog>

			<Dialog
				showDialog={dialogAlteraCravacoesShow}
				closeDialog={closeDialogAlteraCravacoes}
				title={`Deseja realmente alterar os valores de cravação?`}
				question={`Digite a sua matrícula para confirmar`}
				sendDataToParent={handleChildDataAlteraCravacoes}
				user={account.id_user}
			></Dialog>
		</React.Fragment>
	);
}
