import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Alert, Badge, Button, Card, Col, Dropdown, Form, Modal, ModalTitle, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../components/Avatar";

import img11 from "../assets/img/img11.jpg";

import { userServices } from "../apiServices/userServices";
import { Info, MailQuestion, Search, Trash2 } from "lucide-react";
import { cepServices } from "../apiServices/addressServices";
import { ToastContainer, toast } from "react-toastify";
import { productServices } from "../apiServices/productServices";


export default function Contacts() {
  const token = localStorage.getItem("token")
  const [groups, setGroups] = useState([]);
  const [activeUser, setActiveUser] = useState([])
  const [editPermission, setEditPermission] = useState(false);
  const [modalCadastroShow, setModalCadastroShow] = useState(false)
  const [modalPassShow, setModalPassShow] = useState(false)
  const [addressField, setAdressField] = useState("");

  const [material, setMaterial] = useState([]);

  const [typePermissions, setTypePermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [definirPerventualModalShow, setDefinirPercentualModalShow] = useState(false);
  const [definirPermissoesModalShow, setDefinirPermissoesModalShow] = useState(false);

  const [atribuirMetalShow, setAtribuirMetalShow] = useState(false);

  const [endereco, setEndereco] = useState({
    cep: "",
    rua: "",
    bairro: "",
    cidade: "",
    estado: "",
  });

  const [searchUser, setSearchUser] = useState('');

  const handleEditPermission = (token) => {
    setEditPermission(true)
  }

  const handleDefinirPercentual = (e) => {
    setDefinirPercentualModalShow(true);
  }

  const handleSavePermission = async (e) => {
    toast.loading("Adicionando permissão...", {
      position: toast.POSITION.BOTTOM_CENTER
    })
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      permission: formData.get("permission"),
      user: activeUser.USERID,
      token: token
    }

    const response = await userServices.setUserPermission(data);
    if (response.data.status === "success") {
      toast.dismiss()
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
      handleGetUserPermissions()
    } else if (response.data.status === "info") {
      toast.dismiss()
      toast.info(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {
      toast.dismiss()
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }

  const handlePermissionRemove = async (id) => {
    toast.loading("Removendo...", {
      position: toast.POSITION.BOTTOM_CENTER
    })
    const data = {
      id: id,
      token: token
    }
    const response = await userServices.removeUserPermission(data);
    if (response.data.status === "success") {
      toast.dismiss()
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
      handleGetUserPermissions()
    } else {
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }

  const handleGetUserPermissions = async () => {
    const getUserPermissions = await userServices.getUserPermission(activeUser.USERID);
    setUserPermissions(getUserPermissions.data)
  }

  const handleDefinirPermissoes = async (e) => {
    setDefinirPermissoesModalShow(true);
    const response = await userServices.getUserTypePermissions();
    handleGetUserPermissions()
    setTypePermissions(response.data)
  }

  const handleMaterial = async (id) => {
    const responseMaterial = await productServices.selectListMaterial(id, token);

    setMaterial(responseMaterial.data);
  }

  const handleRecebimento = async (e) => {
    setAtribuirMetalShow(true)
    handleMaterial()
  }

  const handleAtribuicaoMetal = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);

    const data = {
      cliente: activeUser.USERID,
      material: formData.get("metal"),
      quantidade: formData.get("quantidade"),
      data_hora: formData.get("datetime"),
      descricao: formData.get("detalhamento"),
      token: token
    }

    const response = await userServices.balanceAdd(data);
    if (response.data.status === "success") {
      toast.dismiss()
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
      setAtribuirMetalShow(false)
    } else {
      toast.dismiss()
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }



  const handleClose = () => {
    setModalCadastroShow(false)
    setDefinirPercentualModalShow(false)
    setAdressField("")
    setModalPassShow(false)
    setDefinirPermissoesModalShow(false)
    setAtribuirMetalShow(false)
  }

  const getUsers = async () => {
    try {
      const request = {
        token: token
      }
      const response = await userServices.getUsers(request);
      const data = response.data.users;

      // Crie um mapa onde a chave é a primeira letra e o valor é um array de usuários com essa primeira letra
      const groupsMap = new Map();

      data.forEach(element => {
        const firstLetter = element.NAME.charAt(0).toUpperCase(); // Garanta que a primeira letra seja maiúscula
        if (!groupsMap.has(firstLetter)) {
          groupsMap.set(firstLetter, []);
        }
        groupsMap.get(firstLetter).push(element);
      });

      // Converta o mapa de grupos em um array de objetos
      const groupsArray = Array.from(groupsMap, ([group, items]) => ({ group, items }));

      // Ordene os grupos pelo nome da letra
      groupsArray.sort((a, b) => a.group.localeCompare(b.group));

      setGroups(groupsArray);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  }

  const handleCep = async (cep) => {
    const response = await cepServices.getAdress(cep);

    if (response.status === 200) {
      // Supondo que os dados do endereço estejam em response.data
      setEndereco({
        ...endereco,
        cep: response.data.cep,
        rua: response.data.logradouro,
        cidade: response.data.localidade,
        bairro: response.data.bairro,
        estado: response.data.uf,
      });
      setAdressField("disabled")
    } else {
      toast.dismiss()
      toast.error("CEP não encontrado", {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }


  };

  const handleUserData = async (id) => {
    const response = await userServices.getUserDataToEdit(id);
    setEditPermission(false)
    setActiveUser(response.data.result)
  }

  const handleOpen = () => {
    setModalCadastroShow(true)
  }

  const handlePassModal = async (e, activeUser) => {
    e.preventDefault()
    setModalPassShow(true)
  }

  const handlePercentual = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const percentual = formData.get("percentual_loss")

    // toast.loading("Processando...", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // })

    const data = {
      "token": token,
      "user": activeUser.USERID,
      "percentual": percentual
    }

    const response = await userServices.setPercentual(data);
    if (response.data.status === "success") {
      toast.dismiss()
      toast.success(response.data.msg)
      handleUserData(activeUser.USERID)
      setTimeout(() => {
        setDefinirPercentualModalShow(false)
      }, 2000)
    } else {
      toast.dismiss()
      toast.error(response.data.msg)
    }

  }


  const handlePass = async (e, token, activeUser) => {
    e.preventDefault();
    toast.loading("Gerando nova senha...", {
      position: toast.POSITION.BOTTOM_CENTER
    })
    const response = await userServices.passWordChange(token, activeUser)
    if (response.data.status === "success") {
      toast.dismiss()
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
      setModalPassShow(false)
    } else {
      toast.dismiss()
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }



  const handleInputChange = (e, f) => {
    const value = e.target.value
    const field = f.toUpperCase();
    setActiveUser((prevData) => ({
      ...prevData,
      [field]: value
    }))
  }

  const handleEdit = async (e) => {
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER })
    e.preventDefault()
    const data = {
      user: activeUser,
      token: token
    }
    const response = await userServices.editUser(data);
    toast.dismiss()
    if (response.data.status === "error") {
      toast.error(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER })
    } else {
      toast.success(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER })
    }
  }

  const handleCadastro = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER })
    // Acesse os valores do FormData usando o método .get()
    const data = {
      action: "create",
      name: formData.get("name"),
      email: formData.get("email"),
      typeUser: formData.get("type_user"),
      document: formData.get("cpf"),
      matricula: formData.get("matricula"),
      fantasy_name: formData.get("fantasyName"),
      phone: formData.get("telefone"),
      postalCode: endereco.cep,
      address: endereco.rua, // Nome do campo deve ser "rua"
      number: formData.get("numero"),
      neighborhood: endereco.bairro, // Nome do campo deve ser "bairro"
      city: endereco.cidade, // Nome do campo deve ser "cidade"
      uf: endereco.estado, // Nome do campo deve ser "estado"
      token: token
    };

    const response = await userServices.createUser(data);
    toast.dismiss();
    if (response.data.status === "error") {
      toast.error(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER })
    } else {
      toast.success(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER })
    }
  }

  useEffect(() => {
    getUsers()
  }, [token])


  useEffect(() => {
    document.body.classList.add('page-app')
    return () => {
      document.body.classList.remove('page-app')
    }
  }, []);


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  // toggle contact sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);

  const sidebarShow = () => {
    setSidebarShow(!isSidebarShow);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={"contact-panel " + (isSidebarShow ? "sidebar-show" : "")}>
          <div className="contact-body">

            <div className="contact-list">
              <div className="contact-list-header">
                <Link onClick={sidebarShow} href="#" className="contact-menu d-xl-none"><i className="ri-arrow-left-line"></i></Link>
                <div className="mt-3">
                  <div className="mb-4">
                    <Button onClick={() => handleOpen()} variant="primary" className="btn-contact-new">
                      <i className="ri-add-fill"></i> Novo Cadastro
                    </Button>
                  </div>
                </div>
              </div>

              <div className="form-search w-100 position-sticky">
                <Form className="d-flex w-100">
                  <Form.Control onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 1) {
                      setSearchUser(value)
                    }
                  }} placeholder="Pesquisar"></Form.Control>
                  <Button type="submit" variant="outline"><Search /></Button>
                </Form>

              </div>

              <PerfectScrollbar className="contact-list-body">

                {groups.map((contact, index) => (
                  <React.Fragment key={index}>
                    <label className="sidebar-label mb-2">{contact.group}</label>
                    <div className="contact-group mb-3">
                      {contact.items.map((item, ind) => {
                        const userName = item.NAME.toUpperCase();
                        const isSelected = item.selected;
                        const isMatchingSearch = userName.includes(searchUser.toUpperCase());
                        const shouldRender = isMatchingSearch;

                        if (shouldRender) {
                          return (
                            <div
                              onClick={() => handleUserData(item.ID)}
                              key={ind}
                              className={`contact-item ${isSelected ? "selected" : ""}`}
                            >
                              <div className="contact-item-body">
                                <div className="d-flex align-items-center mb-1">
                                  <h6 className="mb-0">{item.NAME}</h6>
                                </div>
                                <span>{item.USER_TYPE}</span>
                                <span>{item.EMAIL}</span>
                                <span>{item.PHONE}</span>
                              </div>
                            </div>
                          );
                        }

                        return null; // Renderiza null se não corresponder ao critério de pesquisa
                      })}

                    </div>
                  </React.Fragment>
                ))}
              </PerfectScrollbar>

            </div>

            {activeUser.USERID ? (
              <PerfectScrollbar hidden={typeof activeUser === "undefined" ? true : false} className="contact-content">
                <Link id="contactClose" href="" className="contact-close"><i className="ri-close-fill"></i></Link>
                <div className="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-4">
                  <div className="me-4 mb-3 mb-sm-0"><Avatar img={img11} /></div>
                  <div className="flex-fill">
                    <h4 className="mb-1 text-dark fw-semibold">{activeUser.NAME} - {activeUser.FANTASY_NAME}</h4>
                    <div className="">
                      <p>{activeUser.USER_TYPE_TEXT}</p>
                      {activeUser.USER_TYPE === 1 ? (<p className=""> Código do Cliente: {activeUser.CLIENT_CODE}</p>) : (<></>)}
                    </div>
                    <div className="g-3 d-flex flex-wrap">
                      <div className="d-flex flex-wrap g-3 mx-1">
                        <div className="d-flex">
                          <Button variant="primary" onClick={() => handleEditPermission()} className="px-5">Editar</Button>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap g-3 mx-1">
                        <div className="d-flex">
                          <Button variant="primary" onClick={() => handleDefinirPercentual()} className="px-5">Definir Percentual</Button>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap g-3 mx-1">
                        <div className="d-flex">
                          <Button variant="primary" onClick={() => handleDefinirPermissoes()} className="px-5">Definir Permissões</Button>
                        </div>
                      </div>
                      {activeUser.USERID ?
                        (<div className="d-flex flex-wrap g-3 mx-1">
                          <div className="d-flex">
                            <Button variant="primary" onClick={() => handleRecebimento()} className="px-5">Novo Recebimento</Button>
                          </div>
                        </div>
                        ) : (
                          <>
                          </>)}
                    </div>
                  </div>
                </div>

                <hr />


                <form onSubmit={handleEdit}>
                  <Row>
                    <Col hidden={!editPermission} >
                      <label for="email">Nome</label>
                      <Form.Control className="w-100" name="name" id="name" type="text" onChange={(e) => handleInputChange(e, "NAME")} value={activeUser.NAME} />
                    </Col>
                    <Col hidden={!editPermission} >
                      <label for="email">Nome Fantasia</label>
                      <Form.Control className="w-100" type="text" name="fantasy_name" id="fantasy_name" onChange={(e) => handleInputChange(e, "fantasy_name")} value={activeUser.FANTASY_NAME} />
                    </Col>
                    <Col hidden={!editPermission} >
                      <label for="email">Código do Cliente</label>
                      <Form.Control className="w-100" type="text" name="client_code" id="client_code" onChange={(e) => handleInputChange(e, "CLIENT_CODE")} value={activeUser.CLIENT_CODE} />
                    </Col>


                    <Col >
                      <label for="email">Email</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="email" id="email" onChange={(e) => handleInputChange(e, "email")} className="w-100" type="text" value={activeUser.EMAIL} />
                    </Col>
                    <Col >
                      <label for="email">Permissão para autenticar</label>
                      <Form.Select disabled={editPermission === false ? "disabled" : ""} name="authentication" id="authentication" onChange={(e) => handleInputChange(e, "auth")} className="w-100" value={activeUser.AUTH}>
                        <option value="">
                          Selecione
                        </option>
                        <option value="0">
                          Não
                        </option>
                        <option value="1">
                          Sim
                        </option>
                      </Form.Select>
                    </Col>
                    <Col >
                      <label for="telefone">Telefone</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="phone" id="phone" onChange={(e) => handleInputChange(e, "phone")} className="w-100" type="text" value={activeUser.PHONE} />
                    </Col>
                    <Col>
                      <label for="matricula">Matricula</label>
                      <Form.Control className="w-100" disabled={editPermission === false ? "disabled" : ""} type="text" name="matricula" id="matricula" onChange={(e) => handleInputChange(e, "MATRICULA")} value={activeUser.MATRICULA}></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label for="email">Rua</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="address" id="address" onChange={(e) => handleInputChange(e, "street")} className="w-100" type="text" value={activeUser.STREET} />
                    </Col>
                    <Col>
                      <label for="numero">Numero</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="number" id="number" onChange={(e) => handleInputChange(e, "number")} className="w-100" value={activeUser.NUMBER}></Form.Control>
                    </Col>
                    <Col>
                      <label for="numero">Complemento</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="complement" id="complement" onChange={(e) => handleInputChange(e, "complement")} className="w-100" value={activeUser.COMPLEMENT}></Form.Control>
                    </Col>
                    <Col>
                      <label for="numero">Bairro</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="neighborhood" id="neighborhood" onChange={(e) => handleInputChange(e, "NEIGHBORHOOD")} className="w-100" value={activeUser.NEIGHBORHOOD}></Form.Control>
                    </Col>
                    <Col>
                      <label for="numero">Cidade</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="city" id="city" onChange={(e) => handleInputChange(e, "city")} className="w-100" value={activeUser.CITY}></Form.Control>
                    </Col>
                    <Col>
                      <label for="numero">UF</label>
                      <Form.Control disabled={editPermission === false ? "disabled" : ""} name="uf" id="uf" onChange={(e) => handleInputChange(e, "uf")} className="w-100" value={activeUser.UF}></Form.Control>
                    </Col>
                  </Row>
                  <Row className="w-50">
                    <Col className="mt-3 display-flex align-items-center justify-content-center">
                      <Card className="card-one">
                        <Button hidden={editPermission === false ? "hidden" : ""} type="submit">Salvar</Button>
                      </Card>
                    </Col>
                    <Col className="mt-3 display-flex align-items-center justify-content-center">
                      <Card className="card-one">
                        <Button hidden={editPermission === false ? "hidden" : ""} onClick={(e) => handlePassModal(e, activeUser)}>Definir uma nova senha</Button>
                      </Card>
                    </Col>
                  </Row>
                </form>
              </PerfectScrollbar>

            ) : (<>
            </>)}

          </div>
        </div>

        <Footer />
        <Modal size="xl" show={modalCadastroShow} onHide={handleClose}>
          <form onSubmit={handleCadastro}>
            <Modal.Header>
              <ModalTitle>Novo Usuário</ModalTitle>
            </Modal.Header>
            <Modal.Body>
              <Row className="g-3">
                <Card className="p-3">
                  <Row>
                    <Col md="6" >
                      <label for="email">Nome</label>
                      <Form.Control className="w-100" name="name" id="name" type="text" />
                    </Col>
                    <Col md="6" >
                      <label for="email">Nome Fantasia</label>
                      <Form.Control className="w-100" name="fantasyName" id="fantasyName" type="text" />
                    </Col>
                    <Col md="6" >
                      <label for="email">CPF / CNPJ</label>
                      <Form.Control className="w-100" name="cpf" id="cpf" type="text" />
                    </Col>
                    <Col md="6" >
                      <label for="email">Tipo de Usuário</label>
                      <Form.Select name="type_user" id="type_user">
                        <option value="">Selecione</option>
                        <option value="1">Cliente</option>
                        <option value="2">Colaborador</option>
                        <option value="7">Modelista</option>
                      </Form.Select>
                    </Col>
                    <Col md="6">
                      <label for="email">Email</label>
                      <Form.Control className="w-100" name="email" id="email" type="email" />
                    </Col>
                    <Col md="6">
                      <label for="telefone">Telefone</label>
                      <Form.Control className="w-100" name="telefone" id="telefone" type="phone" />
                    </Col>
                  </Row>
                </Card>
                <Card className="p-3">
                  <Row>
                    <Col md="6">
                      <label for="email">CEP</label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Digite o cep e aperte <strong>TAB</strong>
                          </Tooltip>

                        }
                      >
                        <Info size={18} className="mx-1 mb-1" />
                      </OverlayTrigger>
                      <Form.Control
                        onBlur={(e) => {
                          if (e.target.value.length > 7) {
                            handleCep(e.target.value)
                          }
                        }}
                        disabled={addressField}
                        className="w-100"
                        name="cep"
                        id="cep"
                        onChange={(e) => {
                          setEndereco({
                            ...endereco,
                            cep: e.target.value
                          })
                        }}
                        type="text" />

                    </Col>
                    <Col md="6">
                      <label for="email">Rua</label>
                      <Form.Control
                        disabled={addressField}
                        className="w-100"
                        name="rua"
                        id="rua"
                        value={endereco.rua}
                        onChange={(e) => {
                          setEndereco({
                            ...endereco,
                            rua: e.target.value
                          })
                        }}
                        type="text" />
                    </Col>
                    <Col md="6">
                      <label for="numero">Numero</label>
                      <Form.Control
                        className="w-100" name="numero" id="numero"></Form.Control>
                    </Col>
                    <Col md="6">
                      <label for="complemento">Complemento</label>
                      <Form.Control className="w-100" name="complemento" id="complemento"></Form.Control>
                    </Col>
                    <Col md="6">
                      <label for="numero">Bairro</label>
                      <Form.Control
                        disabled={addressField}
                        className="w-100"
                        name="bairro"
                        id="bairro"
                        value={endereco.bairro}
                        onChange={(e) => {
                          setEndereco({
                            ...endereco,
                            bairro: e.target.value
                          })
                        }}
                      >
                      </Form.Control>
                    </Col>
                    <Col md="6">
                      <label for="cidade">Cidade</label>
                      <Form.Control
                        disabled={addressField}
                        name="cidade"
                        id="cidade"
                        value={endereco.cidade}
                        onChange={(e) => {
                          setEndereco({
                            ...endereco,
                            cidade: e.target.value
                          })
                        }}
                        className="w-100" />
                    </Col>
                    <Col md="6">
                      <label for="numero">UF</label>
                      <Form.Control
                        disabled={addressField}
                        name="estado"
                        id="estado"
                        value={endereco.estado}
                        onChange={(e) => {
                          setEndereco({
                            ...endereco,
                            estado: e.target.value
                          })
                        }}
                        className="w-100">
                      </Form.Control>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button type="submit" variant="primary">Cadastrar</Button>
                </Col>
              </Row>
            </Modal.Footer>
          </form>
        </Modal>
        <ToastContainer />

        <Modal size="lg" show={modalPassShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Definição de nova senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Você tem certeza de que quer gerar uma nova senha para este usuário?
            </h5>

            <Card className="p-2 text-center card-one">

              <h4>
                Email: {activeUser.EMAIL}
              </h4>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={(e) => handlePass(e, token, activeUser)}>
              Gerar nova senha
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={definirPermissoesModalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Definição de permissões</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSavePermission}>
              <Form.Select name="permission" id="permission">
                {typePermissions.map((type) => (
                  <option key={type.id} value={type.type + "|" + type.id}>{type.type} - {type.name}</option>
                ))}
              </Form.Select>
              <Button className="py-1 mt-3" type="submit">Salvar</Button>
            </form>

            <Card className="p-2 mt-3">
              <Row>
                <Col md={12} className="d-flex flex-wrap">
                  {userPermissions.map((type) => (
                    <Button
                      value={type.id}
                      className="rounded p-1 m-1 text-xl text-center d-flex align-items-center justify-content-between"
                      onClick={(e) => handlePermissionRemove(e.target.value)}
                    >
                      <Trash2 size={16} />
                      {type.name}
                    </Button>
                  ))}
                </Col>
              </Row>
            </Card>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={definirPerventualModalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Percentual de perda de metal</Modal.Title>
          </Modal.Header>
          <form onSubmit={handlePercentual}>
            <Modal.Body>
              <div className="w-100 d-flex justify-content-center">
                <Alert color="info" className="text-center w-50" isOpen={true}>
                  Aqui é possível definir de acordo com cada cliente qual é o percentual de perda de metal por peça/pedido, essa quantidade será somado ao montatne necessário para produção.
                </Alert>
              </div>

              <Card className="p-2 text-center card-one">
                <Row>
                  <Col>
                    <Card.Title>
                      Percentual:
                    </Card.Title>
                    <span className="form-control">{activeUser.PERCENT_LOSS}</span>
                  </Col>
                  <Col>
                    <Form.Label>
                      Definir Percentual de Perda
                    </Form.Label>
                    <Form.Control name="percentual_loss" id="percentual_loss" className="" placeholder="%">
                    </Form.Control>
                  </Col>
                </Row>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal size="lg" show={atribuirMetalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Lançar recebimento de metal</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleAtribuicaoMetal} >
            <Modal.Body>
              <Card className="p-2 text-center card-one">
                <Row>
                  <Col>
                    <Form.Label>
                      Material:
                    </Form.Label>
                    <Form.Select name="metal" id="metal">
                      <option value={""}>---Selecione---</option>
                      {material.map((type) => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>
                      Quantidade
                    </Form.Label>
                    <Form.Control name="quantidade" id="quantidade" placeholder="Digite a quantidade:">
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Label>
                      Data de Recebimento
                    </Form.Label>
                    <Form.Control type="datetime-local" name="datetime" id="datetime" className="" placeholder="%">
                    </Form.Control>
                  </Col>
                </Row>
                <div className="w-100 mt-3">
                  <Form.Label for="detalhamento">Detalhamento</Form.Label>
                  <textarea name="detalhamento" id="detalhamento" className="form-control">

                  </textarea>

                </div>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
}