import React, { Component, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
    applicationsMenu,
    dashboardMenu,
    pagesMenu,
    uiElementsMenu
} from "../data/Menu";
import { userServices } from "../apiServices/userServices";
import Skeleton from "react-loading-skeleton";
import logo from "../assets/img/logo.png";
import { Badge } from "react-bootstrap";
import { OrderContext } from "../Context/OrderContext";
import { apiServices } from "../apiServices/ApiServices";

const token = localStorage.getItem("token")

var filteredMenu = "";

export default class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userPermissions: [],
            isLoading: true
        };



    }
    componentDidMount() {
        this.getPermissions();
    }


    async getPermissions() {
        try {
            const response = await userServices.getSideBarOptions(token);
            if (response.data === "Erro: Expired token[]") {
                localStorage.clear()
                window.location.href = '/signin'; // Redirecione o usuário para a página de login
            } else {
                const permissions = response.data.map((permission) => {
                    return permission.id;
                });

                localStorage.setItem("up", JSON.stringify(permissions));

                this.setState({ userPermissions: permissions, isLoading: false });
            }
        } catch (error) {
            console.error('Erro ao buscar as permissões do usuário:', error);
        }
    }

    toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    render() {

        const fullName = localStorage.getItem('name');
        const name = fullName.split(' ')[0];
        const logout = () => {
            localStorage.removeItem('token')
            window.location.reload()
        }

        const userPermissions = this.state.userPermissions.map(id => parseInt(id, 10)); // ou +id

        const idToDashboardItem = {};
        dashboardMenu.forEach(item => {
            idToDashboardItem[item.id] = item;
        });

        // Filtrar com base nas permissões do usuário
        filteredMenu = dashboardMenu.filter(item => {
            // Verificar se o item atual tem um ID que está na lista de IDs de permissão
            return userPermissions.includes(parseInt(item.id, 10));
        });


        return (
            <div className="sidebar">
                <div className="sidebar-header">
                    <Link to="/dashboard" className="d-flex align-items-center justify-content-center">
                        <img className="align-self-center" src={logo} width={160} alt="Logo" />
                    </Link>
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    {this.state.isLoading ? (
                        <div className="p-2">
                            <Skeleton count={3} />
                        </div>
                    ) : (
                        <SidebarMenu userPermissions={filteredMenu} />
                    )
                    }
                </PerfectScrollbar>
                {/* <div className="sidebar-footer">
                    <div className="sidebar-footer-top">
                        <div className="sidebar-footer-thumb">
                            <img src={userAvatar} alt="" />
                        </div>
                        <div className="sidebar-footer-body">
                            <h6><Link to="../pages/profile.html">{name}</Link></h6>
                        </div>
                        <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                    </div>
                    <div className="sidebar-footer-menu">
                        <nav className="nav">
                        <Link onClick={logout}><i className="text-lg ri-logout-box-r-line"></i> Sair </Link>
                            <Link></Link>
                            <Link></Link>
                        </nav>
                        <hr />
                        <nav className="nav">
                            <Link></Link>
                            <Link></Link>
                            <Link></Link> 
                        </nav>
                    </div>
                </div> */}
            </div>
        );
    }
}


class SidebarMenu extends Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props);

        this.state = {
            countOrderInInitialStatus: 0
        }
    }
    componentDidMount() {
        this.getCountOfOrdersInInitialStatus();
    }

    async getCountOfOrdersInInitialStatus() {
        try {
            const payload = {
                action: "get_order_in_initial_status",
                id_type_order: '1',
                token: token,
            }
            const response = await apiServices.getCountOrderInInitialStatus(payload);
            this.setState({ countOrderInInitialStatus: response.data.amount_order_in_initial_status });
        } catch (error) {
            console.error('Error fetching order count:', error);
        }
    }

    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} target={m.target} className="nav-link">{typeof m.icon === "string" ? <i className={m.icon}></i> : <i>{m.icon}</i>} <span className="w-100 d-flex flex-row justify-content-between"><span>{m.label}</span>{m.id == 3 && localStorage.getItem("utp") == "interno" ? <Badge className="d-flex flex-row align-items-center justify-content-center"><strong>{this.state.countOrderInInitialStatus}</strong></Badge> : null}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub">{typeof m.icon === "string" ? <i className={m.icon}></i> : <i>{m.icon}</i>} <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    render() {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    <div className="nav-label">Dashboard</div>
                    {/* <div className="nav-label" onClick={this.toggleMenu}>Dashboard</div> */}
                    {this.populateMenu(filteredMenu)}
                </div>
                {process.env.NODE_ENV !== 'production' ?
                    <>
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>Applications</div>
                            {this.populateMenu(applicationsMenu)}
                        </div>
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>Pages</div>
                            {this.populateMenu(pagesMenu)}
                        </div>
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>UI Elements</div>
                            {this.populateMenu(uiElementsMenu)}
                        </div>
                    </> : <></>
                }
            </React.Fragment>
        )
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});