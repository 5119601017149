import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/img/logo.webp"; import logoBranca from "../assets/img/logo-branca.png";
import pageSvg from "../assets/svg/mailbox.svg";
import { userServices } from "../apiServices/userServices";

export default function ValidateEmail() {
  const { param } = useParams();

  const skin = localStorage.getItem('skin-mode')

  const [label, setLabel] = useState("Processando...");
  const [show, setShow] = useState(false);
  const handleValidate = async () => {
    const response = await userServices.validadeEmail(param);

    if (response.data.status === "success") {
      setLabel(response.data.msg);
      setShow(true)
    } else {
      setLabel(response.data.msg);
      setShow(false)
    }
  };

  useEffect(() => {
    handleValidate();
  }, [param]);

  document.body.classList.remove("sidebar-show");

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
          <img src={skin && skin === "dark" ? logoBranca : logo} width={200}></img>
          </Link>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <Card.Text className="mb-5column ">
                <h1>{label}</h1>
              </Card.Text>
              {show ? <a href="/">Clique aqui para acessar o sistema!</a> : null}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
