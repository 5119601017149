import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/Header";
import { Editor } from '@tinymce/tinymce-react';
import Footer from "../layouts/Footer";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Form,
  Badge,
  ButtonGroup,
  Table,
  Pagination,
  Modal,
  Accordion,
  Image,
  ModalBody,
  Select,
  InputGroup,
  FormGroup,
  FormText,
  CardGroup,
} from "react-bootstrap";
import { Link, Navigate, useAsyncError, useNavigate } from "react-router-dom";
import { apiServices } from "../apiServices/ApiServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Search } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.css";
import Avatar from "../components/Avatar";
import { CarouselCustom } from "../components/Carousel";
import { productServices } from "../apiServices/productServices";
import { userServices } from "../apiServices/userServices";
import { CartFunctions, CartResume } from "../Context/CartFunctions";
import { useMediaQuery } from "react-responsive";
import { CarrinhoButton } from "../components/Carrinho/CarrinhoButton";
import "./styles/Vitrine.css";
import { swalErro } from "../components/Swal";

export default function Vitrine() {
  const desktop = useMediaQuery({ minWidth: 1224 });
  const mobile = useMediaQuery({ maxWidth: 1224 });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  /// End Skin Switch ///

  const token = localStorage.getItem("token");

  // ############### PRODUCTS STATE ############### //
  const [products, setProducts] = useState([]);
  // const [link, setLink] = useState(null);

  // ############### PAGE STATE ############### //
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [cart, setCart] = useState([]);
  const [cartDetail, setCartDetail] = useState([]);
  const [cartResume, setCartResume] = useState({ metal: [], pedras: [] });

  useEffect(() => {
    setCartResume(CartFunctions(cartDetail, cart));
  }, [cartDetail]);

  // ############### PRODUCT SEARCH STATE ############### //
  const [searchKey, setSearchKey] = useState("");
  const [productType, setProductType] = useState(0);
  const [productCategory, setProductCategory] = useState(null);

  // ############### PRODUT LOADING STATE ############### //
  const [productLoading, setProductLoading] = useState(true);

  // ############### METAL EDIT STATE ############### //
  const [modalProductEditShow, setModalProductEditShow] = useState(false);
  const [modalProductContent, setModalProductContent] = useState({
    id: "",
    name: "",
  });

  const [modalCartShow, setModalCartShow] = useState(false);
  const handleGetDataHeader = () => {
    setModalCartShow(true);
  };
  const handeCloseModalCart = () => {
    setModalCartShow(false);
  };

  // ############### METAL DETAIL STATE ############### //
  const [modalProductShow, setModalProductShow] = useState(false);
  const [modalProduct, setModalProduct] = useState({
    permission: 0,
    product: {},
  });

  const editorRef = useRef(null);
  const [newProductDescription, setNewProductDescription] = useState("");
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(modalProduct.product.info_07);
      setNewProductDescription(modalProduct.product.info_07);
    }
  }, [modalProduct.product.info_07]);

  // Atualiza o estado quando o conteúdo do editor muda
  const onUpdatingModalProductDescription = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setNewProductDescription(content);
    }
  };

  const [metalModalProduct, setMetalModalProduct] = useState([]);
  const [metalModule, setMetalModule] = useState([]);
  const [metalNailing, setMetalNailing] = useState([]);
  const [metalTypeNailing, setMetalTypeNailing] = useState([]);
  const [metalAttachment, setMetalAttachment] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const [modalImageModule, setModalImageModule] = useState(false);
  const [modalPedido, setModalPedido] = useState(false);

  const [typeNailing, setTypeNailing] = useState([]);
  const [productTypeNailing, setProductTypeNailing] = useState([]);

  const [productWeight, setProductWeight] = useState(1);

  const [par, setPar] = useState(false);
  const [users, setUsers] = useState([]);

  const [typeSubmit, setTypeSubmit] = useState(null);

  const [toastStatus, setToastStatus] = useState({
    status: "",
    message: "",
  });

  // ############### FUNCTIONS ############### //

  function combineData(productTypeNailing, typeNailing) {
    const combinedData = {};

    productTypeNailing.forEach((product) => {
      const typeId = product.nt_id;
      const typeName = typeNailing.find((type) => type.id === typeId)?.name;

      if (typeName) {
        if (!combinedData[typeName]) {
          combinedData[typeName] = {};
        }

        combinedData[typeName][product.nt_id] = product.amount;
      }
    });
    return combinedData;
  }

  const combinedData = combineData(productTypeNailing, typeNailing);

  const editProduct = (id, name) => {
    // ############### ATRIBUI VALORES E ABRE O MODAL DE EDIÇÃO ############### //
    setModalProductContent({ id: id, name: name });
    setModalProductEditShow(true);
  };

  const handleClose = () => {
    // ############### FECHA MODAL ############### //
    setModalProductEditShow(false);
    setModalProductShow(false);
    setModalImageModule(false);
    setModalPedido(false);
    setOrderData({
      id_product: "",
      order_type: 1,
      product_metal: "",
      product_metal_color: "",
      amount_product: 0,
      amount_product_metal: productWeight,
      amount_product_metal_total: 0,
      ordder_description: newProductDescription,
      id_client: "",
      pair: "N",

    })
  };

  const handleOpenModalImage = (id) => {
    setModalImageModule(true);
    handleAttachment(id, token);
  };

  const handleOpenModalPedido = (id) => { };

  const [orderData, setOrderData] = useState({
    id_product: "",
    order_type: 1,
    product_metal: "",
    product_metal_color: "",
    amount_product: 0,
    amount_product_metal: productWeight,
    amount_product_metal_total: 0,
    ordder_description: newProductDescription,
    id_client: "",
    pair: "N",

  })


  useEffect(() => {
    if (users.length == 1) {
      setOrderData(prevData => ({
        ...prevData, id_client: users[0].id
      }))
    }
  }, [users])

  useEffect(() => { setOrderData({ ...orderData, ordder_description: newProductDescription }) }, [newProductDescription])


  useEffect(() => {
    if (modalProduct?.product) {

      setOrderData((prevData) => ({
        ...prevData,


        id_client:
          modalProduct.product.client_id !== '44'
            ? modalProduct.product.client_id
            : users?.length == '1' ? users[0].id : '',
        id_product: modalProduct.product.product_id || "",
      }));
    }
  }, [modalProduct]);

  const ajaxNewOrder = async (e) => {
    e.preventDefault();
    toast.loading("Procesando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formDataObject = {
      action: "create_order",
      id_status: parseInt(typeSubmit),
      token: token,
      ...orderData
    };


    const response = await apiServices.createOrder(formDataObject);

    if (response.data.status === "info") {
      toast.dismiss();
      toast.info(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (response.data.status === "error") {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (response.data.status === "success") {
      handleClose()
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleGetCart(token);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    const searchKey = formData.get("pesquisar");
    const productType = formData.get("product_type");
    const category = formData.get("product_category");

    if (searchKey || productType || category) {
      setSearchKey(searchKey);
      setProductType(productType);
      setProductCategory(category);
    } else {
      setSearchKey("");
      setProductCategory(null);
      setProductType(null);
    }
  };

  const handleSearchNoneValue = (event) => {
    // ############### SETA ESTADO DE FILTRO QUANDO NÃO HÁ VALOR NO INPUT ############### //
    let formData = event.target.value;
    if (formData.length === 0) {
      setSearchKey("");
    }
  };

  const handleProductType = (event) => {
    // ############### ATUALIZA ESTADO DE TIPO DE PRODUTO PARA FILTRO (TODOS, CRIARTGOLD, MEUS PRODUTOS) ############### //
    const value = event.target.value;
    setProductType(value);
  };

  const handlePageClick = async (pageNumber) => {
    window.scrollTo(0, 0);
    // ############### ATUALIZA PÁGINA ############### //
    setProductLoading(true);
    setCurrentPage(pageNumber);
    await productServices
      .getProducts(
        token,
        pageNumber,
        searchKey,
        productType,
        "showcase",
        productCategory
      )
      .then((response) => {
        setProducts(response.data.products);
        //setLink(response.data.link);
        setTotalPages(response.data.total_pages);
        setProductLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateProduct = async (id_product, id_status, token) => {
    // ############### ATUALIZA STATUS DE PRODUTO PARA EDIÇÃO ############### //
    setModalProductEditShow(false);
    await productServices
      .updateProduct(id_product, id_status, token)
      .then((response) => {
        setToastStatus({
          status: response.data.status,
          message: response.data.msg,
        });
        setShowSuccessToast(true);
        setTimeout(() => {
          setShowSuccessToast(false);
        }, 5000); // Oculta o toast após 5 segundos
      });
  };

  const renderProducts = async (
    token,
    page,
    searchKey,
    productType,
    productCategory
  ) => {
    // ############### RENDERIZA PRODUTOS NA LISTAGEM INICIAL DA PÁGINA ############### //
    await productServices
      .getProducts(
        token,
        page,
        searchKey,
        productType,
        "showcase",
        productCategory
      )
      .then((response) => {
        //setLink(response.data.link);
        setProducts(response.data.products);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => console.log(error));
  };

  const [material, setMaterial] = useState([]);
  const [materialColor, setMaterialColor] = useState([]);


  const [quantidadeNecessariaState, setQuantidadeNecessariaState] = useState(0);

  const handleNewOrder = async (id) => {
    setModalPedido(true);

    const responseMaterial = await productServices.selectListMaterial(
      id,
      token
    );
    setMaterial(responseMaterial.data);

    const responseClients = await userServices.userSearch(token);

    setUsers(responseClients.data);

    const responseMaterialColor = await productServices.selectListMaterialcolor(
      id,
      token
    );
    setMaterialColor(responseMaterialColor.data);

    const responseProductDescription = await productServices.getProductDescription(id, token);
    setProductWeight(
      responseProductDescription.data.product.metal_amount);

    getModalProduct(id, token);
    handleAttachment(id, token);
  };

  const handleQuantidadeNecessaria = (e) => {
    let valorRecebido = e.target.value;
    let peso = parseFloat(productWeight);
    let total = valorRecebido * peso;
    setQuantidadeNecessariaState(total);
  };

  const getModalProduct = async (id, token) => {
    const response = await productServices.getProductDescription(id, token);
    setModalProduct({
      permission: response.data.permission,
      product: response.data.product,
    });
  };

  const getMaterialProduct = async (id, token) => {
    const response = await productServices.getMetalProduct(id, token);
    const metals = response.data.result.map((item) => item);
    setMetalModalProduct(metals);
    const permission = response.data.permission === 0 ? "disabled" : "";
    setPermissionRemoveEdit(permission);
  };

  const getModule = async (id, token) => {
    const response = await productServices.getMetalModule(id, token);
    const modules = response.data.result.map((item) => item);
    setMetalModule(modules);
  };

  const getNailing = async (id, token) => {
    const response = await productServices.getMetalNailing(id, token);
    const nailing = response.data.result.map((item) => item);
    setMetalNailing(nailing);
  };

  const getTypeNailing = async (id, token) => {
    const data = {
      product: id,
      token: token,
    };
    const response = await productServices.getTypeNailing(data);
    setTypeNailing(response.data);
  };

  const getProductTypeNailing = async (id, token) => {
    const data = {
      product: id,
      token: token,
    };
    const response = await productServices.getProductTypeNailing(data);
    setProductTypeNailing(response.data.result);
  };

  const [permissionRemoveEdit, setPermissionRemoveEdit] = useState("");

  const handleProductDetail = async (id) => {
    setModalProductShow(true);
    try {
      await getModalProduct(id, token);
      await getMaterialProduct(id, token);
      await getModule(id, token);
      await getNailing(id, token);
      await getTypeNailing(id, token);
      await getProductTypeNailing(id, token);
      await handleAttachment(id, token)
    } catch (error) {
      return;
    }
  };

  const handleAttachment = async (id, token) => {
    const response = await productServices.getMetalAttachment(id, token);
    const attachment = response.data.result.map((item) => item);
    setMetalAttachment(attachment);
  };

  const handleGetCart = async (token) => {
    const response = await apiServices.getCart(token);
    setCart(response.data.cart);

    setCartDetail(response.data.detail);
    //setCart(response.data.cart);
  };

  const handleRemoveOrder = async (order) => {
    toast.loading("Removendo produto...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      id_order: order,
      token: token,
    };
    const response = await apiServices.removeOrder(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      handleGetCart(token);
    }
  };

  const navigate = useNavigate();

  const handleCloseCart = async () => {
    toast.loading("Fechando carrinho...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const response = await apiServices.closeCart(token);
    toast.dismiss();
    if (response.data.status === "success") {
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTimeout(() => {
        navigate("/pedidos");
      }, 3000);
    } else {
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    renderProducts(token, 1, searchKey, productType, productCategory);
    handleGetCart(token);
  }, [token, searchKey, productCategory, productType]);

  return (
    <React.Fragment>
      <Header
        onSkin={setSkin}
        sendDataToParent={handleGetDataHeader}
        cart={cart}
      />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one d-flex">
              <Card.Header
                className={`d-flex  ${desktop
                  ? "flex-row justify-content-between"
                  : "flex-column justify-content-center"
                  }`}
              >
                <Card className="w-100">
                  <Card.Body>
                    <Form
                      className={`d-flex w-100 h-100 ${desktop ? "gap-3 flex-row" : "flex-column"
                        }`}
                      onSubmit={handleSearch}
                    >
                      <Form.Group
                        className={`h-100 my-auto mb-3 ${desktop ? "w-100" : "w-100"
                          }`}
                      >
                        <Form.Label>Filtro</Form.Label>
                        <Form.Control
                          className="border form-search w-100"
                          onChange={handleSearchNoneValue}
                          placeholder="Pesquisar"
                          id="pesquisar"
                          name="pesquisar"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="w-100 h-100 my-auto mb-3">
                        <Form.Label>Categoria</Form.Label>
                        <Form.Select
                          name="product_category"
                          id="product_category"
                          className="border form-search shadow-0"
                        >
                          <option value={null}>Selecione</option>
                          <option value={1}>Anel</option>
                          <option value={3}>Brinco</option>
                          <option value={2}>Colar</option>
                          <option value={6}>Piercing</option>
                          <option value={4}>Pingente</option>
                          <option value={5}>Pulseira</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="w-100 h-100 my-auto mb-3">
                        <Form.Label>Modelo</Form.Label>
                        <Form.Select
                          name="product_type"
                          id="product_type"
                          className="border form-search"
                        >
                          <option value={0}>Todos</option>
                          <option value={1}>CriartGold</option>
                          <option value={2}>Meus Produtos</option>
                        </Form.Select>
                      </Form.Group>
                      <Button type="submit" variant="outline">
                        <Search />
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>

                {/* {cart.length > 0 ? (
                  <CarrinhoButton onClick={() => setModalCartShow(true)}>
                    <Badge
                      bg="danger"
                      className="p-1"
                      style={{ marginBottom: "-6px" }}
                    >
                      {cart.length}
                    </Badge>
                    <i className="ri-shopping-cart-line h2"></i>
                  </CarrinhoButton>
                ) : // <div className="w-100 d-flex justify-content-center">
                //   <Button
                //     onClick={() => setModalCartShow(true)}
                //     variant="light"
                //     className="w-5 d-flex flex-column justify-content-end align-items-end"
                //   >
                //     <Badge
                //       bg="danger"
                //       className="p-1"
                //       style={{ marginBottom: "-6px" }}
                //     >
                //       {cart.length}
                //     </Badge>
                //     <i className="ri-shopping-cart-line h2"></i>
                //   </Button>
                // </div>
                null} */}

                {/* <Nav
                  style={{ zIndex: 1 }}
                  className="nav-icon nav-icon-sm ms-auto d-flex flex-row w-40"
                >
                  <ButtonGroup aria-label="Basic checkbox toggle d-flex button group">
                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck1"
                      onClick={handleProductType}
                      value={0}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck1">
                      Todos os Produtos
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck2"
                      onClick={handleProductType}
                      value={1}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck2">
                      CriatGold
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck3"
                      onClick={handleProductType}
                      value={2}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck3">
                      Meus Produtos
                    </label>
                  </ButtonGroup>
                </Nav> */}
              </Card.Header>
              <Card.Body className="p-3">
                <div className="row row-cols-1 row-cols-md-2 g-2 justify-content-between overflow-auto">
                  {products.map((product, index) => (
                    <div
                      key={index}
                      style={{ width: "300px" }}
                      className="mb-4 d-block className custom-hover"
                    >
                      <Card
                        className="h-100"
                        style={{
                          minHeight: "350px",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={product.FILE}
                          width="100%"
                          alt={product.DESCRIPTION}
                          className="card-img-top"
                          style={{ cursor: "pointer", maxWidth: "100%" }}
                          onClick={() => handleProductDetail(product.ID)}
                          value={product.ID}
                        />
                        <Card.Body>
                          <Row className="d-flex align-items-end h-100 mb-0">
                            <Col md="12">
                              <div>
                                <h5 className="card-title text-center">
                                  {product.DESCRIPTION}
                                </h5>

                                <label className="d-block text-secondary text-center mb-1">
                                  <i className="ri-scales-2-line"></i>
                                </label>
                                <span className="d-block fw-medium text-dark text-center ff-numerals">
                                  {product.WEIGHT}
                                </span>
                              </div>
                            </Col>
                            <Col className="d-flex align-items-end justify-content-center text-center mb-0">
                              <div className="text-center h-100 mb-0 px-0">
                                <span className="d-block fw-medium text-dark ff-numerals">
                                  <Badge>{product.STATUS}</Badge>
                                </span>
                              </div>
                            </Col>

                            <div className="w-100 p-3">
                              <Button
                                variant="outline"
                                className="btn-criartgold mx-1 w-100 d-flex align-itemns-center justify-content-center flex-column"
                                onClick={() => handleNewOrder(product.ID)}
                                value={product.ID}
                              >
                                <i className="ri-shopping-bag-fill text-center w-100"></i>
                                <span className="text-center w-100">
                                  <strong>Fazer Pedido</strong>
                                </span>
                              </Button>
                            </div>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>

                <Modal
                  size="lg"
                  centered
                  fullscreen="md"
                  show={modalProductEditShow}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Alterar status da peça para edição
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <h4 className="text-center">
                        Deseja iniciar o processo de edição da peça?
                      </h4>
                      <h4>{modalProductContent.name}</h4>
                      <Button
                        onClick={() =>
                          updateProduct(modalProductContent.id, 5, token)
                        }
                      >
                        Confirmar
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal size="xl" show={modalProductShow} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {modalProduct.product.product_name}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card>
                      <Card.Header>Descrição da Peça</Card.Header>
                      <Card.Body>
                        <Row className="border">
                          <Col>
                            <dl>
                              <dt>Código do Cliente:</dt>
                              <dd>{modalProduct.product.client_id}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Cliente:</dt>
                              <dd>{modalProduct.product.client_name}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Telefone:</dt>
                              <dd>{modalProduct.product.client_phone}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Modelista Responsável:</dt>
                              <dd>{modalProduct.product.modeler_name}</dd>
                            </dl>
                          </Col>
                        </Row>

                        <Row className="border">
                          <Col>
                            <dl>
                              <dt>Código da Peça:</dt>
                              <dd>{modalProduct.product.part_code}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Categoria:</dt>
                              <dd>{modalProduct.product.category}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Peso Estimado:</dt>
                              <dd>{modalProduct.product.info_01}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Medida Aro:</dt>
                              <dd>{modalProduct.product.info_05}</dd>
                            </dl>
                          </Col>
                        </Row>

                        <Row className="border">
                          <Col>
                            <dl>
                              <dt>Descrição Resumida:</dt>
                              <dd>{modalProduct.product.short_description}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Espessura Média da Chapa:</dt>
                              <dd>{modalProduct.product.info_02}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Espessura Média das Grifas:</dt>
                              <dd>{modalProduct.product.info_03}</dd>
                            </dl>
                          </Col>
                        </Row>

                        <Row className="border">
                          <Col>
                            <dl>
                              <dt>Tamanho, Largura e Espessura do Aro:</dt>
                              <dd>{modalProduct.product.info_04}</dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Tamanho do Colar (Colares e Rivieras):</dt>
                              <dd></dd>
                            </dl>
                          </Col>
                          <Col>
                            <dl>
                              <dt>Argolas:</dt>
                              CustomToast show={showSuccessToast} status=
                              {toastStatus.status} message={toastStatus.message}{" "}
                              <dd>{modalProduct.product.info_06}</dd>
                            </dl>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Accordion alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Metal</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de metal necessário.</h4>
                          </div>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr className="text-center">
                                <th>Tipo</th>
                                <th>Peso (G)</th>
                                <th>Remover</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metalModalProduct.map((metal, index) => (
                                <tr className="text-center" key={index}>
                                  <td>{metal.metal}</td>
                                  <td>{metal.amount.toFixed(1)}</td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      disabled={permissionRemoveEdit}
                                    >
                                      <i className="ri-delete-bin-6-line"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Módulos</Accordion.Header>
                        <Accordion.Body className="d-flex flex-wrap justify-content-between">
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de composição</h4>
                          </div>
                          {metalModule.map((modules, i) => (
                            <Card
                              key={i}
                              style={{ width: "500px" }}
                              className="mx-2 p-3 d-flex flex-row mb-2"
                            >
                              <Avatar size={"xl"} img={modules.file} />
                              <div className="ms-3 w-100">
                                <div className="w-100 d-flex flex-wrap justify-content-between">
                                  <h4 className="card-value mb-1">
                                    {modules.name}
                                  </h4>
                                  <div className="d-flex">
                                    <Button
                                      variant="danger"
                                      disabled={permissionRemoveEdit}
                                      className="mx-1"
                                    >
                                      <i className="ri-delete-bin-6-line"></i>
                                    </Button>
                                  </div>
                                  <Image
                                    src={modules.file}
                                    width={"100%"}
                                  ></Image>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Cravação</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de pedras.</h4>
                          </div>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                <th>Módulo</th>
                                <th>Mat.Prima</th>
                                <th>Descrição</th>
                                <th>Quantidade</th>
                                <th>Opções</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metalNailing.map((nailing, i) => (
                                <tr key={i}>
                                  <td>{nailing.module}</td>
                                  <td>{nailing.description}</td>
                                  <td>{nailing.stone}</td>
                                  <td>{nailing.amount}</td>
                                  <td>ações</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Tipo de Cravação</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de pedras.</h4>
                          </div>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                {typeNailing.map((type) => (
                                  <th key={type.id}>{type.name}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {typeNailing?.map((type, index) => (
                                  <td key={index}>{productTypeNailing?.find(item => item.nt_id == type.id)?.amount || 0}</td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>


                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Anexos</Accordion.Header>
                        <Accordion.Body className="d-flex flex-row gap-3 justify-content-between">
                          {metalAttachment.map((image, index) => (
                            <>
                              <Card className="card-one" style={{
                                minWidth: "50%"
                              }} key={index} closeButton>

                                <Card.Body className="d-flex flex-column align-items-center justify-content-between" style={{ cursor: 'pointer' }} as={'a'} href={image.file} target="__blank">
                                  <Card.Title>{image.description}</Card.Title>
                                  <div className="h-100 w-100 d-flex flex-column">
                                    <Image src={image.file} style={{ maxWidth: "100%", maxHeight: "450px" }} ></Image>
                                  </div>
                                </Card.Body>
                              </Card>
                            </>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="lg"
                  centered
                  fullscreen="md"
                  show={modalImageModule}
                  onHide={handleClose}
                >
                  {metalAttachment.map((image, index) => (
                    <>
                      <Modal.Header key={index} closeButton>
                        <Modal.Title>{image.description}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image src={image.file} width={"100%"}></Image>
                      </Modal.Body>
                    </>
                  ))}
                </Modal>

                <Modal
                  centered
                  size="xl"
                  fullscreen="md"
                  show={modalPedido}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Novo Pedido</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={ajaxNewOrder}>
                      <div className="d-flex flex-row ">
                        <div className="d-flex w-100 m-0 text-center flex-wrap justify-content-center">
                          <Card className="w-100 mx-4">
                            <h5>
                              Nome da peça: {modalProduct.product.product_name}
                            </h5>
                            <CarouselCustom
                              images={metalAttachment.map((image) => image)}
                            />
                            <h5>{modalProduct.product.short_description}</h5>
                          </Card>
                        </div>
                        <div className="w-50 text-center">
                          <Card className="p-3 h-100 d-flex justify-content-center">
                            {modalProduct.product.client_id === "44" ? (
                              <Row>
                                <Col md={12}>
                                  <div className="mb-3 w-100 d-flex flex-column">
                                    {users.length > 1 ? (
                                      <>
                                        <Form.Label>
                                          Selecione o Cliente
                                        </Form.Label>
                                        <Form.Select
                                          id="id_client"
                                          name="id_client"
                                          onChange={e => setOrderData(prevData => ({
                                            ...prevData,
                                            id_client: e.target.value
                                          }))}
                                        >
                                          <option value={""}>
                                            --- Selecione ---
                                          </option>
                                          {users.map((client) => (
                                            <option
                                              value={client.id}
                                              key={client.id}
                                            >
                                              {client.name}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </>
                                    ) : (
                                      <>
                                        <Card.Title>Cliente</Card.Title>
                                        {users.map((client) => (
                                          <span
                                            id="id_client"
                                            name="id_client"
                                            value={client.id}
                                            key={client.id}
                                          >
                                            {client.name}
                                          </span>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="d-flex text-start flex-column mb-3">
                                    <Form.Label>Cliente</Form.Label>

                                    <Form.Control
                                      type="hidden"
                                      value={modalProduct.product.client_id}
                                      id="id_client"
                                      name="id_client"
                                    ></Form.Control>
                                    <span className="border p-3 rounded">
                                      {modalProduct.product.client_name}
                                    </span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            )}

                            <Row className="d-flex justify-content-center">
                              <Col md={12} className="d-flex flex-wrap">
                                <label className="text-center w-100">Tipo de Metal</label>
                                <select
                                  className="form-control"
                                  id="product_metal"
                                  name="product_metal"
                                  onChange={(e) => setOrderData({ ...orderData, product_metal: e.target.value })}
                                  value={orderData?.product_metal}
                                >
                                  <option value={""}>Selecione</option>
                                  {material.map((metal) => (
                                    <option value={metal.id} key={metal.id}>
                                      {metal.name}
                                    </option>
                                  ))}
                                </select>
                              </Col>
                              <Col md={12} className="d-flex flex-wrap mt-3">
                                <label className="text-center w-100">Cor do Metal</label>
                                <span>{modalProduct.product.category_id == 8}</span>
                                <Form.Select
                                  disabled={!orderData.product_metal}

                                  id="product_metal_color"
                                  name="product_metal_color"
                                  onChange={(e) => setOrderData({ ...orderData, product_metal_color: e.target.value })}
                                >
                                  <option value={""}>Selecione</option>

                                  {modalProduct.product.category_id == 8 ? materialColor.map((color) => {
                                    if (color.id <= 3) {
                                      return (
                                        <option value={color.id} key={color.id}>
                                          {color.name}
                                        </option>
                                      )
                                    }
                                  }) : materialColor.filter(item => orderData.product_metal == 3 ? item.id != "7" : item.id == "7").map((color) => (
                                    <option value={color.id} key={color.id}>
                                      {color.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              <Row>
                                <Col className="mt-3 w-100 text-left">

                                  <span className="w-100 ">Un./Par:</span>
                                  <Nav className="mb-3 nav-icon nav-icon-sm ms-auto d-flex text-center align-items-center justify-content-center">
                                    <Form.Select
                                      disabled={modalProduct.product.category_id != 3}
                                      className="text-center"
                                      name="modal_order_form_input_pair"
                                      id="modal_order_form_input_pair"
                                      value={orderData.pair}
                                      onChange={(e) => {
                                        if (modalProduct.product.category_id != 3) {
                                          swalErro("Essa funcionalidade só está disponível para produtos do tipo Brinco ")
                                        } else {
                                          setOrderData({ ...orderData, pair: e.target.value })
                                        }
                                      }}
                                    >
                                      <option value={"N"}>Un.</option>
                                      <option value={"S"}>Par(es)</option>
                                    </Form.Select>
                                  </Nav>
                                  <span className="mt-4 w-100">
                                    Peso por peça:
                                  </span>
                                  <span
                                    id="quantidadeNecessaria"
                                    name="quantidadeNecessaria"
                                    className=" form-control"
                                  >
                                    <i className="ri-scales-2-fill"></i>
                                    {modalProduct.product.info_01}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3 w-100">
                                  <label className="w-100">Quantidade:</label>
                                  <Form.Control
                                    className="mt-0 mb-3 text-center w-100"
                                    onChange={(e) => setOrderData({
                                      ...orderData,
                                      amount_product: e.target.value,
                                      amount_product_metal_total: Number(e.target.value) * Number(productWeight),
                                    })}
                                    value={orderData.amount_product}
                                    min={0}
                                    stesp={1}
                                    name="amount_product"
                                    id="amount_product"
                                    type="number"
                                  ></Form.Control>
                                  <span className="w-100">
                                    Peso necessário total:
                                  </span>
                                  <span
                                    id="quantidadeNecessariaTotal"
                                    name="quantidadeNecessariaTotal"
                                    className="form-control"
                                  >
                                    <i className="ri-scales-2-fill"></i>
                                    {Number(orderData?.amount_product_metal_total)?.toFixed(3)}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mt-3 w-100">
                                <label>Informações Complementares:</label>
                                <Editor
                                  onInit={(_evt, editor) => editorRef.current = editor}
                                  onChange={(e) => {
                                    onUpdatingModalProductDescription(e)
                                  }}
                                  apiKey='gsmvci1znnyamtj9fuwif8r32sjrds5s7unvbjbszdm09jxk'
                                  init={{
                                    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons',
                                  }}
                                  initialValue={modalProduct.product.info_07}
                                />
                                <p>
                                  <div style={{
                                    textAlign: "start"
                                  }} dangerouslySetInnerHTML={{ __html: newProductDescription }}></div>
                                </p>
                              </Row>
                            </Row>
                          </Card>
                        </div>
                      </div>

                      <Card className="mt-3 d-flex gap-2 flex-row w-100 text-end justify-content-end align-items-center p-3">
                        {localStorage.getItem("utp") === "interno" ? null : (
                          <Button
                            type="submit"
                            name="type_submit"
                            id="type_submit"
                            onClick={(e) => setTypeSubmit(20)}
                            value={20}
                            className="d-flex w-15 flex-row justify-content-between px-4"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="https://www.w3.org/2000/svg"
                              className="text-white"
                            >
                              <path
                                d="M17.5 18.78V17.04H6.2C6.06 17 5.96 16.89 5.96 16.75L5.99 16.63L6.87 14.43H14.85C15.56 14.43 16.18 14.03 16.5 13.43L17.38 11.8C14.53 11.12 12.35 8.71 12.03 5.73H4.12L3.2 4H0V5.74H1.94L5.44 13.36L4.13 15.82C3.9 16.23 3.82 16.75 3.95 17.28C4.17 18.19 5.03 18.79 5.94 18.79H17.5V18.78Z"
                                fill="#fff"
                              ></path>
                              <path
                                d="M16.18 20C15.1 20 14.23 20.9 14.23 22C14.23 23.1 15.1 24 16.18 24C17.26 24 18.14 23.1 18.14 22C18.13 20.9 17.25 20 16.18 20Z"
                                fill="#fff"
                              ></path>
                              <path
                                d="M6.40001 20C5.32001 20 4.45001 20.9 4.45001 22C4.45001 23.1 5.32001 24 6.40001 24C7.48001 24 8.36001 23.1 8.36001 22C8.35001 20.9 7.47001 20 6.40001 20Z"
                                fill="#fff"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19 0C16.238 0 14 2.23858 14 5C14 7.76203 16.2386 10 19 10C21.762 10 24 7.76142 24 5C24 2.23797 21.7614 0 19 0ZM18.4444 2.36249V4.44444H16.2236C15.9199 4.44444 15.6667 4.69318 15.6667 5C15.6667 5.30896 15.916 5.55556 16.2236 5.55556H18.4444V7.91529C18.4444 8.21902 18.6932 8.47222 19 8.47222C19.309 8.47222 19.5556 8.22288 19.5556 7.91529V5.55556H21.7764C22.0801 5.55556 22.3333 5.30682 22.3333 5C22.3333 4.69104 22.084 4.44444 21.7764 4.44444H19.5556V2.36249C19.5556 2.05876 19.3068 1.80556 19 1.80556C18.691 1.80556 18.4444 2.0549 18.4444 2.36249Z"
                                fill="#fff"
                              ></path>
                            </svg>
                            Carrinho
                          </Button>
                        )}

                        <Button
                          type="submit"
                          name="type_submit"
                          id="type_submit"
                          onClick={(e) => setTypeSubmit(1)}
                          value={1}
                          className="d-flex w-15 flex-row justify-content-between px-4"
                        >
                          <svg
                            width="23"
                            height="22"
                            viewBox="0 0 23 22"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.09977 17.6C5.88981 17.6 4.91085 18.59 4.91085 19.8C4.91085 21.01 5.88981 22 7.09977 22C8.30973 22 9.2997 21.01 9.2997 19.8C9.2997 18.59 8.30973 17.6 7.09977 17.6ZM0.5 0V2.2H2.69992L6.65979 10.549L5.17484 13.244C4.99885 13.552 4.89985 13.915 4.89985 14.3C4.89985 15.51 5.88981 16.5 7.09977 16.5H20.2993V14.3H7.56176C7.40776 14.3 7.28677 14.179 7.28677 14.025L7.31977 13.893L8.30973 12.1H16.5044C17.3294 12.1 18.0554 11.649 18.4294 10.967L22.3672 3.828C22.458 3.66013 22.5037 3.47158 22.4998 3.28078C22.4959 3.08998 22.4426 2.90346 22.345 2.73943C22.2475 2.5754 22.1091 2.43947 21.9433 2.34492C21.7776 2.25037 21.5901 2.20044 21.3993 2.2H5.13084L4.09688 0H0.5ZM18.0994 17.6C16.8894 17.6 15.9105 18.59 15.9105 19.8C15.9105 21.01 16.8894 22 18.0994 22C19.3094 22 20.2993 21.01 20.2993 19.8C20.2993 18.59 19.3094 17.6 18.0994 17.6Z"
                              fill="#ffffff"
                            ></path>
                          </svg>
                          Finalizar
                        </Button>
                      </Card>
                    </Form>
                  </Modal.Body>
                </Modal>

                <Card.Footer className="d-flex justify-content-center">
                  <Card.Footer className="d-flex justify-content-center">
                    <Pagination className="pagination-space pagination-circled mb-0">
                      {Array.from({ length: totalPages }, (_, index) => {
                        if (
                          index + 1 === currentPage ||
                          index + 1 === 1 ||
                          index + 1 === totalPages ||
                          (index + 1 >= currentPage - 2 &&
                            index + 1 <= currentPage + 2)
                        ) {
                          return (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageClick(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        } else if (
                          (index + 1 === currentPage - 3 && currentPage > 5) ||
                          (index + 1 === currentPage + 3 &&
                            currentPage < totalPages - 4)
                        ) {
                          return (
                            <Pagination.Ellipsis
                              key={index + 1}
                              onClick={() => handlePageClick(index + 1)}
                            />
                          );
                        }
                        return null;
                      })}
                    </Pagination>
                  </Card.Footer>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
        <Footer />

        <Modal
          className="modal-xl"
          show={modalCartShow}
          onHide={handeCloseModalCart}
        >
          <Modal.Header closeButton>
            <Modal.Title>Carrinho de Compras</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#ebebeb" }}>
            <Row className="gap-row">
              <Col md={8}>
                <Card>
                  <Card.Body className="p-0">
                    {cart.map((cart, index) => (
                      <div
                        key={index}
                        className="d-flex flex-column border-bottom"
                      >
                        <div className="d-flex p-0 flex-row w-100">
                          <div className="cart w-100 d-flex gap-3 flex-wrap flex-row align-items-center">
                            <div className="image-custom d-flex justify-content-center">
                              <img width={"150px"} src={cart.FILE}></img>
                            </div>

                            <div className=" d-flex flex-wrap align-items-start flex-column">
                              <h6
                                className="mt-3 "
                                style={{
                                  marginRight: "5px",
                                  maxWidth: "350px",
                                  minWidth: "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {cart.short_description}
                              </h6>
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() => handleRemoveOrder(cart.id_order)}
                              >
                                <strong>Excluir</strong>
                              </Button>
                            </div>
                            <div className="d-flex py-2 gap-1 flex-column w-30 ml-auto justify-content-end">
                              {/* {cartDetail.map((d, i) => {
                                if (
                                  d.product_id === cart.id_product &&
                                  d.id_order === cart.id_order
                                ) {
                                  return (
                                    <Card className="px-3 py-1">
                                      <div className="d-flex gap-1">
                                        <span>
                                          <strong>Pedra: </strong>
                                        </span>
                                        <span>{d.description}</span>
                                      </div>
                                      <div>
                                        <span>
                                          <strong>Módulo: </strong>
                                        </span>
                                        <span>{d.module}</span>
                                      </div>
                                      <div className="d-flex gap-1">
                                        <span>
                                          <strong>Quantidade</strong>:
                                        </span>
                                        <span> {d.amount}</span>
                                      </div>
                                    </Card>
                                  );
                                }
                              })} */}
                              <div className="py-1 material-phone">
                                <div className="d-flex gap-1 ">
                                  <span>
                                    <strong>Metal</strong>:
                                  </span>
                                  <span> {cart.material_name}</span>
                                </div>

                                <div className="d-flex gap-1 ">
                                  <span>
                                    <strong>Cor</strong>:
                                  </span>
                                  <span> {cart.material_color}</span>
                                </div>

                                <div className="d-flex gap-1 ">
                                  <span>
                                    <strong>Quantidade</strong>:
                                  </span>
                                  <span> {cart.required_weight_total} (g)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title>
                      <h5 className="font-weight-bold">
                        SubTotal ( {cart.length} Pedidos )
                      </h5>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="d-flex gap-3 flex-column">
                    <div className="w-100">
                      <span>Metais:</span>
                      {cartResume.metal.map((m, i) => (
                        <div key={i}>
                          <div className="d-flex fexl-row justify-content-between gap-3">
                            <strong>{m.material_name}</strong>

                            <span>{m.amount.toFixed(2)} (g)</span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* <div className="w-100">
                      <span>Pedras:</span>
                      {cartResume.pedras.map((m, i) => (
                        <div key={i}>
                          <div className="d-flex fexl-row justify-content-between gap-3">
                            <strong>{m.Stone}</strong>
                            <span>{m.Amount}</span>
                          </div>
                        </div>
                      ))}
                    </div> */}

                    <Button onClick={handleCloseCart} className="w-100">
                      Concluir Pedido
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
