import { createContext } from "react";
import TreeServices from "../apiServices/Tree/TreeServices";

const TreeContext = createContext();

const token = localStorage.getItem("token");

const TreeProvider = ({ children }) => {
  const addRealWeight = async (data) => {
    const payload = {
      tree: data.tree,
      batch: data.batch,
      material_color: data.material_color,
      material_color_code: data.material_color_code,
      weight: data.weight,
      field: data.field,
      token: token,
    };

    const response = await TreeServices.addRealWeight(payload);
    return response;
  };
  return (
    <TreeContext.Provider value={{ addRealWeight }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeContext, TreeProvider };
