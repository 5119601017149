import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Card, Alert, Badge, Accordion, Table } from "react-bootstrap";
import { OrderContext } from "../../Context/OrderContext";
import { ScreenContext } from "../../Context/Screen/context";
import { BsClock, BsFillPersonFill, BsFileText, BsExclamationCircle } from "react-icons/bs";

import { FaRegClock } from "react-icons/fa6";
import { productServices } from "../../apiServices/productServices";

export const LogModal = ({ order }) => {
	const { orderLog, getOrderLog } = useContext(OrderContext);
	const { screenWidth } = useContext(ScreenContext);
	const [show, setShow] = useState(false);
	const [materialColors, setMaterialColors] = useState([]);

	const handleClose = () => setShow(false);
	const handleOpenModal = () => {
		getOrderLog({ order: order.ID });
		setShow(true);
		handleGetMaterialColor();
	};

	const handleGetMaterialColor = async () => {
		const response = await productServices.selectListMaterialcolor(null, localStorage.getItem("token"));
		setMaterialColors(response.data);
	};

	const isJson = item => {
		try {
			JSON.parse(item);
			return true;
		} catch (e) {
			return false;
		}
	};

	return (
		<>
			<Button variant="primary" onClick={handleOpenModal}>
				<i className="ri-file-info-line"></i>
			</Button>
			{show && (
				<Modal fullscreen show={show} onHide={handleClose} size="xl">
					<Modal.Header closeButton>
						<Modal.Title>Log de Alterações</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>
							<strong>Pedido: {order.CODE}</strong>
						</h5>
						<hr />

						{orderLog?.history?.length > 0 ? (
							orderLog?.history?.map((log, index) => (
								<Accordion key={index} className="mb-3">
									<Accordion.Item eventKey={index}>
										<Accordion.Header>
											<div className="d-flex justify-content-between w-100">
												<div className="">
													<strong>
														{" "}
														<FaRegClock size={16} />
													</strong>
													<strong className="ms-2">{new Date(log.dh).toLocaleString("pt-BR")}</strong>
													{" - "}
													{log["status"]}
												</div>
												<div>
													<strong className="mb-1">{log["name"]}</strong>
												</div>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<div className="d-flex justify-content-between w-100">
												<div className="">
													<strong>
														{" "}
														<FaRegClock size={16} />
													</strong>
													<strong className="ms-2">{new Date(log.dh).toLocaleString("pt-BR")}</strong>
													{" - "}
													{log["status"]}
												</div>
												<div>
													<strong className="mb-1">{log["name"]}</strong>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							))
						) : (
							<Alert variant="info" className="d-flex align-items-center">
								<BsExclamationCircle size={24} className="me-2" />
								Nenhum log de cadastro encontrado. Pedido cadastrado em: {new Date(order.DATE).toLocaleString("pt-BR")}
							</Alert>
						)}

						{orderLog?.logs?.length > 0 ? (
							orderLog?.logs?.map((log, index) => (
								<Accordion key={index} className="mb-3">
									<Accordion.Item eventKey={index}>
										<Accordion.Header>
											<div className="d-flex align-items-center flex-row justify-content-between w-100">
												<div>
													<strong>
														<FaRegClock size={16} /> <span className="ms-2">{new Date(log.data).toLocaleString("pt-BR")}</span>
													</strong>
													{" - "}
													{log.status == "Pré-aceitação" ? (
														<>
															<span>{log.status}</span>
															{" - "}
															<span>{log.pagina}</span>
														</>
													) : (
														<span>{log.status}</span>
													)}
												</div>
												<strong>{log.alterado_por}</strong>
											</div>
										</Accordion.Header>
										{log.cravacao?.map((item, index) => (
											<Accordion.Body key={index} className="mb-4">
												<div className="d-flex flex-wrap justify-content-between">
													<div className="mb-2">
														<strong>{item.name} </strong>
													</div>
													<div className="mb-2">
														<strong>Pedido:</strong> {item.order_code}
													</div>
													<div className="mb-2">
														<strong>Pavê:</strong> {item.amount_pave}
													</div>
													<div className="mb-2">
														<strong>Grifas:</strong> {item.amount_grifas}
													</div>
													<div className="mb-2">
														<strong>Central:</strong> {item.amount_central}
													</div>
													<div className="mb-2">
														<strong>Inglesa:</strong> {item.amount_inglesa}
													</div>
													<div className="mb-2">
														<strong>Diferencial:</strong> {item.amount_diferencial}
													</div>
													<div className="mb-2">
														<strong>Completado em:</strong> {item.completed_at ? new Date(item.completed_at).toLocaleDateString() : "Não completado"}
													</div>
												</div>
											</Accordion.Body>
										))}

										{log.acabamento &&
											log.acabamento?.map(item => (
												<Accordion.Body>
													<div>
														<div>
															<strong>Ourives: </strong>
															<span>{item.USERNAME}</span>
														</div>
														<div>
															<strong>Total operado: </strong>
															<span>{item.TOTAL_WEIGHT} (g)</span>
														</div>
														<div>
															<strong>Operação: </strong>
															<span>{item.OPERATION_NAME}</span>
														</div>
														<div>
															<strong>Cor da operação: </strong>
															<span>{item.OPERATION_COLOR}</span>
														</div>

														<div>
															<strong>Tipo de movimentação: </strong>
															<span>{item.MOVIMENTATION_NAME}</span>
														</div>
													</div>
												</Accordion.Body>
											))}
										{!log.acabamento && log.children && (
											<Accordion.Body>
												<Table className="m-0" bordered hover>
													<thead>
														<tr>
															<th style={{ width: "25%" }}>Data</th>
															<th style={{ width: "25%" }}>Campo</th>
															<th style={{ width: "25%" }}>Informação Altereada</th>
															<th className="text-end" style={{ width: "25%", paddingRight: "2rem" }}>
																Alterado Por
															</th>
														</tr>
													</thead>
													<tbody>
														{log.children
															?.filter(item => item.informacao_alterada !== "nenhum")
															.map((item, index) => (
																<tr key={index}>
																	<td style={{ paddingLeft: "1.125rem" }}>
																		<strong>
																			<FaRegClock size={16} /> <span className="ms-2">{new Date(item.data).toLocaleString("pt-BR")}</span>
																		</strong>
																	</td>
																	<td>{item.informacao_alterada}</td>
																	<td>
																		{isJson(item.valor_alterado) ? (
																			Array.isArray(JSON.parse(item.valor_alterado)) ? (
																				JSON.parse(item.valor_alterado).map((item, index) => (
																					<span key={index}>
																						{materialColors.find(color => color.id === item.material_color_id)?.name}
																						{" - "}
																						<strong>{item.weight} (g)</strong>
																					</span>
																				))
																			) : (
																				<span>
																					{item.informacao_alterada == "Peso fundição"
																						? JSON.stringify(JSON.parse(item.valor_alterado)) + " (g)"
																						: JSON.stringify(JSON.parse(item.valor_alterado))}{" "}
																				</span>
																			)
																		) : (
																			<span>
																				{item.valor_alterado == "Peso fundição" ? item.valor_alterado + " s(g)" : item.valor_alterado}
																			</span>
																		)}
																	</td>
																	<td style={{ paddingRight: "2rem" }} className="text-end">
																		<strong>{item.alterado_por}</strong>
																	</td>
																</tr>
															))}
													</tbody>
												</Table>
											</Accordion.Body>
										)}
									</Accordion.Item>
								</Accordion>
							))
						) : (
							<Alert variant="info" className="d-flex align-items-center">
								<BsExclamationCircle size={24} className="me-2" />
								Nenhum log de alterações encontrado.
							</Alert>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Fechar
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};
