export const CarouselCustom = ({ images }) => {


    return (

        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {images.map((image) => (
                    <div key={image.id} className="carousel-item active">
                        
                        <img src={image.file} className="d-block w-100" alt="..."></img>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev text-dark" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Anterior</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Posterior</span>
            </button>
        </div>
    )

}