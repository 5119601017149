import React from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import pageSvg from "../assets/svg/security.svg";
import { Link } from "react-router-dom";

export default function Forbidden() {


  return (
    <div className="page-error d-flex align-items-center justify-content-center">
      <div className="content">
        <Container>
          <Row className="gx-5">
            <Col lg="5" className="d-flex flex-column align-items-center">
              <h1 className="error-number">403</h1>
              <h2 className="error-title">Oops!</h2>
              <p className="error-text">A página que você tentou acessar,  não está disponível.</p>
            </Col>
            <Col xs="8" lg="6" className="mb-5 mb-lg-0">
              <object type="image/svg+xml" data={pageSvg} className="w-100" aria-label="svg image"></object>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}