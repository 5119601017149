import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Nav, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Avatar from "../components/Avatar";
import ReactApexChart from "react-apexcharts";
import { Bar } from 'react-chartjs-2';
import { dp1, dp2, dp3 } from "../data/DashboardData";
import { VectorMap } from "@react-jvectormap/core";
import { usAea } from "@react-jvectormap/unitedstates";


import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";

import { apiServices } from "../apiServices/ApiServices";
import { balanceServices } from "../apiServices/balanceServices";
import { Search, TrendingDown, TrendingDownIcon, TrendingUp, TrendingUpIcon } from "lucide-react";
import { DashboardServices } from "../apiServices/dashBoardServices";

const formatDate = (data) => {
  const date = new Date(data);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export default function SalesMonitoring() {
  const [count, setCount] = useState([])


  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const token = localStorage.getItem("token");

  const [balance, setBalance] = useState([]);

  const [modalDetail, setModalDetail] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [searchBalance, setSearchBalance] = useState("");

  const [searchDashBoard, setSerchDashBoard] = useState("");

  const handleBalance = async (searchBalance, token) => {
    const data = {
      "search": searchBalance,
      "token": token
    }
    const response = await balanceServices.getBalance(data)
    setBalance(response.data.balance)
  }

  const handleClose = () => {
    setOpenModalDetail(false)
    setModalDetail([])
  }

  const handleBalanceDetail = async (id) => {
    const data = {
      "id": id,
      "token": token,
      "page": '1',
      "limit": "10",
      "search": searchBalance
    };

    const response = await balanceServices.getTypeBalanceDetail(data)
    setOpenModalDetail(true)
    setModalDetail(response.data)
  }

  const dashBoardData = async () => {
    const data = {
      "action": "dashboard_data",
      "token": token,
      "search": searchDashBoard,
    }
    const response = await DashboardServices.getDataToBalanceController(data)

  }

  useEffect(() => {
    dashBoardData()
    handleBalance(searchBalance, token);
  }, [token])


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 h-100">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Saldo de insumos</h4>
          </div>
        </div>

        <Row className="h-100">
          <Col md="12" xl="12" className="h-100">
            <Card className="card-one h-100">
              <Card.Header>
                <div className="border form-search w-20 mb-3">
                  <Form className="d-flex w-100" onSubmit="" >
                    <Form.Control className="w-100" onChange="" placeholder="Pesquisar" id="pesquisar" name="pesquisar"></Form.Control>
                    <Button type="submit" variant="outline"><Search /></Button>
                  </Form>
                </div>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {balance.map((saldo) => (
                      <tr key={saldo.balance_id}>
                        <td className="custom-hover" style={{ cursor: "pointer" }} value={saldo.balance_id}>
                          <h6 className=" mb-1">
                            <Link onClick={() => handleBalanceDetail(saldo.balance_id)} value={saldo.balance_id}>{saldo.user_name}</Link>
                          </h6>
                        </td>

                        <td>
                          <label className="d-block text-secondary mb-1 text-center">Material</label>
                          <span className="d-block fw-medium text-dark ff-numerals text-center">{saldo.material}</span>
                        </td>
                        <td>
                          <label className="d-block text-secondary mb-1 text-center">Peso</label>
                          <span className="d-block fw-medium text-dark ff-numerals text-center">{saldo.balance} (g)</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* <Card key={saldo.balance_id} className="p-3 d-flex justify-content-between align-items-center flex-row mb-2">
                      <div className="ms-3">
                        <h4 className="card-value mb-1">{saldo.user_name}</h4>
                        <label className="card-title fw-medium text-dark mb-1">{saldo.material}</label>
                        <p className="fs-xs text-secondary mb-0 lh-4">{saldo.balance} (g)</p>
                      </div>
                      <div>
                        <Button onClick={() => handleBalanceDetail(saldo.balance_id)} value={saldo.balance_id}>Consulta</Button>
                      </div>
                    </Card> */}

                </Table>


              </Card.Body>
            </Card>
          </Col>
          <Modal size="lg" centered fullscreen="md" show={openModalDetail} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Detalhamento de saldo</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Table>
                <thead>
                  <tr>
                    <th>
                      <label className="d-block text-secondary mb-1 text-center">Tipo</label>
                    </th>
                    <th>
                      <label className="d-block text-secondary mb-1 text-center">Descrição</label>
                    </th>
                    <th>
                      <label className="d-block text-secondary mb-1 text-center">Pedido</label>
                    </th>
                    <th>
                      <label className="d-block text-secondary mb-1 text-center">Quantidade</label>
                    </th>
                    <th>
                      <label className="d-block text-secondary mb-1 text-center">Data e Hora</label>
                    </th>
                  </tr>
                </thead>
                {modalDetail.map((detail, index) => (
                  <tr key={index}>
                    <td><span className="d-block fw-medium text-dark ff-numerals text-center">
                      <i style={{ marginRight: "5px" }}>
                        {detail.type !== "Entrada" ? <TrendingDownIcon color="#ED1C24" /> : <TrendingUpIcon color="#22B14C" />}
                      </i>
                      {detail.type}
                    </span></td>
                    <td><span className="d-block fw-medium text-dark ff-numerals text-center">{detail.description}</span></td>
                    <td><span className="d-block fw-medium text-dark ff-numerals text-center">{detail.code_order}</span></td>
                    <td>
                      <span className={detail.type !== "Entrada" ? "text-danger d-block fw-medium text-dark ff-numerals text-center" : "text-success d-block fw-medium text-dark ff-numerals text-center"}>
                        {detail.amount}
                      </span>
                    </td>

                    <td><span className="d-block fw-medium text-dark ff-numerals text-center">{formatDate(detail.dh)}</span></td>
                  </tr>
                ))}
              </Table>
            </Modal.Body>
          </Modal>


        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}