import api from "../api";

const AccountServices = {
  accountList: (data) => {
    return api.post("/accountController.php", {
      action: "account_list",
      token: data.token,
      permission_type: data.permissionType,
    });
  },
  accountOperationList: (data) => {
    return api.post("/accountController.php", {
      action: "account_operation_list",
      token: data.token,
    });
  },
  account: (data) => {
    return api.post("/accountController.php", {
      action: "account",
      token: data.token,
      permission: data.permission,
      user: data.id_user,
    });
  },
  accountDetail: (data) => {
    return api.post("/accountController.php", {
      action: "account_detail",
      token: data.token,
      user: data.id_user,
    });
  },
  accountDebitCredit: (data) => {
    return api.post("/accountController.php", {
      action: "account_debit_credit",
      token: data.token,
      user: data.id_user,
      permission_code: data.permission_code
    });
  },
  accountBalance: (data) => {
    return api.post("/accountController.php", {
      "action": "account_balance",
      "user": data.id_user,
      "permission_code": data.permission_code,
      "token": data.token,
      "detail": data.detail,
    })
  },
  accountBalanceList: (data) => {
    return api.post("/accountController.php", {
      action: "account_balance_list",
      token: data.token,
      user: data.id_user,
    });
  },
  accountBalancePedding: (data) => api.post("/accountController.php", data),
  concluiAccountPendding: (data) => api.post("/accountController.php", data),

  getExtract: payload => api.post("/accountController.php", payload),
  addNailingValue: (payload) => api.post("/accountController.php", payload),
  getNailingExtract: payload => api.post("/accountController.php", payload),
};

export default AccountServices;
