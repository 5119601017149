import React, { useCallback, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

const MultipleFileUpload = ({ onFileChange }) => {
  const [files, setFiles] = useState([]);

  const handleDrop = useCallback((acceptedFiles) => {
    const updatedFiles = [
      ...files,
      ...acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      })),
    ];
    setFiles(updatedFiles);
    onFileChange(updatedFiles); // Chama a função de callback passando os arquivos selecionados para o componente pai
  }, [files, onFileChange]);

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    URL.revokeObjectURL(fileToRemove.preview);
    setFiles(updatedFiles);
    onFileChange(updatedFiles); // Chama a função de callback passando os arquivos atualizados para o componente pai
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*', // Define o tipo de arquivo aceito, neste caso, apenas imagens
    multiple: true, // Permite upload de múltiplos arquivos
  });

  const previews = files.map((file) => (
    <Card className='py-3' key={file.name} style={previewStyles}>
      <img className='w-50 h-50' src={file.preview} alt={file.name} style={imageStyles} />
      <p>{file.name}</p>
      <Button onClick={() => removeFile(file)}>Remover</Button>
    </Card>
  ));

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Arraste os arquivos aqui...</p>
        ) : (
          <p>Arraste arquivos aqui ou clique para selecionar</p>
        )}
      </div>
      <div style={previewsContainerStyles}>{previews}</div>
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
};

const previewsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
};

const previewStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px',
};

const imageStyles = {
  width: '150px',
  height: '150px',
  objectFit: 'cover',
  marginBottom: '10px',
};

export default MultipleFileUpload;
