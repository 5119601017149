import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userServices } from "../apiServices/userServices";
import { ToastContainer, toast } from "react-toastify";
import { cepServices } from "../apiServices/addressServices";
import logo from "../assets/img/logo.webp"; import logoBranca from "../assets/img/logo-branca.png";
export default function Signup() {
  const [currentPostalCode, setCurrentPostalCode] = useState([]);
  const skin = localStorage.getItem('skin-mode')
  const [address, setAdress] = useState({
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
  });

  const handlePostalCodeChange = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const response = await cepServices.getAdress(currentPostalCode);

    if (response.status === 200) {
      toast.dismiss();
      const { cep, logradouro, complemento, bairro, localidade, uf } =
        response.data;
      setAdress({ cep, logradouro, complemento, bairro, localidade, uf });
      toast.success("Endereço encontrado com sucesso!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    if (response.data.erro) {
      toast.error("Endereço não encontrado ou CEP inválido!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    toast.loading("Processando...", { position: toast.POSITION.BOTTOM_CENTER });
    const formData = new FormData(e.target);
    const data = {
      name: formData.get("name"),
      fantasy_name: formData.get("fantasy_name"),
      document: formData.get("document"),
      typeUser: formData.get("typeUser"),
      phone: formData.get("phone"),
      email: formData.get("email"),
      password: formData.get("password"),
      postalCode: address.cep,
      address: address.logradouro,
      number: formData.get("number"),
      complement: formData.get("complement"),
      neighborhood: address.bairro,
      city: address.localidade,
      uf: address.uf,
    };

    const response = await userServices.register(data);
    toast.dismiss();
    ;
    if (response) {
      toast.info(response.data.msg, { position: toast.POSITION.BOTTOM_CENTER });
    }
  };

  return (
    <div className="page-sign">
      <Card className="card-one w-50 d-flex flex-column">
        <Card.Header className="d-flex flex-column">
          <Link to="/" className="header-logo mb-4">
            <img src={skin && skin === "dark" ? logoBranca : logo} width={200}></img>
          </Link>
          <Card.Text>
            Fácil e rápido! Crie já o seu acesso aos melhores produtos do
            mercado.
          </Card.Text>
        </Card.Header>
        <Card.Body className="d-flex flex-column">
          <form onSubmit={handleRegister}>
            <Row>
              <Col className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Digite seu e-mail"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Digite a sua senha"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Digite seu nome completo"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Nome Fantasia</Form.Label>
                <Form.Control
                  type="text"
                  name="fantasy_name"
                  id="fantasy_name"
                  placeholder="Nome fantasia da sua empresa"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>CPF/ CNPJ</Form.Label>
                <Form.Control
                  type="text"
                  name="document"
                  id="document"
                  placeholder="Digite seu CPF ou CNPJ"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Digite seu numero de contato"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  autoComplete="false"
                  id="postalCode"
                  value={currentPostalCode}
                  onChange={(e) => {
                    setCurrentPostalCode(e.target.value);
                  }}
                  placeholder="Digite o seu CEP"
                />
              </Col>
              <Col className="mt-2 d-flex align-items-center">
                <Button onClick={() => handlePostalCodeChange()} className="">
                  Pesquisar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  name="address"
                  id="address"
                  disabled={address.logradouro !== "" ? "disabled" : ""}
                  onChange={(e) =>
                    setAdress({ ...address, logradouro: e.target.value })
                  }
                  value={address.logradouro}
                  type="text"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Numero</Form.Label>
                <Form.Control name="number" id="number" type="text" />
              </Col>
              <Col className="mb-3">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  name="neighborhood"
                  id="neighborhood"
                  disabled={address.logradouro !== "" ? "disabled" : ""}
                  onChange={(e) =>
                    setAdress({ ...address, bairro: e.target.value })
                  }
                  value={address.bairro}
                  type="text"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  name="city"
                  id="city"
                  disabled={address.logradouro !== "" ? "disabled" : ""}
                  onChange={(e) =>
                    setAdress({ ...address, localidade: e.target.value })
                  }
                  value={address.localidade}
                  type="text"
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  name="uf"
                  id="uf"
                  disabled={address.logradouro !== "" ? "disabled" : ""}
                  onChange={(e) =>
                    setAdress({ ...address, uf: e.target.value })
                  }
                  value={address.uf}
                  type="text"
                />
              </Col>
            </Row>

            <div className="mb-4">
              <small>
                Clicando em <strong>Criar Conta</strong> abaixo, vocẽ concorda
                com os nosso termos de política de privacidade.
              </small>
            </div>
            <Button variant="primary" type="submit" className="">
              Criar Conta
            </Button>
          </form>
        </Card.Body>
        <Card.Footer>
          Já possui uma conta? <Link to="/signin">Entrar</Link>
        </Card.Footer>
      </Card>
      <ToastContainer />
    </div>
  );
}
