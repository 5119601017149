import { useEffect, useState } from "react";
import { apiServices } from "../../apiServices/ApiServices";
import { Form, Card, Button } from "react-bootstrap";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { BatchServices } from "../../apiServices/Batch/Service";
import { animations, AnimateOnChange } from "react-animation";
import { dialog, htmlDialog, swalErro, swalSucesso } from "../Swal";
import { useFoundry } from "../../Context/FoundryContext/FoundryContext";

export const BatchRetail = ({ batch, sendDataToParent }) => {
	const [retail, setRetail] = useState([]);

	const { retailBatch, handleGetMaterialBatch } = useFoundry();

	// Função para obter o valor do peso baseado no código de cor
	const getWeightByColorCode = colorCode => {
		if (retail && retail.length > 0) {
			const foundItem = retail.find(item => item.MATERIAL_COLOR_CODE === colorCode);
			if (foundItem) {
				return foundItem.TOTAL_WEIGHT;
			}
		}
		return 0; // Retorna 0 se não encontrar o código de cor correspondente
	};

	const updateWeightByColorCode = (colorCode, material_color, newWeight) => {
		setRetail(currentRetail => {
			// Verifica se o array já contém um objeto com o código de cor
			const index = currentRetail.findIndex(item => item.MATERIAL_COLOR_CODE === colorCode);

			if (index !== -1) {
				// Se encontrou, atualiza o peso desse objeto
				const updatedRetail = [...currentRetail];
				updatedRetail[index] = {
					...updatedRetail[index],
					TOTAL_WEIGHT: newWeight,
				};
				return updatedRetail;
			} else {
				// Se não encontrou, adiciona um novo objeto ao array
				return [
					...currentRetail,
					{
						MATERIAL_COLOR_CODE: colorCode,
						MATERIAL_COLOR: material_color,
						TOTAL_WEIGHT: newWeight,
						BATCH_ID: batch.id,
					},
				];
			}
		});
	};

	const handleUpdateRetail = async e => {
		e.preventDefault();
		const htmlContent = retail
			.map(retailItem => {
				if (retailItem.TOTAL_WEIGHT != null || retailItem.TOTAL_WEIGHT != 0 || retailItem.TOTAL_WEIGHT != "") {
					return `
      <div>
        <span><strong>Material: </strong>${retailItem.MATERIAL_COLOR_CODE}</span><br>
        <span><strong>Cor: </strong>${retailItem.MATERIAL_COLOR}</span><br>
        <span><strong>Peso: </strong>${retailItem.TOTAL_WEIGHT}</span><br>
      </div>
    `;
				}
			})
			.join("");

		const confirmation = await htmlDialog("Você deseja realmente salvar os pesos para retalhos?", htmlContent);

		if (!(await confirmation).isConfirmed) {
			return;
		}

		toast.loading("Processando", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		const payload = {
			token: localStorage.getItem("token"),
			batch: batch.id,
			retalho: retail,
		};
		toast.dismiss();
		const response = await apiServices.saveRetail(payload);
		if (response) {
			if (response.data.status === "success") {
				swalSucesso(response.data.message);
				sendDataToParent(true);
				setRetail([]);
				handleGetMaterialBatch(batch);
			} else {
				swalErro(response.data.message);
			}

			// handleGetBatchRetail();
		}
	};

	const [batchHistory, setBatchHistory] = useState([]);
	const handleGetBatchHistory = async () => {
		const payload = {
			token: localStorage.getItem("token"),
			batch: batch.id,
		};
		const response = await BatchServices.getBatchHistory(payload);
		setBatchHistory(response.data.result);
	};

	useEffect(() => {
		handleGetBatchHistory();
	}, []);

	return (
		<div className="d-flex flex-column gap-3">
			<ToastContainer />
			<Card className="w-100">
				<form onSubmit={handleUpdateRetail}>
					<Card.Body>
						<Card.Title>Retalhos</Card.Title>
						<hr></hr>
						<div className="d-flex flex-row gap-3 justify-content-between">
							{retailBatch?.map((retail, index) => (
								<Form.Group className="w-100">
									<Form.Label>
										<strong>{retail.name.replace(" c/ Ródio", "")}</strong>
									</Form.Label>
									<Form.Control
										className="w-100"
										disabled={retail.total_weight != null}
										step={"0.01"}
										onChange={e => updateWeightByColorCode(retail.metal_color, retail.name.replace(" c/ Ródio", ""), e.target.value)}
										type="number"
										value={retail.total_weight !== null ? retail.total_weight : getWeightByColorCode(retail.metal_color)}
									/>
								</Form.Group>
							))}
						</div>
					</Card.Body>
					<Card.Footer>
						<Button type="submit">Salvar</Button>
					</Card.Footer>
				</form>
			</Card>
			{/* <Card>
        <Card.Body className="d-flex gap-3 flex-column">
          {batchHistory &&
            batchHistory.map((b, i) => (
              <>
                <Card className="card-one" key={i}>
                  {" "}
                  <Card.Body>
                    <div>
                      <strong>Material: {b.material_color_code}</strong>
                    </div>

                    {b.order_code !== null ? (
                      <>
                        <div>
                          <strong>Tipo de operação</strong>: Adicionado no
                          pedido
                        </div>
                        <div>
                          <strong>Pedido:</strong>
                          <span>{b.order_code}</span>
                        </div>
                      </>
                    ) : null}
                    <div>
                      <span>
                        <strong>Valor operado: </strong>
                        {b.weight && b.weight} (g)
                      </span>
                    </div>
                    {b.tube && (
                      <>
                        <div>
                          <strong>Tipo de operação: </strong>
                          <span>Adicionado ao tubo {b.tube && b.tube}</span>
                        </div>
                      </>
                    )}

                    <div>
                      {b.reference === "real_weight" ? (
                        <>
                          <strong>Referencia: </strong>
                          <span>Peso injetado inicialmente</span>
                        </>
                      ) : b.reference === "injected_weight" ? (
                        <>
                          <strong>Referencia: </strong>
                          <span>Peso injetado adicional</span>
                        </>
                      ) : b.reference === "retail_weight" ? (
                        <>
                          <strong>Referencia: </strong>
                          <span>
                            Peso injetado de reaproveitamento de retalhos
                          </span>
                        </>
                      ) : b.reference === "retail" ? (
                        <>
                          <strong>Referencia: </strong>
                          <span>Retalho adicionado ao lote</span>
                        </>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              </>
            ))}
        </Card.Body>
      </Card> */}
		</div>
	);
};
