import { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { AccountContext } from "../../Context/AccountContext/AccountContext";
import { ToastContainer, toast } from "react-toastify";
export default function Balance({ selectedUser, permissionFetch, userData }) {
  const [modalShow, setModalShow] = useState(false);

  const { accountBalance, getExtract } = useContext(AccountContext);

  const handleOpenModal = () => setModalShow(true);
  const handleCloseModal = () => setModalShow(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      id_user: selectedUser.id_user,
      permission_code: selectedUser.permission_type,
      detail: formData.get("detail"),
    };

    const response = await accountBalance(payload);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success("Balanço informado com sucesso!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleCloseModal();
      permissionFetch(true);
    } else {
      toast.dismiss();
      toast.error(
        "Ocorreu um erro ao salvar balanço! Entre em contato com o nosso suporte.",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    }
  };

  const handleGetExtract = async () => {
    const payload = {
      id: selectedUser.id_user,
      permission_code: selectedUser.permission_type,
    };

    const response = await getExtract(payload);
    window.open(response.data.link, "_blank");
  };

  return (
    <>
      <ToastContainer />
      <Button onClick={handleOpenModal} style={{ minWidth: "160px" }}>
        Balanço
      </Button>
      <Modal size="xl" show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Registrar balanço</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Card className="card-one">
              <Card.Body>
                <div className="d-flex flex-row gap-3 justify-content-between w-100">
                  <Button onClick={handleGetExtract}>Extrato em PDF</Button>
                </div>
                <hr></hr>

                <div className="d-flex flex-row gap-3 w-100 justify-content-between px-2">
                  <span>
                    <strong>Saldo atual</strong>:{" "}
                    {userData.saldoAtual?.toFixed(3)} (g)
                  </span>
                  <span>
                    <strong>Total débitos</strong>:
                    {userData.totalDebitos?.toFixed(3)} (g)
                  </span>
                  <span>
                    <strong>Total créditos</strong>:
                    {userData.totalCreditos?.toFixed(3)} (g)
                  </span>
                  <span>
                    <strong>Quebra atual</strong>:{" "}
                    {userData.quebraAtual?.toFixed(3)} (g)
                  </span>
                </div>
                <hr></hr>
                <div>
                  <Card>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label as="h5">Detalhes</Form.Label>
                        <Form.Control
                          name="detail"
                          id="detail"
                          as="textarea"
                          placeholder="Detalhamento"
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "150px" }}
              variant="secondary"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
            <Button style={{ width: "150px" }} variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
