import { createContext, useContext, useEffect, useState } from "react";
const VisualizationContext = createContext();

export const VisualizationProvider = ({ children }) => {
  const storageVisualization = JSON.parse(localStorage.getItem("showValues"));

  const [showValues, setShowValues] = useState(
    storageVisualization ? storageVisualization : false
  );
  const handleToggle = () => {
    setShowValues(!showValues);
  };

  useEffect(() => {
    localStorage.setItem("showValues", showValues);
  }, [showValues]);

  return (
    <VisualizationContext.Provider value={{ handleToggle, showValues }}>
      {children}
    </VisualizationContext.Provider>
  );
};

export const useVisualization = () => {
  return useContext(VisualizationContext);
};
