import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Nav, Row, Form, FormGroup, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";

import { userServices } from "../apiServices/userServices";
import { cepServices } from "../apiServices/addressServices";
import { ToastContainer, toast } from "react-toastify";

const token = localStorage.getItem("token")

export default function Profile() {

  const [user, setUser] = useState({
    USERID: "",
    NAME: "",
    FANTASYNAME: "",
    DOCUMENT: "",
    PHONE: "",
    EMAIL: "",
    POSTALCODE: "",
    STREET: "",
    NUMBER: "",
    COMPLEMENT: "",
    NEIGHBORHOOD: "",
    MATRICULA: '',
    CITY: "",
    UF: "",
  });

  const handleUserChange = (e, f) => {
    const value = e.target.value
    const field = f.toUpperCase();
    setUser((prevData) => ({
      ...prevData,
      [field]: value
    }))
  }

  const handleSubmit = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER
    })
    e.preventDefault();
    const data = {
      "user": user,
      "token": token
    }
    const response = await userServices.updateUser(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  };

  const handleCep = async (e) => {
    try {
      const response = await cepServices.getAdress(e);
      const data = {
        NEIGHBORHOOD: response.data.bairro,
        POSTALCODE: response.data.cep?.replace("-", ""),
        CITY: response.data.localidade,
        STREET: response.data.logradouro,
        UF: response.data.uf
      };

      setUser((prevData) => ({
        ...prevData,
        ...data
      }));

      toast.dismiss();
      toast.success("Endereço encontrado com sucesso!", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } catch (error) {
      console.error('Erro ao buscar CEP:', error);
    }
  }


  useEffect(() => {
    userServices.getUserData(token)
      .then(response => {
        setUser(response.data.result)
      })
  }, [])

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main p-4 p-lg-5">
        <Card className="card-one mt-5">
          <div className="media-profile p-3 mt-5 d-flex align-items-center">
            {/* <div className="media-img mb-3 mb-sm-0">
                  <img src="https://www.biiainsurance.com/wp-content/uploads/2015/05/no-image.jpg" className="img-fluid" alt="..." />
                </div> */}
            <div className="media-body">
              <div>
                <h5 className="media-name">{user.NAME}</h5>
                {user.USER_TYPE === 1 ? (
                  <p>Código do Cliente: {user.CLIENT_CODE}</p>
                ) : (<></>)}
              </div>
              <p className="d-flex gap-2"><i className="ri-map-pin-line"></i> {user.CITY}, {user.UF}</p>
              {/* <p className="mb-0">Redhead, Innovator, Saviour of Mankind, Hopeless Romantic, Attractive 20-something Yogurt Enthusiast. You can replace this with any content and adjust it as needed... <Link to="">Read more</Link></p> */}
            </div>
          </div>
          <Card.Header>
            <Card.Title>Editar dados pessoais</Card.Title>
          </Card.Header>
          <form onSubmit={handleSubmit} name="user-form" id="user-form">
            <Card.Body>
              <div className="mb-3">
                <div className="d-flex gap-3">
                  <Card className="w-100">
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control className="m-2" name="name" id="name" placeholder="name" value={user.NAME} onChange={(e) => handleUserChange(e, "name")}         ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Nome Fantasia</Form.Label>
                        <Form.Control className="m-2" name="name" id="name" placeholder="Nome Fantasia" value={user.FANTASY_NAME} onChange={(e) => handleUserChange(e, "fantasy_name")} ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control className="m-2" name="cpf" id="cpf" placeholder="CPF/CNPJ" value={user.DOCUMENT} onChange={(e) => handleUserChange(e, "document")}     ></Form.Control>
                      </Form.Group>
                      <Form.Group><Form.Label>Telefone</Form.Label><Form.Control className="m-2" name="telefone" id="telefone" placeholder="Telefone" value={user.PHONE} onChange={(e) => handleUserChange(e, "phone")}        ></Form.Control></Form.Group>
                      <Form.Group><Form.Label>E-mail</Form.Label><Form.Control className="m-2" name="email" id="email" placeholder="email" value={user.EMAIL} onChange={(e) => handleUserChange(e, "email")}        ></Form.Control></Form.Group>

                      {localStorage.utp == "interno" ? <Form.Group>
                        <Form.Label>Matrícula</Form.Label>
                        <Form.Control
                          type="password"
                          className="m-2"
                          name="Matrícula"
                          id="matricula"
                          placeholder="matricula"
                          value={user.MATRICULA}
                          onChange={(e) => handleUserChange(e, "matricula")}        >
                        </Form.Control>
                      </Form.Group> : null}

                      <Form.Group>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                          type="password"
                          className="m-2"
                          name="senha"
                          id="senha"
                          placeholder="Senha"
                          value={user.PASSWORD}
                          onChange={(e) => handleUserChange(e, "PASSWORD")}        >
                        </Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="w-100">
                    <Card.Body>

                      <div className="d-flex flex-row gap-3">
                        <Form.Group className="w-100">
                          <Form.Label>CEP</Form.Label>
                          <Form.Control className="mx-1 mb-1 mt-0 w-100" name="cep" id="cep" placeholder="CEP" value={user.POSTAL_CODE} onChange={(e) => handleUserChange(e, "POSTAL_CODE")}></Form.Control>
                        </Form.Group>
                        <Button className="my-auto mx-0 w-20" onClick={() => handleCep(user.POSTAL_CODE)} variant="outline-primary">Buscar CEP</Button>
                      </div>

                      <Form.Group>
                        <Form.Label>Logradouro</Form.Label>
                        <Form.Control className="m-1 w-100" name="rua" id="rua" placeholder="Rua" value={user.STREET} onChange={(e) => handleUserChange(e, "street")} ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Número</Form.Label>
                        <Form.Control className="m-1 w-100" name="numero" id="numero" placeholder="Número" value={user.NUMBER} onChange={(e) => handleUserChange(e, "number")} ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Complemento</Form.Label>
                        <Form.Control className="m-1 w-100" name="complemento" id="complemento" placeholder="Complemento" value={user.COMPLEMENT} onChange={(e) => handleUserChange(e, "complement")} ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control className="m-1 w-100" name="bairro" id="bairro" placeholder="Bairro" value={user.NEIGHBORHOOD} onChange={(e) => handleUserChange(e, "neighborhood")} ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control className="m-1 w-100" name="cidade" id="cidade" placeholder="Cidade" value={user.CITY} onChange={(e) => handleUserChange(e, "city")} ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Estado</Form.Label>
                        <Form.Control className="m-1 w-100" name="uf" id="uf" placeholder="Estado" value={user.UF} onChange={(e) => handleUserChange(e, "uf")} ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                </div>
              </div>

            </Card.Body>
            <Card.Footer>
              <div className="w-100 d-flex flex-wrap">
                <Button className="w-20 mx-2" form="user-form" type="submit" variant="outline-primary">Salvar</Button>
                <Button className="w-20 mx-2" variant="outline-danger">Cancelar</Button>
              </div>
            </Card.Footer>
          </form>
        </Card>
        {/* <Card className="card-post mt-4">
              <Card.Header>
                <Card.Title>Pedidos</Card.Title>
                <Link to="" className="link-more"><i className="ri-more-2-fill"></i></Link>
              </Card.Header>
              <Card.Body>
                <div className="interest-item">
                  <div className="interest-icon bg-dark"><i className="ri-github-fill"></i></div>
                  <div className="interest-body">
                    <h6>Github, Inc.</h6>
                    <p>A provider of Internet hosting for software development and version control using Git. <Link to="">Learn more</Link></p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-twitter-fill"></i></div>
                  <div className="interest-body">
                    <h6>Twitter, Inc.</h6>
                    <p>An American communications company that operates the microblogging and social networking service. <Link to="">Learn more</Link></p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-amazon"><i className="ri-amazon-fill"></i></div>
                  <div className="interest-body">
                    <h6>Amazon.com, Inc.</h6>
                    <p>An American multinational technology company which focuses on e-commerce, artificial intelligence and more. <Link to="">Learn more</Link></p>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <Nav>
                  <Nav.Link href="">Show more interests (1) <i className="ri-arrow-down-s-line"></i></Nav.Link>
                </Nav>
              </Card.Footer>
            </Card> */}

        {/* <Col xl="4" xxl="3" className="d-none d-xl-block">
            <h5 className="section-title mb-4">Mutual Connections</h5>
            <div className="profile-mutual">
              <ul className="mutual-group mb-3">
                <li><Avatar img={img12} /></li>
                <li><Avatar img={img11} /></li>
                <li><Avatar img={img10} /></li>
                <li><Avatar img={img9} /></li>
                <li><Avatar img={img8} /></li>
              </ul>
              <h6>You have 18 mutual connection</h6>
              <p>You and Fen both know Archie Cantones, Socrates Itumay, and 17 others</p>
            </div>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">People You May Know</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "Allan Rey Palban",
                  "position": "Senior Business Analyst"
                }, {
                  "avatar": img7,
                  "name": "Adrian Moniño",
                  "position": "Software Engineer"
                }, {
                  "avatar": img8,
                  "name": "Charlene Plateros",
                  "position": "Sales Representative"
                }, {
                  "avatar": img9,
                  "name": "Analyn Mercado",
                  "position": "Executive Assistant"
                }, {
                  "avatar": img10,
                  "name": "Rolando Paloso",
                  "position": "Senior Architect"
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">People Also Viewed</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img11,
                  "name": "Maricel Villalon",
                  "position": "Engineering Manager"
                }, {
                  "avatar": img12,
                  "name": "Geraldine Cantones",
                  "position": "Software Architect"
                }, {
                  "avatar": img6,
                  "name": "Allan Rey Palban",
                  "position": "Senior Business Analyst"
                }, {
                  "avatar": img7,
                  "name": "Adrian Moniño",
                  "position": "Software Engineer"
                }, {
                  "avatar": img8,
                  "name": "Charlene Plateros",
                  "position": "Sales Representative"
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">Contact Information</h5>
            <ul className="list-contact-info">
              <li><i className="ri-building-fill"></i><span>Bay Area, San Francisco, CA</span></li>
              <li><i className="ri-home-8-fill"></i><span>Westfield, Oakland, CA</span></li>
              <li><i className="ri-phone-fill"></i><span>(+1) 012 345 6789</span></li>
              <li><i className="ri-mail-fill"></i><span>you@youremail.com</span></li>
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">Social Channel</h5>
            <ul className="list-contact-info">
              <li><i className="ri-twitter-fill"></i><span>@username</span></li>
              <li><i className="ri-instagram-fill"></i><span>@username</span></li>
              <li><i className="ri-messenger-fill"></i><span>@username</span></li>
            </ul>
          </Col> */}

        <Footer />
        <ToastContainer />
      </div>
    </React.Fragment >
  );
}