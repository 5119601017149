import api from "./api";

export const userServices = {
  createUser: (data) => {
    return api.post("/userController.php", data);
  },
  validadeEmail: (code) => {
    return api.post("/loginController.php", {
      code: code,
      action: "validateEmail",
    });
  },

  login: (data) => {
    return api.post("/loginController.php", {
      action: "auth",
      route: "auth",
      email: data.email,
      pass: data.password,
    });
  },

  checkSession: (token) => {
    return api.post("/sessionController.php", {
      token: token,
    });
  },

  getUserData: (token, matricula, user) => {
    return api.post("/userController.php", {
      action: "data",
      selectedUser: user,
      token: token,
      matricula: matricula,
    });
  },

  getUserDataToEdit: (data) => {
    return api.post("/userController.php", {
      action: "data",
      user_id: data.user_id,
      token: data.token,
    });
  },

  getClientLastTransaction: (data) => {
    return api.post("/transactionController.php", {
      "action": "get_client_transaction_data",
      "client_id": data.client_id,
      "token": data.token
    })
  },

  getUsers: (data) => {
    return api.post("/userController.php", {
      action: "read",
      token: data.token,
      permission_type: data.permission_type,
      extract_user: data.extract_user,
      page: data.page,
      pageSearch: data.pageSearch,
      search: data.searchKey,
      limit: data.limit,
    });
  },

  userSearch: (data) => {
    return api.post("/productController.php", {
      action: "userSearch",
      token: data,
      user_type: "1",
      txt: "",
    });
  },

  updateUser: (data) => {
    const user = data.user;
    return api.post("/userController.php", {
      action: "update_profile",
      id_user: user.USERID,
      name: user.NAME,
      fantasy_name: user.FANTASY_NAME,
      phone: user.PHONE,
      email: user.EMAIL,
      postalCode: user.POSTALCODE,
      address: user.STREET,
      number: user.NUMBER,
      complement: user.COMPLEMENT,
      neighborhood: user.NEIGHBORHOOD,
      city: user.CITY,
      matricula: user.MATRICULA,
      password: user.PASSWORD,
      uf: user.UF,
      token: data.token,
    });
  },

  recoveryCode: (data) => {
    return api.post("/loginController.php", {
      action: "recoveryCode",
      email: data.email,
    });
  },

  createPassword: (data) => {
    return api.post("/loginController.php", {
      action: "createPassword",
      email: data.email,
      password: data.password1,
      password2: data.password2,
      code: data.code,
    });
  },
  register: (data) => {
    return api.post("/loginController.php", {
      action: "register",
      name: data.name,
      fantasy_name: data.fantasy_name,
      document: data.document,
      typeUser: data.typeUser,
      phone: data.phone,
      email: data.email,
      password: data.password,
      postalCode: data.postalCode,
      address: data.address,
      number: data.number,
      complement: data.complement,
      neighborhood: data.neighborhood,
      city: data.city,
      type_user: "1",
      uf: data.uf,
    });
  },
  editUser: (data) => {
    return api.post("/userController.php", {
      action: "update",
      userID: data.user.USERID,
      name: data.user.NAME,
      client_code: data.user.CLIENT_CODE,
      fantasy_name: data.user.FANTASY_NAME,
      document: data.user.DOCUMENT,
      typeUser: data.user.USER_TYPE,
      typeUser_text: data.user.USER_TYPE_TEXT,
      phone: data.user.PHONE,
      email: data.user.EMAIL,
      matricula: data.user.MATRICULA,
      authentication: data.user.AUTH,
      representative: data.user.REPRESENTATIVE,
      observations: data.user.OBSERVATIONS,
      legal_person: data.user.LEGAL_PERSON,
      state_registration: data.user.STATE_REGISTRATION,
      postalCode: data.user.POSTAL_CODE,
      address: data.user.STREET,
      number: data.user.NUMBER,
      complement: data.user.COMPLEMENT,
      neighborhood: data.user.NEIGHBORHOOD,
      percent_loss: data.user.PERCENT_LOSS,
      city: data.user.CITY,
      uf: data.user.UF,
      token: data.token,
    });
  },
  passWordChange: (token, data) => {
    return api.post("/userController.php", {
      token: token,
      action: "passwordChange",
      id_user_input: data.USERID,
      name_user: data.NAME,
      email_user: data.EMAIL,
    });
  },

  getSideBarOptions: (data) => {
    return api.post("/homeController.php", {
      action: "navbar",
      token: data,
    });
  },

  setPercentual: (data) => {
    return api.post("/userController.php", {
      action: "percentmetallosDefine",
      token: data.token,
      id_user_input: data.user,
      percent: data.percentual,
    });
  },

  getUserTypePermissions: () => {
    return api.post("/userController.php", {
      action: "permitionListSelect",
    });
  },

  getUserPermission: (id) => {
    return api.post("/userController.php", {
      action: "permitionList",
      userID: id,
    });
  },

  removeUserPermission: (data) => {
    return api.post("/userController.php", {
      action: "permitionRemove",
      permission_remove_id: data.id,
      token: data.token,
    });
  },

  setUserPermission: (data) => {
    return api.post("/userController.php", {
      action: "permissionAdd",
      userID: data.user,
      permission_add_id: data.permission,
      token: data.token,
    });
  },

  getModelista: (data) => {
    return api.post("/productController.php", {
      action: "userSearch",
      user_type: 7,
      txt: "",
      token: data.token,
    });
  },

  balanceAdd: (data) => {
    const payload = {
      "action": "add_value_to_user_account",
      "client_id": data.cliente,
      "amount_operated": data.quantidade,
      "material_id": data.material,
      "transaction_type": "1",
      "token": data.token
    }

    return api.post("/transactionController.php", payload);
  },

  operationAdd: (data) => {
    return api.post("/balanceController.php", {
      action: "operation_add",
      selectedUser: data.id_user,
      permission_code: data.permission_code,
      movimentation_type: data.movimentation_type,
      hand_water_real_weight: data.hand_water_real_weight,
      detail: data.detail,
      operation_type: data.operation_type,
      operation_code: data.operation_code,
      id_orders: data.id_orders,
      total_weight: data.total_weight,
      token: data.token
    });
  },
  getUserDataToExtract: (data) => api.post("/userController.php", data)
};
