import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, ListGroup, Modal, Nav, Table } from "react-bootstrap";
import ModalSolicitacao from "../../components/Solicitacao/ModalSolicitacao";
import { NailingContext } from "../../Context/NailingContext/Context";
import { ModalSolicitacaoDetalhes } from "../../components/Solicitacao/Detalhes/ModalDetalhes";
import AlteraStatus from "../../components/Solicitacao/AlteraStatusSolicitacao/ModalAlteraStatus";
import DynamicIcon from "../../components/DynamicIcon/DynamicIcon";
import { Search } from "lucide-react";
import { UserContext } from "../../Context/UserContext";

const Solicitacao = () => {
	const { listUser } = useContext(UserContext);
	const { listaSolicitacao, solicitacoes, detalhesSolicitacao, requestStatus, ListRequestStatus } = useContext(NailingContext);

	const [statusName, setStatusName] = useState("Aguardando");

	useEffect(() => {
		const payload = {
			status_name: statusName,
		};
		listaSolicitacao(payload);
		ListRequestStatus();
	}, []);

	useEffect(() => {
		listaSolicitacao({
			status_name: statusName,
		});
	}, [statusName]);

	const [modalAttachmentShow, setModalAttachmentShow] = useState(false);
	const [modalAttachment, setModalAttachment] = useState({
		link: "",
		name: "",
	});

	const handleOpenModalAttachment = data => {
		setModalAttachment({
			link: data.FILE,
			name: data.part_code,
		});
		setModalAttachmentShow(true);
	};

	const [statusList, setStatusList] = useState([]);

	useEffect(() => {
		const list = solicitacoes.map(item => ({
			status_name: item.status_name,
			status_icon: item.status_icon,
		}));

		const uniqueItems = list.filter((item, index, self) => index === self.findIndex(t => t.status_name === item.status_name && t.status_icon === item.status_icon));
		setStatusList(uniqueItems);
	}, [solicitacoes]);

	const [clientList, setClientList] = useState([]);
	const handleGetUser = async () => {
		const token = localStorage.getItem("token");
		const response = await listUser({
			token: token,
			page: "client",
			limit: 9999,
			permission_type: 1,
		});
		setClientList(response.data.users);
	};
	const [selectOptionClient, setSelectOptionClient] = useState([]);
	const selectOptionsClient = () => {
		if (solicitacoes.length > 0) {
			const lista = solicitacoes.reduce((acumulador, atual) => {
				// Verifica se o cliente já está na lista
				const existe = acumulador.some(item => item.client_id === atual.client_id);

				// Se não existir, adiciona o cliente à lista
				if (!existe) {
					acumulador.push({
						client_id: atual.client_id,
						client_name: atual.client_name,
					});
				}

				return acumulador;
			}, []); // Inicializa o acumulador como um array vazio

			setSelectOptionClient(lista);
		}
	};
	useEffect(() => {
		handleGetUser();
	}, []);

	const [clientSelected, setClientSelected] = useState(null);
	const handleSetClient = e => {
		setClientSelected(e.target.value);
	};

	const [filteredOrder, setFilteredOrder] = useState("");

	const [filteredRequests, setFilteredRequests] = useState([]);

	useEffect(() => {
		setFilteredRequests(solicitacoes);
		selectOptionsClient();
	}, [solicitacoes]);

	const handleSetFilteredRequest = () => {
		if (clientSelected) {
			const filtered = solicitacoes.filter(request => request.client_id === parseInt(clientSelected));
			setFilteredRequests(filtered);
		} else {
			setFilteredRequests(solicitacoes);
		}
	};

	const handleFilterWithOrder = order => {
		if (order == "") {
			setFilteredRequests(solicitacoes);
			return;
		} else {
			const filtered = solicitacoes.filter(request => request.order_codes.toLowerCase().includes(order.toLowerCase()));
			setFilteredRequests(filtered);
		}
	};

	useEffect(() => {
		handleSetFilteredRequest();
	}, [clientSelected, filteredOrder]);

	return (
		<React.Fragment>
			<div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
				<Card className="card-one">
					<Card.Body>
						<div className="d-md-flex align-items-center justify-content-between">
							<div>
								<Card.Title as={"h4"}>Solicitações de pedras e metais para o cliente</Card.Title>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className="card-one">
					<Card.Header>
						<ModalSolicitacao />
					</Card.Header>

					<Card.Body className="d-flex flex-row gap-3">
						<Card className="w-100 card-one">
							<Card.Header>
								<div className="p-2 d-flex w-100 flex-row gap-3 justify-content-between align-items-end">
									<div className="border form-search w-20 d-flex flex-row align-items-end">
										<div className="d-flex w-100">
											<Form.Control
												className="w-100"
												placeholder="Pesquisar"
												onChange={e => handleFilterWithOrder(e.target.value)}
												id="pesquisar"
												name="pesquisar"
											></Form.Control>
											<Button variant="outline">
												<Search />
											</Button>
										</div>
									</div>
									<div className="w-20">
										<Form className="w-100">
											<Form.Label>Cliente</Form.Label>
											<Form.Select onChange={e => handleSetClient(e)} className="w-100" placeholder="Cliente" id="pesquisar" name="pesquisar">
												<option value="">Todos</option>
												{selectOptionClient?.map(client => (
													<option key={client.client_id} value={client.client_id}>
														{client.client_name}
													</option>
												))}
											</Form.Select>
										</Form>
									</div>
								</div>
							</Card.Header>
							<Card.Body className="d-flex flex-row gap-2 justify-content-between">
								<Card className="w-15">
									<Card.Body>
										<Nav className="nav-sidebar">
											{requestStatus?.map((status, index) => (
												<Nav.Link
													key={index}
													href=""
													active={status.status_name == statusName}
													onClick={() => setStatusName(status?.status_name)}
													className="d-flex flex-row gap-3"
												>
													<h5 className="font-xl">
														<DynamicIcon icon={status?.icon} />
													</h5>{" "}
													<span>{status?.status_name}</span>
												</Nav.Link>
											))}
										</Nav>
									</Card.Body>
								</Card>
								<div className="w-100 h-100 d-flex flex-column gap-1">
									<div className="px-1">
										<Card className="px-2">
											<Card.Body className="d-flex flex-row justify-content-between align-items-center px-5">
												<div
													className="text-start d-flex align-items-center justify-content-center"
													style={{
														width: "4rem",
													}}
												>
													<strong> #</strong>
												</div>
												<div
													className="text-start"
													style={{
														width: "9rem",
													}}
												>
													<span
														style={{
															display: "block",
															width: "100%",
															overflow: "hidden",
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
														}}
													>
														Cliente
													</span>
												</div>

												<div
													className="text-start"
													style={{
														width: "9rem",
													}}
												>
													Data
												</div>

												<div className="text-start border-0 flex-row justify-content-center">
													<span className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-center">Status</span>
												</div>
												<div className="text-center">Alterar Status</div>
											</Card.Body>
										</Card>
									</div>
									<div
										className="w-100 d-flex flex-column gap-2 px-1"
										style={{
											maxHeight: "40rem",
											minHeight: "40rem",
											overflowY: "scroll",
										}}
									>
										{filteredRequests?.map((s, i) => (
											<Card key={i}>
												<Card.Body className="d-flex flex-row justify-content-between align-items-center px-5">
													<div
														className="text-center d-flex align-items-center justify-content-center"
														style={{
															width: "4.25rem",
														}}
													>
														<ModalSolicitacaoDetalhes id={s.id}>
															<div className="product-thumb w-100">{s.id}</div>
														</ModalSolicitacaoDetalhes>
													</div>
													<div
														className="text-center"
														style={{
															width: "9rem",
														}}
													>
														<span
															style={{
																display: "block",
																width: "100%",
																overflow: "hidden",
																whiteSpace: "nowrap",
																textOverflow: "ellipsis",
															}}
														>
															{s.client_name}
														</span>
													</div>

													<div
														className="text-end"
														style={{
															width: "9rem",
														}}
													>
														<span>
															{new Date(s.created_at).toLocaleDateString("pt-BR")} {new Date(s.created_at).toLocaleTimeString("pt-BR")}
														</span>
													</div>

													<div className="text-center border-0 flex-row justify-content-center">
														<span
															className="m-auto d-flex  flex-row  gap-3 align-items-center justify-content-center"
															style={{
																padding: "0.5rem",
																borderRadius: "5px",
																minWidth: "10rem",
																color: s.text_color,
																backgroundColor: s.status_color,
															}}
														>
															<DynamicIcon icon={s.status_icon} />
															<span>{s.status_name}</span>
														</span>
													</div>
													<div className="text-center">
														<AlteraStatus solicitacao={s} />
													</div>
												</Card.Body>
											</Card>
										))}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Card.Body>
				</Card>
			</div>
			<Modal size="lg" show={modalAttachmentShow} onHide={() => setModalAttachmentShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{modalAttachment.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body className="d-flex justify-content-center">
							<img src={modalAttachment.link} height={"100%"}></img>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};

export default Solicitacao;
