import api from "../api";

const BalanceServices = {
  listOperationType: (data) => {
    return api.post("/balanceController.php", {
      action: "operation_type",
      token: data.token,
    });
  },

  listOperationCode: (data) => {
    return api.post("/balanceController.php", {
      action: "operation_code",
      id_operation_type: data.id_operation_type,
      token: data.token,
    });
  },

  listOperation: (data) => {
    return api.post("/balanceController.php", {
      action: "list_operation",
      token: data.token,
      permission_code: data.permission_code,
      selectedUser: data.id_user
    });
  },
  listMovimentation: (data) => {
    return api.post("/balanceController.php", {
      action: "list_movimentation",
      token: data.token
    })
  },
  getTotalBreak: (data) => {
    return api.post("/balanceController.php", {
      action: "get_total_break",
      token: data.token,
      selectedUser: data.selectedUser,
      permission_code: data.permission_code,
    })
  },
  getOperationDetail: (data) => {
    return api.post("/balanceController.php", {
      action: "get_operation_detail",
      token: data.token,
      id_operation: data.id_operation
    })
  }
};

export default BalanceServices;
