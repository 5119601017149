import api from "../api";

export const TabelaMontagemServices = {
	Listar: data => {
		return api.post("/tabelaMontagemController.php", {
			action: "lista",
			token: data.token,
		});
	},
};
