import { createContext, useState } from "react";
import { NailingServices } from "../../apiServices/NailingServices/service";
import { swalErro, swalSucesso } from "../../components/Swal";
const token = localStorage.getItem("token");

const NailingContext = createContext();

const NailingProvider = ({ children }) => {
	const [orderNailing, setOrderNailing] = useState([]);
	const [totalValue, setTotalValue] = useState([]);
	const [solicitacoes, setSolicitacoes] = useState([]);
	const [requestStatus, setRequestStatus] = useState([]);

	const getOrderNailing = async data => {
		const payload = {
			action: "get_order_nailing",
			user: data.id,
			id_orders: data.id_orders,
			token: token,
			start_date: data.start_date,
			end_date: data.end_date,
			permission_id: data.permission_id,
		};
		const response = await NailingServices.getOrderNailing(payload);
		setOrderNailing(response.data.result);
	};

	const getTotalValue = async data => {
		const { id, start_date, end_date, month, permission_id } = data;

		const payload = {
			action: "get_total_value",
			user: id,
			token: token,
			start_date: start_date,
			end_date: end_date,
			permission_id: permission_id,
		};
		const response = await NailingServices.getTotalValue(payload);
		setTotalValue(response.data.result);
	};

	const NailingBreak = async data => {
		const payload = {
			action: "nailing_break",
			user: data.user,
			token: token,
		};

		return await NailingServices.NailingBreak(payload);
	};

	const SaveSolicitacao = async data => {
		const payload = {
			action: "save_request",
			id_orders: data.id_orders,
			item: data.item,
			token: token,
		};

		const response = await NailingServices.SaveSolicitacao(payload);
		if (response.data.status == "success") {
			swalSucesso(response.data.message);
		} else {
			swalErro(response.data.message);
		}
		return response;
	};

	const listaSolicitacao = async data => {
		const payload = {
			action: "list_request",
			token: token,
			status_name: data.status_name ? data.status_name : "Aguardando",
		};

		const response = await NailingServices.listSolicitacao(payload);
		setSolicitacoes(response.data.result);
	};

	const listaSolicitacaoCliente = async data => {
		const payload = {
			action: "list_request_client",
			status_name: data.status_name ? data.status_name : "Aguardando",
			token: token,
		};

		const response = await NailingServices.ListaSolicitacaoCliente(payload);
		setSolicitacoes(response.data.result);
	};

	const AtualizaSolicitacao = async data => {
		const payload = {
			action: "update_request",
			id_request: data.id_request,
			request_status: data.request_status,
			token: token,
		};

		const response = await NailingServices.AtualizaSolicitacao(payload);
		return response;
	};

	const detalhesSolicitacao = async data => {
		const payload = {
			action: "request_detail",
			id_request: data.id_request,
			token: token,
		};

		const response = await NailingServices.detalhesSolicitacao(payload);
		return response;
	};

	const ListRequestStatus = async () => {
		const payload = {
			action: "list_request_status",
			token: token,
		};

		const response = await NailingServices.ListRequestStatus(payload);
		setRequestStatus(response.data.result);
	};

	const RecebimentoItens = async itens => {
		const payload = {
			action: "receive_request_item",
			received_item: itens,
			token: token,
		};
		const response = await NailingServices.RecebimentoItens(payload);

		if (response.data.status?.toLowerCase() === "success") {
			swalSucesso("Item recebido com sucesso!");
		} else {
			swalErro("Ocorreu um erro ao receber o item! Por favor, tente novamente ou entre em contato com o suporte!");
		}
		return response;
	};

	const resendRequestEmail = async data => {
		const payload = {
			action: "resend_request_email",
			id_request: data.id_request,
			token: token,
		};
		const response = await NailingServices.ResendRequestEmail(payload);
		if (response.data.status == "success") {
			swalSucesso("E-mail enviado com sucesso!");
		} else {
			swalErro(response.data.message);
		}
	};

	const listRequestFiles = async data => {
		const payload = {
			action: "list_request_files",
			id_request: data.id_request,
			token: token,
		};
		const response = await NailingServices.listRequestFiles(payload);
		return response;
	};

	const SaveRequestItemFile = async data => {
		const payload = {
			token: token,
			id_request_item: data.id_request_item,
			file_name: data.file_name,
			file: data.file,
		};
		const response = await NailingServices.SaveRequestItemFile(payload);
	};

	const handleResetValuesFromNailing = () => {
		setOrderNailing([]);
		setTotalValue([]);
		setSolicitacoes([]);
		setRequestStatus([]);
	};

	const saveConcluedOrCanceled = async nailing => {
		const { id_order_nailing, id_status_services, id_order, completed_detail } = nailing;

		const payload = {
			action: "save_as_conclued_or_canceled",
			nailing: [
				{
					id_order,
					id_order_nailing,
					id_status_services,
					completed_detail,
				},
			],
			editing_nailing: [nailing.nailing],
			token: token,
		};

		const response = await NailingServices.saveConcluedOrCanceled(payload);
		if (response.data.status == "success") {
			swalSucesso(response.data.msg);
			return response;
		} else {
			console.error("Error ao concluir/cancelar cravação! Por gentileza, entre em contato com o nosso suporte.", response.data.msg);
		}
	};

	const SaveEditingNailing = async ({ nailing }) => {
		const { amount_central, amount_diferencial, amount_grifas, amount_inglesa, amount_pave, id_order, additional_value, id_order_nailing } = nailing;

		const payload = {
			action: "save_editing_order_nailing",
			token: token,
			nailing: [
				{
					amount_central,
					amount_diferencial,
					amount_grifas,
					amount_inglesa,
					amount_pave,
					id_order,
					additional_value,
					id_order_nailing,
				},
			],
		};

		const response = await NailingServices.SaveEditingNailing(payload);
		if (response.data.status == "success") {
			swalSucesso(response.data.msg);
		} else {
			swalErro(response.data.msg);
		}
		return response;
	};

	const getOrderNailingUser = async data => {
		const payload = {
			action: "get_order_nailing_user",
			order_code: data.order_code,
			token: token,
		};
		const response = await NailingServices.getOrderNailingUser(payload);
		return response;
	};

	return (
		<NailingContext.Provider
			value={{
				// Getters
				listaSolicitacao,
				getOrderNailing,
				getTotalValue,

				// Methods
				NailingBreak,
				SaveSolicitacao,
				detalhesSolicitacao,
				ListRequestStatus,
				AtualizaSolicitacao,
				RecebimentoItens,
				resendRequestEmail,
				listaSolicitacaoCliente,
				SaveRequestItemFile,
				listRequestFiles,
				SaveEditingNailing,
				handleResetValuesFromNailing,
				saveConcluedOrCanceled,

				//Attributes_request",
				token: token,
				orderNailing,
				totalValue,
				solicitacoes,
				requestStatus,
				getOrderNailingUser,
			}}
		>
			{children}
		</NailingContext.Provider>
	);
};

export { NailingContext, NailingProvider };
