import { Form } from "react-bootstrap";

export const NumberFormControl = props => {
	return (
		<Form.Control
			type="number"
			onWheel={e => e.preventDefault()} // Cancela o comportamento padrão do scroll
			{...props}
		/>
	);
};
